// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import { getDataWithPagination } from '@/utils/getDataWithPagination';

import { types, VideosState } from './types';
import { RootState } from '../types';
import { getDemoVideos } from './demo';

interface EditVideoQuery {
  id: string;
  name: string;
  gpsLatitude: number;
  gpsLongitude: number;
  tagIds?: string[];
}

export const actions: ActionTree<VideosState, RootState> = {
  fetchVideos({ commit, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      const host = process.env.VUE_APP_DECONVE_API_URL;
      const url = `${host}/videos/`;
      const params = { skip: 0 };

      if (rootGetters.isDemoMode) {
        getDemoVideos().then((videos) => {
          commit(types.GET_VIDEOS_SUCCESS, videos);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      } else {
        getDataWithPagination(url, params, rootGetters).then((videos) => {
          commit(types.GET_VIDEOS_SUCCESS, videos);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      }
    });
  },
  deleteVideo({ commit, dispatch, rootGetters }, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'delete',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/videos/${id}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then(() => {
        commit(types.DELETE_VIDEOS_SUCCESS, id);
        dispatch('fetchVideos');
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  editUnit({ dispatch, rootGetters }, params: EditVideoQuery): Promise<void> {
    return new Promise((resolve, reject) => {
      const {
        id: videoId,
        name,
        gpsLatitude,
        gpsLongitude,
        tagIds,
      } = params;

      const payload = {
        name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        tag_ids: tagIds,
        // eslint-disable-next-line @typescript-eslint/camelcase
        gps_atitude: gpsLatitude,
        // eslint-disable-next-line @typescript-eslint/camelcase
        gps_longitude: gpsLongitude,
      };

      const requestOptions: AxiosRequestConfig = {
        method: 'put',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/videos/${videoId}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        data: payload,
      };

      axios(requestOptions).then(() => {
        dispatch('fetchUnits');

        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
};

export default actions;
