// Copyright (C) 2022 Deconve Technology. All rights reserved.
/* eslint-disable @typescript-eslint/camelcase */

import moment from 'moment';

import i18n from '@/plugins/i18n';

import { Tag } from './types';

export const tags: Tag[] = [
  {
    id: '00000000-0000-0000-0000-000000000000',
    name: i18n.t('deconve.onboarding.tag.region.name') as string,
    value: i18n.t('deconve.onboarding.tag.region.value') as string,
    color: '#54D162',
    created_at: moment.utc().subtract(30, 'days').toISOString(),
  },
  {
    id: '00000000-0000-0000-0000-000000000001',
    name: i18n.t('deconve.onboarding.tag.state.name') as string,
    value: i18n.t('deconve.onboarding.tag.state.value') as string,
    color: '#8F93FF',
    created_at: moment.utc().subtract(15, 'days').toISOString(),
  },
  {
    id: '00000000-0000-0000-0000-000000000002',
    name: i18n.t('deconve.onboarding.tag.city00.name') as string,
    value: i18n.t('deconve.onboarding.tag.city00.value') as string,
    color: '#ED7777',
    created_at: moment.utc().subtract(10, 'days').toISOString(),
  },
  {
    id: '00000000-0000-0000-0000-000000000003',
    name: i18n.t('deconve.onboarding.tag.city01.name') as string,
    value: i18n.t('deconve.onboarding.tag.city01.value') as string,
    color: '#ED7777',
    created_at: moment.utc().subtract(10, 'days').toISOString(),
  },
];

export function getDemoTags(): Promise<Tag[]> {
  return new Promise((resolve) => {
    resolve(tags);
  });
}
