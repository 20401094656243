<template>
  <v-tooltip
    bottom
    :disabled="!tooltipMessage"
  >
    <template v-slot:activator="{ on: tooltip }">
      <v-menu
        offset-y
        offset-x
        left
        rounded="lg"
        :disabled="menuDisabled"
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-btn
            :class="contentClass"
            :color="color"
            :data-cy="dataCy"
            :disabled="disabled"
            :icon="!fab"
            :fab="fab"
            :outlined="outlined"
            v-bind="attrs"
            :small="(fab && !small) || (!fab && $vuetify.breakpoint.mobile)"
            :x-small="fab && small"
            elevation="0"
            @click="$emit('clicked')"
            v-on="{...tooltip, ...menu}"
          >
            <v-icon
              size="20"
              :color="iconColor"
            >
              {{ iconName }}
            </v-icon>
          </v-btn>
        </template>
        <slot />
      </v-menu>
    </template>
    <span>{{ tooltipMessage }}</span>
  </v-tooltip>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

export default {
  name: 'SquareButton',
  props: {
    contentClass: { type: String, required: false, default: '' },
    tooltipMessage: { type: String, required: false, default: '' },
    dataCy: { type: String, require: false, default: 'square-button' },
    disabled: Boolean,
    iconName: { type: String, required: true },
    color: { type: String, required: true },
    iconColor: { type: String, required: true },
    menuDisabled: Boolean,
    fab: Boolean,
    small: Boolean,
    outlined: Boolean,
  },
};
</script>
