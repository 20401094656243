// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { AxiosError } from 'axios';
import {
  SuperProfile, SuperProfilesState, SuperProfilesPreviewPage, SuperProfileImages,
} from './types';
import { ApiRequestError, RootState } from '../../types';

export const getters: GetterTree<SuperProfilesState, RootState> = {
  getSuperProfiles(state): SuperProfilesPreviewPage {
    return state.superProfilesPreviewList;
  },
  getSuperProfile: (state) => (superProfileId: string): SuperProfile | undefined => (
    state.superProfiles.find(({ id }) => id === superProfileId)
  ),
  getSimilarSuperProfile: (state) => (superProfileId: string): SuperProfile | undefined => (
    state.similarSuperProfiles.find(({ id }) => id === superProfileId)
  ),
  getSuperProfilesFiltersSettings(state) {
    return state.superProfileFiltersSettings;
  },
  superProfileData(state): SuperProfile | undefined {
    return state.superProfile.data;
  },
  isLoadingSuperProfile(state): boolean {
    return state.superProfile.isLoading;
  },
  editSuperProfileError(state): AxiosError | ApiRequestError | undefined {
    const { error } = state.superProfile as { error: AxiosError };

    if (error?.response) {
      const { data: errorData } = error.response as { data: ApiRequestError };

      if (errorData) {
        return errorData;
      }
    }

    return state.superProfile.error;
  },
  getSuperProfileImagesReviewer(state): SuperProfileImages | undefined {
    return state.superProfileReviewer.data;
  },
};

export default getters;
