// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { PersonFilesState, types } from './types';

export const mutations: MutationTree<PersonFilesState> = {
  [types.GET_FACEID_PERSON_FILE_REQUEST](state, data) {
    state.files.push(data);
  },
  [types.GET_FACEID_PERSON_FILE_SUCCESS](state, data) {
    const newStateIndex = state.files.findIndex(
      (file) => file.originalName === data.originalName,
    );

    if (newStateIndex !== -1) {
      state.files.splice(newStateIndex, 1, data);
    }
  },
  [types.RESET_FACEID_PERSON_FILES](state) {
    state.files = [];
    state.personId = '';
  },
  [types.SET_FACEID_PERSON_ID_FILES_REQUESTED](state, personId) {
    state.personId = personId;
  },
};

export default mutations;
