// Copyright (C) 2022 Deconve Technology. All rights reserved.

import moment from 'moment';
import { GetterTree } from 'vuex';
import { ReportsState, Report } from './types';
import { RootState } from '../../types';

interface DatasetItem {
  [date: string]: number;
}

export const getters: GetterTree<ReportsState, RootState> = {
  reportsByDay: (state) => state.reportsByDay.data,
  reportsTotal: (state) => state.reportsByDay.totalNumberOfVehicles,
  isLoadingReportsByDay: (state) => state.reportsByDay.isLoading,
  reportsToChartAudience: (state) => state.reportsByDay.audience,
  reportsPercentage: (state) => state.reportsByDay.percentageOfVehicles,
  reportsByDayToChartData(state) {
    // first date and last date are used to create the chart data labels
    let firstValidDate: moment.Moment = moment(null);
    let lastValidDate: moment.Moment = moment(null);

    const dateFormat = 'DD/MM/YYYY';
    const dataByDate: DatasetItem = {};

    state.reportsByDay.data.forEach((dataByVideo: Report[]) => {
      dataByVideo.forEach((item: Report) => {
        const {
          created_at: createdAt, local_time_zone: timeZone, direction_in: directionIn,
          direction_out: directionOut, direction_undefined: directionUndefined,
        } = item;

        const date = moment.utc(createdAt).add(timeZone, 'hours');

        if (!firstValidDate.isValid() || date.isBefore(firstValidDate)) {
          firstValidDate = moment(date);
        }

        if (!lastValidDate.isValid() || date.isAfter(lastValidDate)) {
          lastValidDate = moment(date);
        }

        const dateInString = date.format(dateFormat);

        let total = 0;

        if (directionIn && directionIn?.total) {
          total += directionIn.total;
        }

        if (directionOut && directionOut?.total) {
          total += directionOut.total;
        }

        if (directionUndefined && directionUndefined?.total) {
          total += directionUndefined.total;
        }

        if (dateInString in dataByDate) {
          dataByDate[dateInString] += total;
        } else {
          dataByDate[dateInString] = total;
        }
      });
    });

    const labels: string[] = [];
    const data: number[] = [];

    if (firstValidDate.isValid()) {
      while (firstValidDate.isSameOrBefore(lastValidDate)) {
        const dateLabel = firstValidDate.format(dateFormat);

        labels.push(dateLabel);

        data.push(dateLabel in dataByDate ? dataByDate[dateLabel] : 0);
        firstValidDate.add(1, 'day');
      }
    }

    return { labels, data };
  },
};

export default getters;
