// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { ReportsState, types } from './types';

export const mutations: MutationTree<ReportsState> = {
  [types.GET_DOOH_AUDIENCE_BY_PANEL_SUCCESS](state, data) {
    state.reportsByDay.audience = data;
    state.reportsByDay.isLoading = false;
  },
  [types.GET_DOOH_PEOPLE_BY_VEHICLE_SUCCESS](state, data) {
    state.reportsByDay.byVehicles = data;
    state.reportsByDay.isLoading = false;
  },
  [types.GET_DOOH_TOTAL_PEOPLE_IMPACTED_SUCCESS](state, total: number) {
    state.numberOfPeopleImpacted = total;
  },
  [types.GET_DOOH_TOTAL_VEHICLES_IMPACTED_SUCCESS](state, total: number) {
    state.numberOfVehiclesImpacted = total;
  },
  [types.GET_DOOH_TOTAL_VEHICLES_SUCCESS](state, total: number) {
    state.totalNumberOfVehicles = total;
  },
  [types.GET_DOOH_TOTAL_PEOPLE_IMPACTED_BY_CAMPAIGN_SUCCESS](state, total: number) {
    state.numberOfPeopleImpactedByCampaign = total;
  },
  [types.GET_DOOH_TOTAL_VEHICLES_IMPACTED_BY_CAMPAIGN_SUCCESS](state, total: number) {
    state.numberOfVehiclesImpactedByCampaign = total;
  },
  [types.GET_DOOH_TOTAL_VEHICLES_BY_CAMPAIGN_SUCCESS](state, total: number) {
    state.totalNumberOfVehiclesByCampaign = total;
  },
  [types.GET_DOOH_CAMPAIGN_COST_PER_MILE_IMPRESSIONS_SUCCESS](state, total: number) {
    state.campaignCostPerMileImpressions = total;
  },
  [types.GET_DOOH_REPORTS_BY_DAY_REQUEST](state) {
    state.reportsByDay.isLoading = true;
  },
  [types.GET_DOOH_REPORTS_BY_DAY_SUCCESS](state, data) {
    state.reportsByDay.byPanel = data;
    state.reportsByDay.isLoading = false;
  },
  [types.GET_DOOH_REPORTS_BY_DAY_FAILURE](state) {
    state.reportsByDay.isLoading = false;
  },
};

export default mutations;
