// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import {
  Video, Unit, VideosState, types,
} from './types';

export const mutations: MutationTree<VideosState> = {
  [types.GET_RETAIL_ANALYTICS_VIDEOS_SUCCESS](state, videos: Video[]) {
    state.videos = videos;
  },
  [types.GET_RETAIL_ANALYTICS_VIDEO_GROUPS_SUCCESS](state, groups: Unit[]) {
    state.videoGroups = groups;
  },
};

export default mutations;
