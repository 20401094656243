// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { VideosState, types } from './types';

export const mutations: MutationTree<VideosState> = {
  [types.GET_OBJECT_COUNTER_VIDEOS_REQUEST](state) {
    state.isLoading = true;
  },
  [types.GET_OBJECT_COUNTER_VIDEOS_SUCCESS](state, data) {
    state.isLoading = false;
    state.videos = data;
  },
  [types.GET_OBJECT_COUNTER_VIDEOS_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
};

export default mutations;
