// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import { RequestParams, getDataWithPagination } from '@/utils/getDataWithPagination';
import { NotificationReportsState, types } from './types';
import { RootState } from '../../types';
import { getDemoVideos } from '../../videos/demo';

import { getDemoNotificationStatistics, getDemoNotificationReports } from './demo';

interface ReportQuery {
  startDate: string;
  endDate: string;
  groupBy: string;
  notificationTags: string[];
  noNotificationTags: boolean;
  peopleTags: string[];
  noPeopleTags: boolean;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
  limit?: number;
  skip?: number;
  personId?: string;
  videoId?: string;
  formatType?: string;
  locale?: string;
}

interface ParsedReportQuery {
  start_date: string;
  end_date: string;
  group_by: string;
  search?: string;
  limit?: number;
  sort_by?: string;
  sort_order?: string;
  skip?: number;
  video_id?: string;
  person_id?: string;
  format_type?: string;
  locale?: string;
}

interface ReportParamsAndUrl {
  params: RequestParams;
  url: string;
}

interface RootGetters {
  authorizationToken: string;
}

function parseTagsToUrlPattern(prefix: string, noTags: boolean, tags: string[]): string {
  if (noTags) {
    return prefix;
  }

  return tags.map((tag) => `${prefix}=${tag}`).join('&');
}

function getReportRequestParamsAndUrl(queryOption: ReportQuery): ReportParamsAndUrl {
  const {
    endDate,
    startDate,
    groupBy,
    personId,
    videoId,
    notificationTags,
    noNotificationTags,
    peopleTags,
    noPeopleTags,
    search,
    sortBy,
    sortOrder,
  } = queryOption;
  const host = process.env.VUE_APP_DECONVE_API_URL;
  let url = `${host}/faceid/reports/`;

  let urlParams = parseTagsToUrlPattern('tag_ids', noNotificationTags, notificationTags);
  const peopleTagsParams = parseTagsToUrlPattern('people_tag_ids', noPeopleTags, peopleTags);

  if (urlParams.length > 0 && peopleTagsParams.length > 0) {
    urlParams += '&';
  }

  urlParams += peopleTagsParams;

  if (urlParams.length > 0) {
    url += `?${urlParams}`;
  }

  const params: RequestParams = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    start_date: startDate,
    // eslint-disable-next-line @typescript-eslint/camelcase
    end_date: endDate,
    // eslint-disable-next-line @typescript-eslint/camelcase
    group_by: groupBy,
    skip: 0,
    limit: 1000,
  };

  if (search) {
    params.search = search;
  }

  if (personId) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    params.person_id = personId;
  }

  if (videoId) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    params.video_id = videoId;
  }

  if (sortBy) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    params.sort_by = sortBy;
  }

  if (sortOrder) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    params.sort_order = sortOrder;
  }

  return { url, params };
}

// Getting report by video is not improving the request response time
function getReportsByVideo(
  queryOptions: ReportQuery, rootGetters: RootGetters, videoIds: string[],
): Promise<unknown[]> {
  return new Promise((resolve, reject) => {
    const promises: Promise<unknown[]>[] = [];

    videoIds.forEach((videoId) => {
      const options: ReportQuery = { ...queryOptions, videoId };
      const { url, params } = getReportRequestParamsAndUrl(options);

      promises.push(getDataWithPagination(url, params, rootGetters));
    });

    Promise.all(promises).then((results) => {
      const items = results.reduce(
        (previousValue, currentValue) => previousValue.concat(currentValue), [],
      );

      resolve(items);
    }).catch((error) => {
      reject(error);
    });
  });
}

export const actions: ActionTree<NotificationReportsState, RootState> = {
  getNotificationStatistics({ commit, rootGetters }): void {
    commit(types.GET_FACEID_NOTIFICATIONS_STATISTICS_REQUEST);

    const requestOptions: AxiosRequestConfig = {
      method: 'get',
      baseURL: process.env.VUE_APP_DECONVE_API_URL,
      url: '/faceid/reports/stats/',
      headers: {
        Authorization: rootGetters.authorizationToken,
      },
    };

    if (rootGetters.isDemoMode) {
      getDemoNotificationStatistics().then((data) => {
        commit(types.GET_FACEID_NOTIFICATIONS_STATISTICS_SUCCESS, data);
      });
    } else {
      axios(requestOptions).then((response) => {
        const { data } = response;

        commit(types.GET_FACEID_NOTIFICATIONS_STATISTICS_SUCCESS, data);
      }).catch(() => {
        commit(types.GET_FACEID_NOTIFICATIONS_STATISTICS_FAILURE);
      });
    }
  },
  getNotificationReports({ commit, rootGetters }, queryOptions: ReportQuery): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_FACEID_NOTIFICATIONS_REPORTS_REQUEST);
      const { url, params } = getReportRequestParamsAndUrl(queryOptions);

      if (rootGetters.isDemoMode) {
        const { group_by: groupBy } = params;

        getDemoNotificationReports(groupBy).then((data) => {
          commit(types.GET_FACEID_NOTIFICATIONS_REPORTS_SUCCESS, data);
          resolve();
        });
      } else {
        getDataWithPagination(url, params, rootGetters).then((items) => {
          commit(types.GET_FACEID_NOTIFICATIONS_REPORTS_SUCCESS, { items });
          resolve();
        }).catch(() => {
          commit(types.GET_FACEID_NOTIFICATIONS_REPORTS_FAILURE);
          reject();
        });
      }
    });
  },
  getNotificationReportsByNotificationDelay(
    { commit, rootGetters }, queryOptions: ReportQuery,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_REQUEST);
      const options: ReportQuery = { ...queryOptions, groupBy: 'notification_delay' };
      const { url, params } = getReportRequestParamsAndUrl(options);

      if (rootGetters.isDemoMode) {
        const { group_by: groupBy } = params;

        getDemoNotificationReports(groupBy).then((data) => {
          commit(types.GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_SUCCESS, data);
          resolve();
        });
      } else {
        getDataWithPagination(url, params, rootGetters).then((items) => {
          commit(types.GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_SUCCESS, { items });
          resolve();
        }).catch(() => {
          commit(types.GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_FAILURE);
          reject();
        });
      }
    });
  },
  getNotificationVideoSources({ commit, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_FACEID_NOTIFICATION_VIDEO_SOURCES_REQUEST);

      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: '/faceid/videos/',
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      if (rootGetters.isDemoMode) {
        getDemoVideos().then((videos) => {
          commit(types.GET_FACEID_NOTIFICATION_VIDEO_SOURCES_SUCCESS, videos);
          resolve();
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response;

          commit(types.GET_FACEID_NOTIFICATION_VIDEO_SOURCES_SUCCESS, data);
          resolve();
        }).catch(() => {
          commit(types.GET_FACEID_NOTIFICATION_VIDEO_SOURCES_FAILURE);
          reject();
        });
      }
    });
  },
  downloadNotificationReports({ commit, rootGetters }, options: ReportQuery): Promise<void> {
    const { formatType, locale } = options;

    return new Promise((resolve, reject) => {
      commit(types.DOWNLOAD_FACEID_REPORTS_REQUEST);

      const { url, params } = getReportRequestParamsAndUrl(options);

      if (formatType) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        params.format_type = formatType;
      }

      if (locale) {
        params.locale = locale;
      }

      const requestOptions: AxiosRequestConfig = {
        url,
        params,
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        responseType: 'blob',
      };

      axios(requestOptions).then((response) => {
        const path = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = path;
        link.setAttribute('download', 'deconve_faceid_report.csv');
        document.body.appendChild(link);
        link.click();

        commit(types.DOWNLOAD_FACEID_REPORTS_SUCCESS);
        resolve();
      }).catch((error) => {
        commit(types.DOWNLOAD_FACEID_REPORTS_FAILURE, error);
        reject(error);
      });
    });
  },
};

export default actions;
