// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { Video, VideosState, types } from './types';

export const mutations: MutationTree<VideosState> = {
  [types.GET_VIDEOS_SUCCESS](state, videos: Video[]) {
    state.videos = videos;
  },
  [types.DELETE_UNIT_PANEL_SUCCESS](state, id) {
    const videoIndex = state.videos.findIndex((video) => video.id === id);

    state.videos.splice(videoIndex, 1);
  },
};

export default mutations;
