// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { Report, ReportsState, types } from './types';

export const mutations: MutationTree<ReportsState> = {
  [types.GET_PEOPLE_COUNTER_REPORTS_BY_DAY_SUCCESS](state, data: Report[][]) {
    state.reportsByDay = data;
  },
  [types.GET_PEOPLE_COUNTER_REPORTS_BY_HOUR_SUCCESS](state, data: Report[][]) {
    state.reportsByHour = data;
  },
  [types.GET_PEOPLE_COUNTER_REPORTS_TOTAL_BY_VIDEO_SUCCESS](state, data) {
    state.totalByVideo = data;
  },
  [types.GET_PEOPLE_COUNTER_REPORTS_TOTAL_PEOPLE_SUCCESS](state, total: number) {
    state.totalNumberOfPeople = total;
  },
};

export default mutations;
