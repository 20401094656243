// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios from 'axios';

import { RootState } from '../../types';
import { getReports } from '../../../api/vehiclecounter/report';

import { Unit, PeopleFlowSettings } from '../../units/types';
import { Campaign, UnitInfo } from '../campaigns/types';

import {
  PeopleReport, PercentageByVehicleType, ReportsState, Report, types,
} from './types';

interface Config {
  total: number;
  exposureRate: number;
}

interface ReportQuery {
  startDate: string;
  endDate: string;
  groupBy: string;
  campaign?: Campaign;
  panelId?: string;
  panelIds?: string[];
}

interface ReportByPanelQuery {
  startDate: string;
  endDate: string;
  panelId: string;
  exposureRate: number;
}

interface AudienceByPanel {
  data: number[];
  labels: string[];
}

interface DownloadReportyOptions {
  start_date: string;
  end_date: string;
  group_by: string;
  report_type: string;
  video_ids?: string;
}

function calcPercentage(partial: number, total: number): string {
  if (total > 0) {
    const ratio = partial / total;
    const percentage = `${(ratio * 100).toFixed(2)}%`;

    return percentage;
  }

  return '0%';
}

function populateEntries(
  bus: number, car: number, motorbike: number, truck: number,
  total: number,
): PercentageByVehicleType {
  const vehiclesEntries: PercentageByVehicleType = {
    bus: { total: bus, percentage: calcPercentage(bus, total) },
    car: { total: car, percentage: calcPercentage(car, total) },
    motorbike: { total: motorbike, percentage: calcPercentage(motorbike, total) },
    truck: { total: truck, percentage: calcPercentage(truck, total) },
  };

  return vehiclesEntries;
}

function sortDataAndLabels(data: number[], labels: string[]): AudienceByPanel {
  // Data is sorted in descending order and, when there is multiple data with same value, the
  // labels are sorted in ascending order:
  // unsorted: C: 0, Bb: 0, Ba: 0, D: 1
  // sorted:   D: 1, Ba: 0, Bb: 0, C: 0
  const sortedData: number[] = [];
  const sortedLabels: string[] = [];

  const dataAndLabels: { [value: number]: string[] } = {};

  data.forEach((value, index) => {
    if (value in dataAndLabels) {
      dataAndLabels[value].push(labels[index]);
    } else {
      dataAndLabels[value] = [labels[index]];
    }
  });

  let sortedValueKeys: number[] = [];

  Object.keys(dataAndLabels).forEach((value) => sortedValueKeys.push(Number(value)));

  sortedValueKeys = sortedValueKeys.sort((a, b) => (a > b ? -1 : 1));

  sortedValueKeys.forEach((value) => {
    const valueLabels = dataAndLabels[value].sort((a, b) => (a > b ? 1 : -1));

    valueLabels.forEach((label: string) => {
      sortedData.push(value);
      sortedLabels.push(label);
    });
  });

  return { data: sortedData, labels: sortedLabels };
}

// TODO: improve array allocation

function computeNumberOfPeople(
  report: Report, peopleFlowSettings: PeopleFlowSettings, exposureRate: number,
): PeopleReport {
  const {
    video,
    created_at: createdAt,
    local_time_zone: localTimeZone,
    direction_in: directionIn,
    direction_out: directionOut, direction_undefined: directionUndefined,
  } = report;

  // Since in the DOOH analytics we are not considering the vehicle direction, we can
  // merge the directions here. Usually, we have data only for the `in` direction.
  let numberOfBuses = 0;
  let numberOfCars = 0;
  let numberOfMotorbikes = 0;
  let numberOfTrucks = 0;

  if (directionIn?.total > 0) {
    const {
      bus, car, motorbike, truck,
    } = directionIn;

    numberOfBuses += bus;
    numberOfCars += car;
    numberOfMotorbikes += motorbike;
    numberOfTrucks += truck;
  }

  if (directionOut?.total > 0) {
    const {
      bus, car, motorbike, truck,
    } = directionOut;

    numberOfBuses += bus;
    numberOfCars += car;
    numberOfMotorbikes += motorbike;
    numberOfTrucks += truck;
  }

  if (directionUndefined?.total > 0) {
    const {
      bus, car, motorbike, truck,
    } = directionUndefined;

    numberOfBuses += bus;
    numberOfCars += car;
    numberOfMotorbikes += motorbike;
    numberOfTrucks += truck;
  }

  const numberOfVehicles = numberOfBuses + numberOfCars + numberOfMotorbikes + numberOfTrucks;

  numberOfBuses *= exposureRate;
  numberOfCars *= exposureRate;
  numberOfMotorbikes *= exposureRate;
  numberOfTrucks *= exposureRate;

  // To prevent the user from getting confused when adding up the number of people and comparing
  // with the total, we round the number of people here
  const numberOfVehiclesImpacted = Math.round(
    numberOfBuses + numberOfCars + numberOfMotorbikes + numberOfTrucks,
  );

  const numberOfPeopleByBus = Math.round(numberOfBuses * peopleFlowSettings.bus);
  const numberOfPeopleByCar = Math.round(numberOfCars * peopleFlowSettings.car);
  const numberOfPeopleByMotorbike = Math.round(numberOfMotorbikes * peopleFlowSettings.motorbike);
  const numberOfPeopleByTruck = Math.round(numberOfTrucks * peopleFlowSettings.truck);

  const total = numberOfPeopleByBus + numberOfPeopleByCar + numberOfPeopleByMotorbike
    + numberOfPeopleByTruck;

  const peopleByVehicles = {
    bus: numberOfPeopleByBus,
    car: numberOfPeopleByCar,
    motorbike: numberOfPeopleByMotorbike,
    truck: numberOfPeopleByTruck,
    total,
  };

  const output: PeopleReport = {
    video,
    createdAt,
    localTimeZone,
    peopleByVehicles,
    numberOfVehicles,
    numberOfVehiclesImpacted,
  };

  return output;
}

export const actions: ActionTree<ReportsState, RootState> = {
  downloadReportByHour(
    { rootGetters }, { startDate, endDate, panelId }: ReportQuery,
  ) {
    const params: DownloadReportyOptions = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      start_date: startDate,
      // eslint-disable-next-line @typescript-eslint/camelcase
      end_date: endDate,
      // eslint-disable-next-line @typescript-eslint/camelcase
      group_by: 'hour',
      // hour_offset: dashboardSettings.getValue('hourOffset', 0),
      // eslint-disable-next-line @typescript-eslint/camelcase
      report_type: 'xlsx',
    };

    if (panelId) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params.video_ids = panelId;
    }

    const { authorizationToken } = rootGetters;

    const host = process.env.VUE_APP_DECONVE_VEHICLE_COUNTER_API_URL
      || process.env.VUE_APP_DECONVE_API_URL;
    const url = `${host}/vehiclescounter/reports/`;
    const headers = { Authorization: authorizationToken };

    return axios.get(url, { params, headers, responseType: 'blob' })
      .then((response) => {
        const path = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = path;
        link.setAttribute('download', 'report.xlsx');
        document.body.appendChild(link);
        link.click();
        return true;
      });
  },
  getPanelReportsByDay(
    { rootGetters }, queryOptions: ReportByPanelQuery,
  ): Promise<PeopleReport[][]> {
    const {
      startDate, endDate, panelId, exposureRate,
    } = queryOptions;

    return new Promise((resolve, reject) => {
      const getPeopleFlowSettings = rootGetters['units/getPeopleFlowSettings'];
      const getPanelVideos = rootGetters['units/getUnitVideos'];
      const findVideoByUuid = rootGetters['vehiclecounter/findVideoByUuid'];

      const peopleFlowSettings: PeopleFlowSettings = getPeopleFlowSettings(panelId);
      const panelVideos = getPanelVideos(panelId);

      // Get reports grouped by day for each video in the panel
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any[]>[] = [];

      panelVideos.forEach((panelVideo: { id: string }) => {
        const { id: videoUuid } = panelVideo;
        const video = findVideoByUuid(videoUuid);

        if (video) {
          const params = {
            startDate,
            endDate,
            groupBy: 'day',
            videoId: video.id,
          };

          promises.push(getReports(params, rootGetters));
        }
      });

      // Here we can not just merge the videos reports. In a panel with two cameras, for example,
      // if one camera is offline at X day, we won't have data in that day
      Promise.all(promises).then((reportByVideos) => {
        const peopleByVideos: PeopleReport[][] = [];

        reportByVideos.forEach((reportByVideo: Report[]) => {
          const peopleByVideo: PeopleReport[] = [];

          reportByVideo.forEach((report: Report) => {
            peopleByVideo.push(computeNumberOfPeople(report, peopleFlowSettings, exposureRate));
          });

          peopleByVideos.push(peopleByVideo);
        });

        resolve(peopleByVideos);
      }).catch((error) => reject(error));
    });
  },
  setPanelData(
    { commit, rootGetters },
    { panelIds, reportsByPanels }: { panelIds: string[]; reportsByPanels: PeopleReport[][][] },
  ): Promise<void> {
    return new Promise((resolve) => {
      let numberOfPeopleByBus = 0;
      let numberOfPeopleByCar = 0;
      let numberOfPeopleByMotorbike = 0;
      let numberOfPeopleByTruck = 0;
      let totalNumberOfVehicles = 0;
      let totalNumberOfVehiclesImpacted = 0;
      const totalByPanels: number[] = [];

      reportsByPanels.forEach((reportsByPanel) => {
        let totalByPanel = 0;

        reportsByPanel.forEach((reportByVideo) => {
          reportByVideo.forEach((item) => {
            const { peopleByVehicles, numberOfVehicles, numberOfVehiclesImpacted } = item;
            const {
              bus, car, motorbike, truck, total,
            } = peopleByVehicles;

            numberOfPeopleByBus += bus;
            numberOfPeopleByCar += car;
            numberOfPeopleByMotorbike += motorbike;
            numberOfPeopleByTruck += truck;
            totalByPanel += total;
            totalNumberOfVehicles += numberOfVehicles;
            totalNumberOfVehiclesImpacted += numberOfVehiclesImpacted;
          });
        });

        totalByPanels.push(totalByPanel);
      });

      const totalNumberOfPeopleImpacted = numberOfPeopleByBus + numberOfPeopleByCar
          + numberOfPeopleByMotorbike + numberOfPeopleByTruck;

      commit(types.GET_DOOH_TOTAL_PEOPLE_IMPACTED_SUCCESS, totalNumberOfPeopleImpacted);
      commit(types.GET_DOOH_TOTAL_VEHICLES_SUCCESS, totalNumberOfVehicles);
      commit(types.GET_DOOH_TOTAL_VEHICLES_IMPACTED_SUCCESS, totalNumberOfVehiclesImpacted);

      const getPanelById = rootGetters['units/getUnit'];
      const panelNames: string[] = [];

      panelIds.forEach((pid) => {
        const panel = getPanelById(pid);

        panelNames.push(panel.name);
      });

      commit(types.GET_DOOH_REPORTS_BY_DAY_SUCCESS, reportsByPanels);
      const audienceByPanel = sortDataAndLabels(totalByPanels, panelNames);

      commit(types.GET_DOOH_AUDIENCE_BY_PANEL_SUCCESS, audienceByPanel);

      const vehiclesEntries = populateEntries(
        numberOfPeopleByBus, numberOfPeopleByCar, numberOfPeopleByMotorbike,
        numberOfPeopleByTruck, totalNumberOfPeopleImpacted,
      );

      commit(types.GET_DOOH_PEOPLE_BY_VEHICLE_SUCCESS, vehiclesEntries);

      resolve();
    });
  },
  setCampaignData(
    { commit },
    {
      campaignValue,
      reportsByPanels,
    }: {
      campaignValue: number;
      reportsByPanels: PeopleReport[][][];
    },
  ): Promise<void> {
    return new Promise((resolve) => {
      let totalNumberOfVehicles = 0;
      let totalNumberOfVehiclesImpacted = 0;
      let totalNumberOfPeopleImpacted = 0;

      reportsByPanels.forEach((reportsByPanel) => {
        reportsByPanel.forEach((reportByVideo) => {
          reportByVideo.forEach((item) => {
            const { peopleByVehicles, numberOfVehicles, numberOfVehiclesImpacted } = item;
            const { total } = peopleByVehicles;

            totalNumberOfPeopleImpacted += total;
            totalNumberOfVehicles += numberOfVehicles;
            totalNumberOfVehiclesImpacted += numberOfVehiclesImpacted;
          });
        });
      });

      let cpm = 0;

      if (totalNumberOfPeopleImpacted > 0 && campaignValue >= 0) {
        cpm = (campaignValue / totalNumberOfPeopleImpacted) * 1000.0;
      }

      commit(types.GET_DOOH_CAMPAIGN_COST_PER_MILE_IMPRESSIONS_SUCCESS, cpm);
      commit(
        types.GET_DOOH_TOTAL_PEOPLE_IMPACTED_BY_CAMPAIGN_SUCCESS,
        totalNumberOfPeopleImpacted,
      );
      commit(types.GET_DOOH_TOTAL_VEHICLES_BY_CAMPAIGN_SUCCESS, totalNumberOfVehicles);
      commit(
        types.GET_DOOH_TOTAL_VEHICLES_IMPACTED_BY_CAMPAIGN_SUCCESS,
        totalNumberOfVehiclesImpacted,
      );

      resolve();
    });
  },
  getReportsByDay(
    { commit, dispatch, rootGetters }, {
      startDate, endDate, campaign, panelIds,
    }: ReportQuery,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_DOOH_REPORTS_BY_DAY_REQUEST);
      // If the campaign is set, we have to compute the cpm, considering all panels.
      // If the panel is set, we have to show the result only for it
      let panelIdsReport: string[] = [];
      const units = campaign?.units;

      if (units) {
        panelIdsReport = units.map(({ id }) => id);
      } else if (panelIds !== undefined) {
        panelIdsReport = panelIds;
      } else {
        const panels: Unit[] = rootGetters['units/units'];

        panelIdsReport = panels.map((panel) => panel.id);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];

      let exposureRate = 1.0;

      panelIdsReport.forEach((pid: string) => {
        if (units) {
          const panelInfo = units.find((u: UnitInfo) => u.id === pid);

          exposureRate = panelInfo?.exposure_rate || 1.0;
        }

        promises.push(dispatch('getPanelReportsByDay', {
          startDate, endDate, panelId: pid, exposureRate,
        }));
      });

      Promise.all(promises).then((reportsByPanels: PeopleReport[][][]) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const setDataPromises: Promise<any>[] = [];

        if (campaign) {
          const { value: campaignValue } = campaign;

          setDataPromises.push(dispatch('setCampaignData', { campaignValue, reportsByPanels }));
        }

        if (panelIds === undefined) {
          setDataPromises.push(dispatch('setPanelData', { panelIds: panelIdsReport, reportsByPanels }));
        } else {
          const reportsByPanelsIds: PeopleReport[][][] = [];

          // If panelIds is set, only reports of the selected panels is set
          panelIds.forEach((panelId: string) => {
            const panelIndex = panelIdsReport.findIndex((pid) => pid === panelId);

            reportsByPanelsIds.push(reportsByPanels[panelIndex]);
          });
          setDataPromises.push(dispatch('setPanelData', { panelIds, reportsByPanels: reportsByPanelsIds }));
        }

        resolve();
      }).catch((error) => {
        commit(types.GET_DOOH_REPORTS_BY_DAY_FAILURE);
        reject(error);
      });
    });
  },
  resetCampaignData({ commit }): void {
    commit(types.GET_DOOH_CAMPAIGN_COST_PER_MILE_IMPRESSIONS_SUCCESS, -1);
    commit(types.GET_DOOH_TOTAL_PEOPLE_IMPACTED_BY_CAMPAIGN_SUCCESS, 0);
    commit(types.GET_DOOH_TOTAL_VEHICLES_BY_CAMPAIGN_SUCCESS, 0);
    commit(types.GET_DOOH_TOTAL_VEHICLES_IMPACTED_BY_CAMPAIGN_SUCCESS, 0);
  },
};

export default actions;
