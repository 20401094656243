// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { Video, Unit, VideosState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<VideosState, RootState> = {
  videos: (state): Video[] => state.videos,
  units: (state): Unit[] => state.videoGroups,
};

export default getters;
