// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { getDataWithPagination } from '@/utils/getDataWithPagination';

import {
  types, Unit, UnitsState, PeopleFlowSettings, UnitsIteratorSettings,
} from './types';
import { RootState } from '../types';
import getDemoUnits from './demo';

interface UnitOptions {
  unitId?: string;
  name: string;
  videoIds: string[];
  tagIds: string[];
  peopleFlowSettings: PeopleFlowSettings;
  enableImagePreview: boolean;
  enableAutoUpdate: boolean;
  longitude: number;
  latitude: number;
}

export const actions: ActionTree<UnitsState, RootState> = {
  fetchUnit({ rootGetters }, unitId: string): Promise<Unit> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/units/${unitId}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      if (rootGetters.isDemoMode) {
        getDemoUnits().then((data) => {
          const { items } = data;

          const unit = items.find(({ id }) => id === unitId);

          resolve(unit as Unit);
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response;

          resolve(data);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  },
  fetchUnitsHelper({ commit, rootGetters }, workspaceId = undefined): Promise<void> {
    return new Promise((resolve, reject) => {
      if (rootGetters.isDemoMode) {
        getDemoUnits().then((data) => {
          const { items: units } = data;

          commit(types.GET_UNITS_SUCCESS, units);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      } else {
        const headers: AxiosRequestHeaders = {};

        if (workspaceId) {
          headers.workspaceId = workspaceId;
        }

        const host = process.env.VUE_APP_DECONVE_API_URL;
        const url = `${host}/units/`;
        const params = { skip: 0, limit: 100 };

        getDataWithPagination(url, params, rootGetters, headers).then((units) => {
          commit(types.GET_UNITS_SUCCESS, units);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      }
    });
  },
  fetchUnits({ dispatch }, workspaceId = undefined): Promise<void> {
    return new Promise((resolve) => {
      resolve(dispatch('fetchUnitsHelper', workspaceId));
    });
  },
  fetchUnitsPage({ commit, state, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!state.unitsIteratorSettings) {
        resolve(undefined);
        return;
      }

      const {
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        search,
        status,
        tags,
        noTags,
        workspaceId,
        createdAfter,
        createdBefore,
      } = state.unitsIteratorSettings as UnitsIteratorSettings;

      const skip = itemsPerPage * (page - 1);

      let url = '/units/';

      // To be able to filter data without tags, we add them to the url
      if (noTags) {
        url += '?tag_ids';
      } else {
        (tags as string[]).forEach((tagId, index) => {
          if (!index) {
            url += `?tag_ids=${tagId}`;
          } else {
            url += `&tag_ids=${tagId}`;
          }
        });
      }

      const headers: AxiosRequestHeaders = { Authorization: rootGetters.authorizationToken };

      if (workspaceId) {
        headers.workspaceId = workspaceId;
      }

      const requestOptions: AxiosRequestConfig = {
        url,
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        params: {
          limit: itemsPerPage,
          skip,
          search,
          status,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sort_by: sortBy,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sort_order: sortOrder,
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_after: createdAfter,
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_before: createdBefore,
        },
        headers,
      };

      if (rootGetters.isDemoMode) {
        getDemoUnits().then((data) => {
          commit(types.GET_UNITS_PAGE_SUCCESS, data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response;

          commit(types.GET_UNITS_PAGE_SUCCESS, data);
          resolve();
        }).catch((error) => {
          reject(error);
        });
      }
    });
  },
  addUnit({ dispatch, rootGetters }, params: UnitOptions): Promise<string> {
    return new Promise((resolve, reject) => {
      const {
        name, videoIds, tagIds, peopleFlowSettings,
        enableImagePreview, enableAutoUpdate, longitude, latitude,
      } = params;

      const unitData = {
        name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        video_ids: videoIds,
        // eslint-disable-next-line @typescript-eslint/camelcase
        tag_ids: tagIds,
        // eslint-disable-next-line @typescript-eslint/camelcase
        people_flow_settings: peopleFlowSettings,
        // eslint-disable-next-line @typescript-eslint/camelcase
        enable_image_preview: enableImagePreview,
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_auto_update_enabled: enableAutoUpdate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        gps_longitude: longitude,
        // eslint-disable-next-line @typescript-eslint/camelcase
        gps_latitude: latitude,
      };

      const requestOptions: AxiosRequestConfig = {
        method: 'POST',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: '/units/',
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        data: unitData,
      };

      axios(requestOptions).then((response) => {
        const { data } = response;
        const { unit_id: unitId } = data;

        dispatch('fetchUnitsHelper');
        resolve(unitId);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  deleteUnit({ commit, dispatch, rootGetters }, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'delete',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/units/${id}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then(() => {
        commit(types.DELETE_UNIT_PANEL_SUCCESS, id);
        dispatch('fetchUnitsHelper');
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  editUnit({ dispatch, rootGetters }, params: UnitOptions): Promise<void> {
    return new Promise((resolve, reject) => {
      const {
        unitId,
        name,
        videoIds,
        tagIds,
        peopleFlowSettings,
        enableImagePreview,
        enableAutoUpdate,
        longitude,
        latitude,
      } = params;

      const payload = {
        name,
        // eslint-disable-next-line @typescript-eslint/camelcase
        video_ids: videoIds,
        // eslint-disable-next-line @typescript-eslint/camelcase
        tag_ids: tagIds,
        // eslint-disable-next-line @typescript-eslint/camelcase
        people_flow_settings: peopleFlowSettings,
        // eslint-disable-next-line @typescript-eslint/camelcase
        enable_image_preview: enableImagePreview,
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_auto_update_enabled: enableAutoUpdate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        gps_longitude: longitude,
        // eslint-disable-next-line @typescript-eslint/camelcase
        gps_latitude: latitude,
      };

      const requestOptions: AxiosRequestConfig = {
        method: 'put',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/units/${unitId}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        data: payload,
      };

      axios(requestOptions).then(() => {
        dispatch('fetchUnitsHelper');

        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  setUnitsIteratorSettings({ commit }, data) {
    commit('setUnitsIteratorSettings', data);
  },
};

export default actions;
