// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { Occurrence } from './types';

// Keep the data cached for a maximum of 4,5 minutes
const kCacheMaxAgeInMs = 4.5 * 60 * 1000;
const kCacheMinAgeInMs = 1000;

function hasSomeNotificationIdEmpty(data: Occurrence) {
  const isSomeIdEmpty = data.notifications.some((notification) => {
    const { id } = notification;

    if (!id) return true;

    return false;
  });

  return isSomeIdEmpty;
}

// If some url is empty, the data is cached by the minimum time. The time is not zero to avoid a
// too many requests to backend. If the occurrence data is updated every time, in the current
// implementation, the vue component will be too
export function prepareOccurrenceToBeCached(data: Occurrence): Occurrence {
  const areTheNotificationsIdsReady = !hasSomeNotificationIdEmpty(data);

  return { ...data, areTheNotificationsIdsReady, requestedAtMs: Date.now() };
}

export function isCachedDataValid(cachedData: Occurrence) {
  if (cachedData) {
    const { areTheNotificationsIdsReady, requestedAtMs } = cachedData;
    const cacheAge = Date.now() - requestedAtMs;
    const cacheAgeLimit = areTheNotificationsIdsReady ? kCacheMaxAgeInMs : kCacheMinAgeInMs;

    return cacheAge < cacheAgeLimit;
  }

  return false;
}

export function hasOccurrenceChanged(currentData: Occurrence, newData: Occurrence): boolean {
  if ((currentData.updated_at !== newData.updated_at)
      || (currentData.expire_at !== newData.expire_at)
      || (currentData.expire_mode !== newData.expire_mode)) {
    return true;
  }

  return !isCachedDataValid(currentData);
}
