// Copyright (C) 2022 Deconve Technology. All rights reserved.

export const types: { [id: string]: string } = {
  GET_PEOPLE_COUNTER_VIDEOS_SUCCESS: 'GET_PEOPLE_COUNTER_VIDEOS_SUCCESS',
  GET_PEOPLE_COUNTER_VIDEOS_GROUP_SUCCESS: 'GET_PEOPLE_COUNTER_VIDEOS_GROUP_SUCCESS',
};

export interface Video {
  id: string;
  name: string;
}

export interface VideosState {
  videos: Video[];
}
