// Copyright (C) 2023 Deconve Technology. All rights reserved.

export const DEFAULT_RINGTONE = 'notification-alert-ringtone.mp3';

export function getRingtoneFileName(ringtone: string): string {
  // To avoid deprecated audio option, we return the default value if required ringtone is not
  // avaliable
  const ringtones = [
    DEFAULT_RINGTONE, 'chord-alert-ringtone.mp3', 'jiggle-alert-ringtone.mp3',
  ];

  return ringtones.includes(ringtone) ? ringtone : DEFAULT_RINGTONE;
}
