// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Person } from './types';

// Keep the data cached for a maximum of 4,5 minutes
const kCacheMaxAgeInMs = 4.5 * 60 * 1000;
const kCacheMinAgeInMs = 1000;

function hasSomeImageUrlEmpty(data: Person) {
  const { profile_image_url: profileImageUrl, images } = data;

  if (!profileImageUrl) return true;

  const isSomeUrlEmpty = images.some((image) => {
    const {
      original_url: imageOriginalUrl,
      thumbnail_url: imageThumbnailUrl,
      medium_quality_url: imageMediumQualityUrl,
    } = image;

    if (!imageOriginalUrl || !imageThumbnailUrl || !imageMediumQualityUrl) {
      return true;
    }

    return false;
  });

  return isSomeUrlEmpty;
}

// If some url is empty, the data is cached by the minimum time. The time is not zero to avoid a
// too many requests to backend. If the person data is updated every time, in the current
// implementation, the vue component will be too
export function preparePersonToBeCached(data: Person) {
  const areTheImageUrlsReady = !hasSomeImageUrlEmpty(data);

  return { ...data, areTheImageUrlsReady, requestedAtMs: Date.now() };
}

export function isCachedDataValid(cachedData: Person) {
  if (cachedData) {
    const { areTheImageUrlsReady, requestedAtMs } = cachedData;
    const cacheAge = Date.now() - requestedAtMs;
    const cacheAgeLimit = areTheImageUrlsReady ? kCacheMaxAgeInMs : kCacheMinAgeInMs;

    return cacheAge < cacheAgeLimit;
  }

  return false;
}

export function hasPersonChanged(currentData: Person, newData: Person): boolean {
  if ((currentData.updated_at !== newData.updated_at)
      || (currentData.expire_at !== newData.expire_at)
      || (currentData.expire_mode !== newData.expire_mode)
      || (currentData.sharing?.current_review.updated_at
        !== newData.sharing?.current_review.updated_at)) {
    return true;
  }

  return !isCachedDataValid(currentData);
}
