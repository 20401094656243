<template>
  <v-app
    id="inspire"
    style="max-height: 100vh; max-width: 100vw;"
  >
    <v-navigation-drawer
      v-model="drawer"
      color="boxBackground"
      clipped
      app
      :mini-variant="!$vuetify.breakpoint.mobile"
      mini-variant-width="76"
      :expand-on-hover="!$vuetify.breakpoint.mobile && drawer"
      @transitionend="onNavigationDrawerTransitionEnd"
    >
      <template v-slot:prepend>
        <v-list class="py-2">
          <v-list-item>
            <v-list-item-avatar
              class="d-flex justify-center align-center rounded"
              size="36"
              :color="workspaceAvatarColor"
            >
              <v-img
                v-if="workspaceImage"
                :src="workspaceImage"
                contain
              />
              <span
                v-else
                class="subtitle-1 white--text"
              >{{ workspaceNameInitial }}</span>
            </v-list-item-avatar>
            <div>
              <v-list-item-title
                class="body-2 font-weight-medium neutralPrimary--text"
              >
                {{ workspaceName }}
              </v-list-item-title>
              <v-list-item-subtitle class="mt-1 caption neutral--text">
                {{ $t('deconve.workspace') }}
              </v-list-item-subtitle>
            </div>
          </v-list-item>
        </v-list>
        <v-divider />
      </template>
      <v-list
        nav
      >
        <div
          v-for="(navOption) in navOptions"
          :key="navOption.name"
        >
          <v-list-group
            v-if="navOption.group"
            v-model="navOption.active"
            no-action
            :group="navOption.group"
            :data-cy="navOption.dataCy"
          >
            <template v-slot:activator>
              <v-list-item-icon
                class="ml-2 mr-2"
              >
                <v-icon
                  size="20"
                >
                  {{ navOption.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title
                class="ml-2 body-2"
              >
                {{ navOption.name }}
              </v-list-item-title>
            </template>
            <v-list-item
              v-for="child in navOption.children"
              :key="child.name"
              :data-cy="child.dataCy"
              link
              class="pl-10"
              :to="child.link"
            >
              <v-list-item-icon
                v-if="child.icon"
                class="mr-2"
              >
                <v-icon
                  size="20"
                >
                  {{ child.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title
                class="body-2"
              >
                {{ child.name }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            v-model="navOption.active"
            :data-cy="navOption.dataCy"
            color="primary"
            link
            :to="navOption.link"
          >
            <v-list-item-icon
              class="ml-2 mr-2"
            >
              <v-icon
                size="20"
              >
                {{ navOption.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="ml-2 body-2"
            >
              {{ navOption.name }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
      <template v-slot:append>
        <v-divider />
        <v-list class="py-1">
          <v-list-item
            @click="logout"
          >
            <v-list-item-icon>
              <v-icon color="neutral">
                {{ icons.mdiLogoutVariant }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-2 font-weight-medium neutral--text">
              {{ $t('deconve.logout') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      color="#FFFFFF"
      elevation="0"
      clipped-left
      clipped-right
      app
      style="border-bottom: 1px solid #0000001f"
    >
      <div
        class="d-flex align-center"
        :style="$vuetify.breakpoint.mobile ? '' : 'width: 30%'"
      >
        <v-app-bar-nav-icon
          v-if="$vuetify.breakpoint.mobile"
          data-cy="drawer"
          class="neutralPrimary--text"
          @click.stop="drawer = !drawer"
        />
        <square-button
          v-else
          menu-disabled
          color="neutralPrimary"
          icon-color="neutralPrimary"
          :icon-name="drawer? icons.mdiMenu : icons.mdiMenuOpen"
          @clicked="drawer = !drawer"
        />
        <v-toolbar-title
          class="d-sm-flex neutralPrimary--text ml-2 subtitle-1"
        >
          {{ getRouteName }}
        </v-toolbar-title>
      </div>
      <div
        v-if="!$vuetify.breakpoint.mobile"
        class="d-flex justify-center"
        style="width: 40%"
      >
        <v-toolbar-title>
          <v-img
            contain
            height="30"
            :src="workspaceBrandImage"
          />
        </v-toolbar-title>
      </div>
      <v-spacer v-else />
      <div
        class="d-flex justify-end"
        :style="$vuetify.breakpoint.mobile ? '' : 'width: 30%'"
      >
        <div
          class="d-flex align-center"
        >
          <div
            class="d-flex align-center pa-1"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="d-none d-sm-flex mr-2 neutralPrimary--text ml-2 subtitle-2"
                >{{ displayUserName }}</span>
                <v-btn
                  icon
                  elevation="0"
                  v-bind="attrs"
                  :small="$vuetify.breakpoint.mobile"
                  v-on="on"
                >
                  <v-avatar
                    size="28"
                    :color="avatarColor"
                  >
                    <v-img
                      v-if="displayUserProfileImage"
                      :src="displayUserProfileImage"
                    />
                    <span
                      v-else
                      class="white--text caption"
                    >
                      {{ displayUserNameInitials }}
                    </span>
                  </v-avatar>
                </v-btn>
              </template>

              <v-list class="px-4 py-4">
                <div>
                  <span
                    class="d-flex align-start body-4 text-sm-body-2
                  letter-spacing-body-2 neutral--text text-uppercase"
                  >
                    {{ $t('deconve.account') }}
                  </span>

                  <v-list-item class="d-flex align-center">
                    <v-avatar
                      size="40"
                      class="mt-2 mb-2"
                      :color="avatarColor"
                    >
                      <v-img
                        v-if="displayUserProfileImage"
                        :src="displayUserProfileImage"
                      />
                      <span
                        v-else
                        class="white--text caption"
                      >
                        {{ displayUserNameInitials }}
                      </span>
                    </v-avatar>
                    <v-col>
                      <v-row
                        class="d-sm-flex mr-2 neutralPrimary--text ml-2 subtitle-2"
                      >
                        {{ displayUserName }}
                      </v-row>
                      <v-row
                        class="text-sm-body-2 neutral--text ml-2"
                        :style="{
                          fontSize: `${$vuetify.breakpoint.mobile ? '12px' : '16px'}`,
                        }"
                      >
                        {{ workspaceName }}
                      </v-row>
                    </v-col>
                  </v-list-item>

                  <v-list-item
                    @click="openManagerAccount"
                  >
                    <span
                      class="d-flex align-start body-4 text-sm-body-2
                      letter-spacing-body-2"
                    >
                      {{ $t('deconve.manageAccount') }}
                    </span>
                  </v-list-item>
                </div>

                <div v-if="!isDemoMode">
                  <v-divider />

                  <span
                    class="d-flex align-start body-4 text-sm-body-2
                  letter-spacing-body-2 neutral--text text-uppercase mt-2"
                  >
                    {{ $t('deconve.workspaces') }}
                  </span>

                  <v-list-item
                    v-for="(item, index) in filteredWorkspaceList"
                    :key="index"
                    style="cursor: pointer"
                    @click="workspaceLogin(item)"
                  >
                    <v-avatar
                      size="28"
                      color="primary"
                      class="mr-2"
                    >
                      <v-img
                        v-if="item.workspace.profile_image"
                        :src="item.workspace.profile_image"
                        style="width: inherit; height: inherit;"
                      />
                      <span
                        v-else
                        style="color: white"
                      >{{ item.workspace.name[0] }}</span>
                    </v-avatar>
                    <span
                      class="d-sm-flex mr-2 neutralPrimary--text ml-2 subtitle-2"
                    >
                      {{ item.workspace.name }}
                    </span>
                  </v-list-item>

                  <v-list-item
                    v-if="exceedsMaxWorkspaces"
                    style="cursor: pointer"
                    @click="openWorkspacesDialog"
                  >
                    <span
                      class="d-flex align-start body-4 text-sm-body-2
                      letter-spacing-body-2"
                    >
                      {{ $t('deconve.myWorkspaces') }}
                    </span>
                  </v-list-item>
                </div>

                <v-divider
                  :style="{
                    marginTop: `${exceedsMaxWorkspaces ? '0px' : '8px'}`
                  }"
                />

                <v-list-item>
                  <v-list-item-title
                    class="body-4 text-sm-body-2 letter-spacing-body-2"
                  >
                    {{ displayTitleModeActivation }}
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-switch
                      v-model="isDemoModeEnabled"
                    />
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  @click="showWhatsNew()"
                >
                  <v-list-item-title
                    class="body-4 text-sm-body-2 letter-spacing-body-2"
                  >
                    {{ $t('deconve.whatIsNew') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="enableNotification(!isNotificationEnabled)"
              >
                <v-icon>
                  {{ isNotificationEnabled ? icons.mdiBellOutline : icons.mdiBellOffOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ isNotificationEnabled ? this.$t('deconve.notificationEnabled') :
                this.$t('deconve.notificationDisabled')
              }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </v-app-bar>
    <v-main
      class="d-flex"
      style="flex: 1; overflow-y: auto;"
    >
      <router-view />
    </v-main>
    <notifier />
    <app-update-notifier />
    <whats-new-notifier ref="whatsNewNotifier" />
    <my-workspaces-dialog
      ref="workspacesDialog"
    />
  </v-app>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import {
  mdiAccountBoxMultiple as faceidNotificationsIcon,
  mdiAccountDetails as retailAnalyticsIcon,
  mdiAccountMultiple as peopleIcon,
  mdiFolderAccount as occurrenceeIcon,
  mdiAccountMultiplePlus as usersIcon,
  mdiCar as vehicleFlowIcon,
  mdiApps as unitsIcon,
  mdiChartBox as reportIcon,
  mdiCogOutline as settingsIcon,
  mdiDotsHorizontalCircle as generalSettingsIcon,
  mdiFaceRecognition as faceIdIcon,
  mdiFolder as workspaceIcon,
  mdiGoogleAnalytics as analyticsIcon,
  mdiLogoutVariant,
  mdiImageFilterCenterFocusStrong as doohAudicenceIcon,
  mdiMenu,
  mdiMenuOpen,
  mdiSecurity as apiKeysIcon,
  mdiShape as objectCounterIcon,
  mdiBellOutline,
  mdiBellOffOutline,
  mdiTools,
  mdiDotsVertical,
  mdiAccountGroup as superProfileIcon,
} from '@mdi/js';

import MyWorkspacesDialog from '@/components/MyWorkspacesDialog.vue';
import AppUpdateNotifier from '../components/AppUpdateNotifier.vue';
import WhatsNewNotifier from '../components/WhatsNewNotifier.vue';
import SquareButton from '../components/SquareButton.vue';
import { updateAbility } from '../services/ability';
import { logInAnotherWorkspace } from '../services/authHelper';
import Notifier from '../components/Notifier.vue';

export default Vue.extend({
  name: 'Index',
  components: {
    AppUpdateNotifier,
    WhatsNewNotifier,
    Notifier,
    SquareButton,
    MyWorkspacesDialog,
  },
  props: {
    source: { type: String, default: '' },
  },
  data: () => ({
    drawer: null,
    navOptions: [],
    isMenuExpanded: false,
    hasRouteChanged: false,
    isDemoModeEnabled: false,
    maxWorkspacesOnPage: 4,
    icons: {
      mdiLogoutVariant,
      mdiMenu,
      mdiMenuOpen,
      mdiBellOutline,
      mdiBellOffOutline,
      mdiDotsVertical,
    },
  }),
  computed: {
    ...mapGetters({
      userName: 'userName',
      userNameInitials: 'userNameInitials',
      userProfileImage: 'userProfileImage',
      userScopes: 'userScopes',
      userRole: 'userRole',
      myWorkspace: 'workspace/myWorkspace',
      isNotificationEnabled: 'isNotificationEnabled',
      isDemoMode: 'isDemoMode',
      workspaceList: 'workspace/workspaceList',
    }),
    exceedsMaxWorkspaces() {
      return this.workspaceList.length > this.maxWorkspacesOnPage;
    },
    displayTitleModeActivation() {
      const isOnMobile = this.$vuetify.breakpoint.mobile;

      return isOnMobile ? this.$t('deconve.demoMode') : this.$t('deconve.enableDemoMode');
    },
    displayUserName() {
      return this.isDemoMode ? this.$t('deconve.onboarding.workspace.name') : this.userName;
    },
    displayUserNameInitials() {
      return this.isDemoMode ? this.workspaceNameInitial : this.userNameInitials;
    },
    displayUserProfileImage() {
      return this.isDemoMode ? undefined : this.userProfileImage;
    },
    workspaceName() {
      const { name } = this.myWorkspace;

      return this.isDemoMode ? this.$t('deconve.onboarding.workspace.name') : name;
    },
    workspaceImage() {
      return this.isDemoMode ? undefined : this.myWorkspace.profile_image;
    },
    workspaceNameInitial() {
      return this.workspaceName[0];
    },
    workspaceBrandImage() {
      const { brand_image: brandImage } = this.myWorkspace;

      if (brandImage && !this.isDemoMode) return brandImage;

      const defaultBrandImage = '/logo/logo.svg';

      return defaultBrandImage;
    },
    avatarColor() {
      return this.displayUserProfileImage ? 'transparent' : 'primary';
    },
    workspaceAvatarColor() {
      return this.workspaceImage ? 'transparent' : 'primary';
    },
    getRouteName() {
      // If the specific route does not have a name, its parent route name is returned
      const routeParts = this.$route.path.split('/');

      for (let i = routeParts.length; i > 0; i -= 1) {
        const link = routeParts.slice(0, i).join('/');
        const name = this.getRouteNameHelper(link);

        if (name !== '') return name;
      }

      return '';
    },
    filteredWorkspaceList() {
      return this.workspaceList.filter((item, index) => index < this.maxWorkspacesOnPage);
    },
  },
  watch: {
    userRole() {
      this.setUserAbilities();
    },
    userScopes() {
      this.setUserAbilities();
    },
    isDemoModeEnabled(value) {
      if (value !== this.isDemoMode) {
        this.enableDemoMode(value);

        if (!value) {
          this.$router.push('/');
        }

        window.location.reload();
      }
    },
  },
  created() {
    this.isDemoModeEnabled = this.isDemoMode;

    this.populateNavOptions();
    this.setUserAbilities();
    this.getUserWorkspaces();
  },
  updated() {
    this.$nextTick(() => {
      // Code that will run only after the entire view has been re-rendered
      if (this.hasRouteChanged && !this.isMenuExpanded) {
        this.collapseSubItems();
      }

      this.hasRouteChanged = false;
    });
  },
  methods: {
    ...mapActions({
      logout: 'userLogout',
      enableNotification: 'enableNotification',
      enableDemoMode: 'enableDemoMode',
      getUserWorkspaces: 'workspace/getUserWorkspaces',
    }),
    collapseSubItems() {
      this.navOptions.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.active = false;
      });
    },
    onNavigationDrawerTransitionEnd() {
      // v-navigation-drawer emits to `transitionend` events. One when openning the navigation
      // and another one when closing. We wait for the second events to close all subroutes to
      // avoid an error when user clicks in the units route and the app opens the faceid reports,
      // for example
      const wasExpanded = this.isMenuExpanded;

      this.isMenuExpanded = !this.isMenuExpanded;

      if (wasExpanded) {
        this.collapseSubItems();
      }
    },
    setUserAbilities() {
      updateAbility();
    },
    hasPermission(permissions) {
      return permissions.every((permission) => {
        const [action, subject] = permission.split(':', 2);

        return Vue.prototype.$ability.can(action, subject);
      });
    },
    hasSomePermission(permissions) {
      return permissions.some((permission) => {
        const [action, subject] = permission.split(':', 2);

        return Vue.prototype.$ability.can(action, subject);
      });
    },
    getRouteNameHelper(link) {
      let navOptionSelected;

      this.navOptions.find((navOption) => {
        if (navOption?.link === link) {
          navOptionSelected = navOption;
          return true;
        }

        if (navOption?.children) {
          const childIndex = navOption.children.findIndex(
            (child) => child.link === link,
          );

          if (childIndex !== -1) {
            navOptionSelected = navOption.children[childIndex];
            return true;
          }
        }

        return false;
      });

      return navOptionSelected?.name || '';
    },
    populateNavOptions() {
      const navOptions = [];

      if (this.hasPermission(['use:com.deconve.id']) && this.hasSomePermission([
        'read:com.deconve.faceid.person',
        'read:com.deconve.faceid.notification',
        'read:com.deconve.faceid.report',
        'read:com.deconve.faceid.occurrence',
      ])) {
        navOptions.push({
          name: this.$t('deconve.faceId'),
          icon: faceIdIcon,
          group: 'faceid',
          dataCy: 'drawer-faceid',
          active: false,
          children: [
            {
              name: this.$t('deconve.people'),
              icon: peopleIcon,
              dataCy: 'drawer-faceid-people',
              link: '/faceid/people',
            },
            {
              name: this.$t('deconve.occurrences'),
              icon: occurrenceeIcon,
              dataCy: 'drawer-faceid-occurrences',
              link: '/faceid/occurrences',
            },
            {
              name: this.$t('deconve.notifications'),
              icon: faceidNotificationsIcon,
              dataCy: 'drawer-faceid-notifications',
              link: '/faceid/notifications',
            },
            {
              name: this.$t('deconve.reports'),
              icon: reportIcon,
              link: '/faceid/reports',
              dataCy: 'drawer-faceid-reports',
            },
          ],
        });
      }

      if (this.hasPermission(['use:com.deconve.hub'])) {
        const navFaceIdChildren = navOptions[0].children;
        const superProfilePath = {
          name: this.$t('deconve.superProfiles'),
          icon: superProfileIcon,
          link: '/faceid/superprofiles',
        };

        navFaceIdChildren.splice(1, 0, superProfilePath);
      }

      const analyticsApps = [];

      if (this.hasPermission(
        ['use:com.deconve.analytics.dooh', 'read:com.deconve.vehiclecounter.report'],
      )) {
        analyticsApps.push(
          {
            name: this.$t('deconve.analytics.doohAudicenceTitle'),
            icon: doohAudicenceIcon,
            link: '/analytics/dooh',
            dataCy: 'drawer-deconve-analytics-audience',
          },
        );
      }

      if (this.hasPermission(
        ['use:com.deconve.analytics.vehicleflow', 'read:com.deconve.vehiclecounter.report'],
      )) {
        analyticsApps.push(
          {
            name: this.$t('deconve.analytics.vehicleFlowTitle'),
            icon: vehicleFlowIcon,
            link: '/analytics/vehicles',
            dataCy: 'drawer-deconve-analytics-vehicles',
          },
        );
      }

      if (this.hasPermission(
        ['use:com.deconve.analytics.peopleflow', 'read:com.deconve.peoplecounter.report'],
      )) {
        analyticsApps.push(
          {
            name: this.$t('deconve.analytics.peopleFlowTitle'),
            icon: peopleIcon,
            link: '/analytics/people',
            dataCy: 'drawer-deconve-analytics-people',
          },
        );
      }

      if (this.hasPermission(
        ['use:com.deconve.analytics.retail', 'read:com.deconve.retailanalytics.report'],
      )) {
        analyticsApps.push(
          {
            name: this.$t('deconve.analytics.retailTitle'),
            icon: retailAnalyticsIcon,
            link: '/analytics/retail',
            dataCy: 'drawer-deconve-analytics-retail',
          },
        );
      }

      if (analyticsApps.length > 0) {
        navOptions.push({
          name: this.$t('deconve.analytics.title'),
          icon: analyticsIcon,
          group: 'analytics',
          dataCy: 'drawer-deconve-analytics',
          active: false,
          children: analyticsApps,
        });
      }

      if (this.hasPermission(
        ['use:com.deconve.objectcounter', 'read:com.deconve.objectcounter.report'],
      )) {
        navOptions.push({
          name: this.$t('deconve.objectCounter.title'),
          icon: objectCounterIcon,
          group: 'objectcounter',
          dataCy: 'drawer-objectCounter',
          active: false,
          children: [
            {
              name: this.$t('deconve.reports'),
              icon: reportIcon,
              link: '/objectcounter/reports',
              dataCy: 'drawer-objectcounter-reports',
            },
          ],
        });
      }

      if (this.hasPermission(['read:com.deconve.unit'])) {
        navOptions.push({
          name: this.$t('deconve.units'),
          icon: unitsIcon,
          dataCy: 'drawer-units',
          link: '/units',
          active: false,
        });
      }

      navOptions.push({
        name: this.$t('deconve.settings'),
        icon: settingsIcon,
        group: 'settings',
        dataCy: 'drawer-settings',
        active: false,
        children: [
          {
            name: this.$t('deconve.workspace'),
            icon: workspaceIcon,
            link: '/settings/workspace',
            dataCy: 'drawer-settings-workspace',
          },
          {
            name: this.$t('deconve.users'),
            icon: usersIcon,
            link: '/settings/users',
            dataCy: 'drawer-settings-users',
          },
          {
            name: this.$t('deconve.setting.apiKeys'),
            icon: apiKeysIcon,
            link: '/settings/apikeys',
            dataCy: 'drawer-settings-apiKeys',
          },
          {
            name: this.$t('deconve.setting.general'),
            icon: generalSettingsIcon,
            link: '/settings/general',
            dataCy: 'drawer-settings-general',
          },
          {
            name: this.$t('deconve.tools'),
            icon: mdiTools,
            link: '/tools',
            dataCy: 'drawer-settings-tools',
          },
        ],
      });
      this.navOptions = navOptions;
    },
    workspaceLogin(item) {
      const { host, id: workspaceId } = item.workspace;

      // TODO: remove after update dashboard app to use only aws cognito
      localStorage.setItem('host', host);

      logInAnotherWorkspace(workspaceId);
    },
    openManagerAccount() {
      const url = `${process.env.VUE_APP_DECONVE_ACCOUNT_APP_URL}`;

      window.location.href = url;
    },
    openWorkspacesDialog() {
      this.$refs.workspacesDialog.open().then((workspaceSelected) => {
        if (workspaceSelected) {
          this.workspaceLogin({ workspace: workspaceSelected });
        }
      });
    },
    showWhatsNew() {
      this.$refs.whatsNewNotifier.open();
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.hasRouteChanged = true;
  },
});
</script>
