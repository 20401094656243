/* eslint-disable @typescript-eslint/camelcase */
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import i18n from '@/plugins/i18n';
import moment from 'moment';

import {
  PersonPreviewPage, Person, PersonPreview,
} from './types';

export function getPeople(): Promise <PersonPreviewPage> {
  return new Promise((resolve) => {
    const person00: PersonPreview = {
      id: '00000000-0000-0000-0000-000000000000',
      name: i18n.t('deconve.onboarding.faceid.person00') as string,
    };

    const people = [person00];

    const data = { items: people, total: people.length };

    resolve(data);
  });
}

export function getPerson00(): Person {
  const createdAt = moment.utc().add(-5, 'days').toISOString();

  const person: Person = {
    id: '00000000-0000-0000-0000-000000000000',
    workspace: { id: '00000000-0000-0000-0000-000000000000' },
    unit: { id: '00000000-0000-0000-0000-000000000000' },
    name: i18n.t('deconve.onboarding.faceid.person00') as string,
    created_at: createdAt,
    updated_at: createdAt,
    profile_image: 'profile_image.jpg',
    profile_image_url: '/img/demo/faceid_person_00/image_00.jpg',
    images: [
      {
        original: 'entrada_principal.jpg',
        original_url: '/img/demo/faceid_person_00/image_00.jpg',
        thumbnail: 'entrada_principal_thumbnail.jpg',
        thumbnail_url: '/img/demo/faceid_person_00/image_00.jpg',
        medium_quality: 'entrada_principal_medium.jpg',
        medium_quality_url: '/img/demo/faceid_person_00/image_00.jpg',
        original_width: 1920,
        original_height: 1080,
        faces: [
          {
            bounding_box: {
              top_left_x: 1029,
              top_left_y: 232,
              width: 94,
              height: 110,
            },
            detection_score: 0.9972921013832092,
            image_quality_score: 0.8176610469818115,
            angles: {
              yaw: 8.264114570552453,
              pitch: 14.958168852459607,
              roll: 6.490493464061614,
            },
            landmarks: [
              1058.7137451171875,
              279.49005126953125,
              1092.6807861328125,
              275.9355163574219,
              1077.6385498046875,
              298.83282470703125,
              1066.81982421875,
              314.372802734375,
              1093.0809326171875,
              311.48797607421875,
            ],
          },
        ],
      },
    ],
    files: [],
    videos: [],
    tags: [
      {
        id: '00000000-0000-0000-0000-000000000001',
      },
    ],
    history: [
      {
        created_at: createdAt,
        created_by: '00000000-0000-0000-0000-000000000000',
        action: 'com.deconve.faceid.person:created',
      },
    ],
    requestedAtMs: Date.now(),
    areTheImageUrlsReady: true,
    reviewSettings: [],
    enabled: true,
    is_all_faceid_models_trained: true,
  };

  return person;
}

export function getPerson(personId: string): Promise <Person> {
  return new Promise((resolve) => {
    if (personId === '00000000-0000-0000-0000-000000000000') {
      const person = getPerson00();

      resolve(person);
    } else if (personId !== '') {
      const person: Person = {
        id: personId,
        name: personId,
        images: [],
        files: [],
        videos: [],
        tags: [],
        history: [],
        requestedAtMs: 0,
        areTheImageUrlsReady: false,
        reviewSettings: [],
        enabled: true,
        is_all_faceid_models_trained: true,
      };

      resolve(person);
    }
  });
}
