// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { Route, NavigationGuardNext } from 'vue-router';
import auth from '../services/auth';
import { setUserCredentials } from '../services/authHelper';

export default {
  render: () => null,
  beforeRouteEnter(to: Route, _from: Route, next: NavigationGuardNext<Vue>) {
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    const { query } = to;
    const nextUri = query.next ? query.next : '/';
    const {
      code,
      workspace_host: workspaceHost,
      c: refreshToken, k: deviceKey, u: userId,
      w: workspaceId,
    } = query;

    if (refreshToken) {
      setUserCredentials(
        refreshToken as string, deviceKey as string, userId as string, workspaceId as string,
      );
    }

    // Remove after update to use only aws cognito
    if (code) auth.setRefreshToken(code as string);
    if (workspaceHost) auth.setWorkspaceHost(workspaceHost as string);

    next(nextUri as string);
  },
};
