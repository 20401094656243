// Copyright (C) 2020 Deconve Technology. All rights reserved.

import Vue from 'vue';
import VueI18n from 'vue-i18n';
// import messages from '@/locales/en';
import messages from '../locales/pt-br';

Vue.use(VueI18n);

// TODO(spidteam): use lazy loading: https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
// function loadLocaleMessages() {
//   const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.js$/i);
//   const messages = {};
//   locales.keys().forEach((key) => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i);
//     if (matched && matched.length > 1) {
//       const locale = matched[1];
//       messages[locale] = locales(key);
//       console.log('Locales: ', key, messages[locale]);
//     }
//   });
//   return messages;
// }

export default new VueI18n({
  messages,
  locale: 'pt-br',
  fallbackLocale: 'pt-br',
});
