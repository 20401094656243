// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';
import { PersonFinderPeople } from '../people/types';

export const types: { [id: string]: string } = {
  // TODO: include faceid in the key/value to avoid conflict
  GET_PERSON_IMAGE_REQUEST: 'GET_PERSON_IMAGE_REQUEST',
  GET_PERSON_IMAGE_SUCCESS: 'GET_PERSON_IMAGE_SUCCESS',
  GET_PERSON_IMAGE_FAILURE: 'GET_PERSON_IMAGE_FAILURE',

  ADD_PERSON_PROFILE_IMAGE: 'ADD_PERSON_PROFILE_IMAGE',

  LOAD_FACEID_PERSON_IMAGE_SUCCESS: 'LOAD_FACEID_PERSON_IMAGE_SUCCESS',
  ADD_PERSON_IMAGE_INFO_REQUEST: 'ADD_PERSON_IMAGE_INFO_REQUEST',
  ADD_PERSON_IMAGE_INFO_SUCCESS: 'ADD_PERSON_IMAGE_INFO_SUCCESS',
  ADD_PERSON_IMAGE_INFO_FAILURE: 'ADD_PERSON_IMAGE_INFO_FAILURE',
  ADD_PERSON_IMAGE_INFO_SIMILAR_PEOPLE: 'ADD_PERSON_IMAGE_INFO_SIMILAR_PEOPLE',

  ADD_PERSONID: 'ADD_PERSONID',

  EDIT_PERSON_IMAGE_REQUEST: 'EDIT_PERSON_IMAGE_REQUEST',
  EDIT_PERSON_IMAGE_SUCCESS: 'EDIT_PERSON_IMAGE_SUCCESS',
  EDIT_PERSON_IMAGE_FAILURE: 'EDIT_PERSON_IMAGE_FAILURE',

  DETECT_FACEID_FACES_REQUEST: 'DETECT_FACEID_FACES_REQUEST',
  DETECT_FACEID_FACES_SUCCESS: 'DETECT_FACEID_FACES_SUCCESS',
  DETECT_FACEID_FACES_FAILURE: 'DETECT_FACEID_FACES_FAILURE',

  DELETE_PERSON_IMAGE_SUCCESS: 'DELETE_PERSON_IMAGE_SUCCESS',

  RESET_FACEID_PERSON_IMAGES: 'RESET_FACEID_PERSON_IMAGES',

  SORT_IMAGES_BY_QUALITY: 'SORT_IMAGES_BY_QUALITY',
};

interface BoundingBox {
  width: number;
  height: number;
  top_left_x: number;
  top_left_y: number;
}

export interface Info {
  bounding_box: BoundingBox;
  image_quality_score: number;
}

export interface ImageInfo {
  faces: Info[];
  selected?: number;
  similarPeople?: PersonFinderPeople[];
}

export interface PersonImage {
  id?: string;
  originalImageUrl: string;
  mediumImageUrl: string;
  thumbnailImageUrl: string;
  originalName: string;
  mediumName: string;
  thumbnailName: string;
  originalImage: string;
  mediumImage: string;
  thumbnailImage: string;
  imageIsBeingLoaded: boolean;
  facesAreBeingLoaded?: boolean;
  info: ImageInfo | undefined;
  originalHeight?: number;
  originalWidth?: number;
  error?: AxiosError;
}

export interface PersonImagesState {
  personId: string;
  images: PersonImage[];
  personProfileImage: string;
}
