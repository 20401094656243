// Copyright (C) 2022 Deconve Technology. All rights reserved.
/* eslint-disable @typescript-eslint/camelcase */

import moment from 'moment';

import i18n from '@/plugins/i18n';

import { Unit, UnitsPreviewPage } from './types';

export default function getDemoUnits(): Promise<UnitsPreviewPage> {
  return new Promise((resolve) => {
    const unitId00 = '00000000-0000-0000-0000-000000000000';
    const unitId01 = '00000000-0000-0000-0000-000000000001';
    const unitId02 = '00000000-0000-0000-0000-000000000002';

    const units: Unit[] = [
      {
        created_at: moment.utc().subtract(3, 'days').toISOString(),
        id: unitId00,
        name: i18n.t('deconve.onboarding.units.unit00') as string,
        people_flow_settings: undefined,
        tags: [{
          id: '00000000-0000-0000-0000-000000000001',
        }, {
          id: '00000000-0000-0000-0000-000000000002',
        }],
        updated_at: moment.utc().subtract(1, 'days').toISOString(),
        videos: [{
          id: '00000000-0000-0000-0000-000000000000',
        },
        {
          id: '00000000-0000-0000-0000-000000000001',
        }],
        enable_image_preview: true,
        gps_latitude: -5.818591,
        gps_longitude: -35.225042,
      },
      {
        created_at: moment.utc().subtract(10, 'days').toISOString(),
        id: unitId01,
        name: i18n.t('deconve.onboarding.units.unit01') as string,
        people_flow_settings: undefined,
        tags: [{
          id: '00000000-0000-0000-0000-000000000000',
        }, {
          id: '00000000-0000-0000-0000-000000000001',
        }, {
          id: '00000000-0000-0000-0000-000000000003',
        }],
        updated_at: moment.utc().subtract(10, 'days').toISOString(),
        videos: [{
          id: '00000000-0000-0000-0000-000000000002',
        }],
        enable_image_preview: true,
        gps_latitude: -7.583562,
        gps_longitude: -40.497574,
      },
      {
        created_at: moment.utc().subtract(3, 'months').toISOString(),
        id: unitId02,
        name: i18n.t('deconve.onboarding.units.unit02') as string,
        people_flow_settings: undefined,
        tags: [],
        updated_at: moment.utc().subtract(1, 'days').toISOString(),
        videos: [
          {
            id: '00000000-0000-0000-0000-000000000003',
          },
        ],
        enable_image_preview: true,
        gps_latitude: -23.517954,
        gps_longitude: -46.579625,
      },
    ];

    const output = { total: units.length, items: units };

    resolve(output);
  });
}
