// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { SocketState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<SocketState, RootState> = {
  isSocketConnected(state): boolean {
    return state.socket !== undefined && state.socket.connected;
  },
  getSocketEvent(state): object {
    return state.event || {};
  },
};

export default getters;
