// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import { getDataWithPagination } from '@/utils/getDataWithPagination';

import {
  types, Player, PlayersState, PlayerLog,
} from './types';
import { RootState } from '../types';
import { getUnitDemoPlayers } from './demo';

interface UnitPlayersQuery {
  unitId: string;
  refDate: string;
  createdAfter: string;
  createdBefore: string;
}

interface PlayerLogQuery {
  playerId: string;
  refDate: string;
  createdAfter: string;
  createdBefore: string;
}

export const actions: ActionTree<PlayersState, RootState> = {
  fetchPlayers({ rootGetters }, options: UnitPlayersQuery): Promise<Player[]> {
    const {
      unitId, refDate, createdAfter, createdBefore,
    } = options;

    return new Promise((resolve, reject) => {
      const host = process.env.VUE_APP_DECONVE_API_URL;
      const url = `${host}/units/${unitId}/logs/`;

      const params = {
        skip: 0,
        limit: 1000,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: 'created_at',
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_order: 'ascending',
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_after: createdAfter,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_before: createdBefore,
      };

      if (rootGetters.isDemoMode) {
        getUnitDemoPlayers(unitId, refDate).then((players) => {
          resolve(players);
        });
      } else {
        getDataWithPagination(url, params, rootGetters).then((logs: PlayerLog[]) => {
          const logByPlayer: Record<string, PlayerLog> = {};

          logs.forEach((log) => {
            const { id: playerId } = log.player;

            if (playerId in logByPlayer) {
              const { received_at: receivedAt } = logByPlayer[playerId];

              if (log.received_at > receivedAt) {
                logByPlayer[playerId] = log;
              }
            } else {
              logByPlayer[playerId] = log;
            }
          });

          const players: Player[] = [];
          const sortedPlayerIds = Object.keys(logByPlayer).sort((a, b) => (a > b ? 1 : -1));

          sortedPlayerIds.forEach((playerId) => {
            const playerLog = logByPlayer[playerId];
            const player: Player = {
              id: playerId,
              // eslint-disable-next-line @typescript-eslint/camelcase
              created_at: playerLog.created_at,
              unit: { id: unitId },
              enabled: true,
              // eslint-disable-next-line @typescript-eslint/camelcase
              last_notification_at: undefined,
              // eslint-disable-next-line @typescript-eslint/camelcase
              last_log: playerLog,
            };

            players.push(player);
          });

          resolve(players);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  },
  fetchCurrentPlayers({ commit, rootGetters }, unitId: string): Promise<Player[]> {
    return new Promise((resolve, reject) => {
      const host = process.env.VUE_APP_DECONVE_API_URL;
      const url = `${host}/units/${unitId}/players/`;

      const params = {
        skip: 0,
        limit: 100,
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_by: 'created_at',
        // eslint-disable-next-line @typescript-eslint/camelcase
        sort_order: 'ascending',
      };

      if (rootGetters.isDemoMode) {
        getUnitDemoPlayers(unitId).then((players) => {
          commit(types.GET_PLAYERS_SUCCESS, { unitId, players });
          resolve(players);
        });
      } else {
        getDataWithPagination(url, params, rootGetters).then((players: Player[]) => {
          const enabledPlayers = players.filter(({ enabled }) => enabled);

          enabledPlayers.sort(({ id: idA }, { id: idB }) => (idA > idB ? 1 : -1));

          commit(types.GET_PLAYERS_SUCCESS, { unitId, players: enabledPlayers });
          resolve(enabledPlayers);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  },
};

export default actions;
