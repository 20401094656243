// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { ReportsState, types } from './types';

export const mutations: MutationTree<ReportsState> = {
  [types.GET_OBJECT_COUNTER_REPORTS_BY_DAY_REQUEST](state) {
    state.reportsByDay.isLoading = true;
  },
  [types.GET_OBJECT_COUNTER_REPORTS_BY_DAY_SUCCESS](state, data) {
    state.reportsByDay.isLoading = false;
    state.reportsByDay.data = data;
  },
  [types.GET_OBJECT_COUNTER_REPORTS_BY_DAY_FAILURE](state) {
    state.reportsByDay.isLoading = false;
  },
  [types.GET_OBJECT_COUNTER_REPORTS_BY_HOUR_REQUEST](state) {
    state.reportsByHour.isLoading = true;
  },
  [types.GET_OBJECT_COUNTER_REPORTS_BY_HOUR_SUCCESS](state, data) {
    state.reportsByHour.isLoading = false;
    state.reportsByHour.data = data;
  },
  [types.GET_OBJECT_COUNTER_REPORTS_BY_HOUR_FAILURE](state) {
    state.reportsByHour.isLoading = false;
  },
};

export default mutations;
