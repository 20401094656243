// Copyright (C) 2022 Deconve Technology. All rights reserved.
/* eslint-disable @typescript-eslint/camelcase */

import moment from 'moment';

import i18n from '@/plugins/i18n';

import { Video } from './types';

export function getDemoVideos(): Promise<Video[]> {
  return new Promise((resolve) => {
    const videos: Video[] = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        name: i18n.t('deconve.onboarding.videos.video00') as string,
        url: 'rtsp://ip01/video.mp4?param=param01',
        // floripa
        gps_latitude: -27.60252323293182,
        gps_longitude: -48.458811603490545,
        created_at: moment.utc().subtract(30, 'days').toISOString(),
        updated_at: moment.utc().subtract(30, 'days').toISOString(),
        tags: [
          {
            id: '00000000-0000-0000-0000-000000000000',
          },
          {
            id: '00000000-0000-0000-0000-000000000001',
          },
          {
            id: '00000000-0000-0000-0000-000000000002',
          },
        ],
      },
      {
        id: '00000000-0000-0000-0000-000000000001',
        name: i18n.t('deconve.onboarding.videos.video01') as string,
        url: 'rtsp://ip01/video.mp4?param=param01',
        // palhoça
        gps_latitude: -27.647569307350334,
        gps_longitude: -48.67224617626354,
        created_at: moment.utc().subtract(30, 'days').toISOString(),
        updated_at: moment.utc().subtract(30, 'days').toISOString(),
        tags: [
          {
            id: '00000000-0000-0000-0000-000000000001',
          },
          {
            id: '00000000-0000-0000-0000-000000000003',
          },
        ],
      },

      {
        id: '00000000-0000-0000-0000-000000000002',
        name: i18n.t('deconve.onboarding.videos.video02') as string,
        url: 'rtsp://ip01/video.mp4?param=param01',
        // são paulo
        gps_latitude: -23.55563959679571,
        gps_longitude: -46.63945672069779,
        created_at: moment.utc().subtract(10, 'days').toISOString(),
        updated_at: moment.utc().subtract(10, 'days').toISOString(),
        tags: [
          {
            id: '00000000-0000-0000-0000-000000000000',
          },
        ],
      },

      {
        id: '00000000-0000-0000-0000-000000000003',
        name: i18n.t('deconve.onboarding.videos.video03') as string,
        url: 'rtsp://ip01/video.mp4?param=param01',
        // belém
        gps_latitude: -1.4594562148563357,
        gps_longitude: -48.489709778788956,
        created_at: moment.utc().subtract(9, 'days').toISOString(),
        updated_at: moment.utc().subtract(3, 'days').toISOString(),
        tags: [
          {
            id: '00000000-0000-0000-0000-000000000000',
          },
        ],
      },
    ];

    resolve(videos);
  });
}

export function getDemoVideo(videoId: string): Promise<Video> {
  return new Promise((resolve) => {
    getDemoVideos().then((videos) => {
      const videoIndex = videos.findIndex(({ id }) => id === videoId);
      const video = videoIndex >= 0 ? videos[videoIndex] : { id: videoId, name: videoId };

      resolve(video);
    });
  });
}
