// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { AuthState } from './types';
import { RootState } from '../types';

const isDemoMode = localStorage.getItem('demo') !== null;

export const state: AuthState = {
  isDemoMode,
  isLogged: false,
  isLoading: false,
  isLogging: false,
  authorizationToken: undefined,
  tokenUpdateTimerId: undefined,
  user: undefined,
  introspect: undefined,
  error: undefined,
};

const namespaced = false;

export const auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
