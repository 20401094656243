// Copyright (C) 2020 Deconve Technology. All rights reserved.

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import '@fontsource/inter'; // Defaults to weight 400
import '@fontsource/inter/100.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/900.css';

import '@mdi/font/css/materialdesignicons.css';

import i18n from './i18n';
import tipsAndUpdatesIcon from '../components/TipsAndUpdatesIcon.vue';

Vue.use(Vuetify);

const theme = {
  primary: '#075bf5',
  primaryHighlight: '#afcafb',
  secondary: '#0b1b4f',
  textBlack: '#2C2C2C',
  accent: '#9C27b0',
  info: '#8F93FF',
  warn: '#ED7777',
  border: '#DFE3E8',
  colorChart: '#00FF47',
  borderChart: '#8F93FF',
  neutralPrimary: '#454C58',
  neutral: '#727F8B',
  background: '#FAFBFC',
  inputBackground: '#FAFAFA',
  boxBackground: '#FAFAFA',
  transparentBackground: '#EFEFEF',
  reportPrimary: '#53A357',
  femaleColor: '#FF6D90',
  maleColor: '#53A357',
  positive: '#54D162',
  negative: '#FF6D90',
  unreviewed: '#DDDDDD',
  notificationDelayChartColor: '#80DEEA',
  falseNegativeStatusStole: '#ED7777',
  falseNegativeStatusTriedToSteal: '#F0AA6E',
  falseNegativeStatusDidNotTryToSetal: '#727F8B',
  heatMap: '#FF6863',
  weekDay0: '#9EF0D0',
  weekDay1: '#F09EC0',
  weekDay2: '#F0AA6E',
  weekDay3: '#A37853',
  weekDay4: '#A3647E',
  weekDay5: '#6498A3',
  weekDay6: '#53A357',
  goodResolutionColor: '#1B07F5',
  lowResolutionColor: '#FFAB21',
  notAcceptedResolutionColor: '#FF0000',
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params) as string,
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons: {
    values: {
      tipsAndUpdatesIcon: {
        component: tipsAndUpdatesIcon,
        props: {
          name: 'tips-and-updates-icon',
        },
      },
    },
  },
});
