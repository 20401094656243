// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Module } from 'vuex';

import { RootState } from '../../types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { ReportsState } from './types';

export const state: ReportsState = {
  campaignCostPerMileImpressions: -1,
  reportsByDay: {
    byPanel: [],
    isLoading: false,
    error: undefined,
    audience: { data: [], label: [] },
    byVehicles: {
      bus: { total: 0, percentage: '' },
      car: { total: 0, percentage: '' },
      motorbike: { total: 0, percentage: '' },
      truck: { total: 0, percentage: '' },
    },
  },
  numberOfPeopleImpacted: 0,
  numberOfVehiclesImpacted: 0,
  totalNumberOfVehicles: 0,
  numberOfPeopleImpactedByCampaign: 0,
  numberOfVehiclesImpactedByCampaign: 0,
  totalNumberOfVehiclesByCampaign: 0,
};

const namespaced = false;

export const reports: Module<ReportsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
