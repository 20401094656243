// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { RootState } from '../types';

import { reports } from './reports';
import { videos } from './videos';

const namespaced = true;

export const vehiclecounter: Module<RootState, RootState> = {
  namespaced,
  modules: {
    videos,
    reports,
  },
};

export default vehiclecounter;
