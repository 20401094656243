// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { Video, VideosState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<VideosState, RootState> = {
  getVideos: (state): Video[] => state.videos,
};

export default getters;
