/* eslint-disable @typescript-eslint/camelcase */
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import moment from 'moment';
import i18n from '@/plugins/i18n';

import {
  Occurrence,
  OccurrencePreviewPage,
  OccurrencePreview,
} from './types';

export function getDemoOccurrences(): Promise<OccurrencePreviewPage> {
  return new Promise((resolve) => {
    const occurrencePreview00: OccurrencePreview = {
      id: '00000000-0000-0000-0000-000000000000',
      notification: {
        id: '00000000-0000-0000-0000-000000000000',
      },
    };

    const occurrencePreview01: OccurrencePreview = {
      id: '00000000-0000-0000-0000-000000000001',
      notification: {
        id: '00000000-0000-0000-0000-000000000001',
      },
    };

    const occurrencePreview02: OccurrencePreview = {
      id: '00000000-0000-0000-0000-000000000002',
      notification: {
        id: '00000000-0000-0000-0000-000000000002',
      },
    };

    const notifications: OccurrencePreview[] = [
      occurrencePreview00, occurrencePreview01, occurrencePreview02,
    ];

    const data = { items: notifications, total: notifications.length };

    resolve(data);
  });
}

function getOccurrence00(preview: OccurrencePreview): Occurrence {
  const createdAt = moment.utc().add(-25, 'seconds');

  const occurrence: Occurrence = {
    ...preview,
    created_at: createdAt.toISOString(),
    name: i18n.t('deconve.onboarding.occurrences.occurrence00.name') as string,
    time_line: i18n.t('deconve.onboarding.occurrences.occurrence00.timeLine') as string,
    theft_status: i18n.t('deconve.onboarding.occurrences.occurrence00.theftStatus') as string,
    files: [],
    notifications: [{
      id: '00000000-0000-0000-0000-000000000000',
    }],
    value_of_loss: 0,
    value_of_prevention: 0,
    updated_at: moment(createdAt).add(15, 'seconds').toISOString(),
    expire_mode: undefined,
    expire_at: undefined,
    there_was_an_arrest: false,
    cops_were_called: false,
    history: [
      {
        created_at: moment(createdAt).add(15, 'seconds').toISOString(),
        created_by: '00000000-0000-0000-0000-000000000000',
        action: 'com.deconve.faceid.notification:reviewed',
      },
    ],
    tags: [],
    people: [{
      id: '00000000-0000-0000-0000-000000000000',
    }],
    unit: {
      id: '00000000-0000-0000-0000-000000000001',
    },
    about: i18n.t('deconve.onboarding.occurrences.occurrence00.about') as string,
    requestedAtMs: Date.now(),
    areTheNotificationsIdsReady: true,
    is_finished: true,
  };

  return occurrence;
}

function getOccurrence01(preview: OccurrencePreview): Occurrence {
  const createdAt = moment.utc().add(-25, 'seconds');

  const occurrence: Occurrence = {
    ...preview,
    created_at: createdAt.toISOString(),
    name: i18n.t('deconve.onboarding.occurrences.occurrence01.name') as string,
    time_line: i18n.t('deconve.onboarding.occurrences.occurrence01.timeLine') as string,
    theft_status: i18n.t('deconve.onboarding.occurrences.occurrence01.theftStatus') as string,
    files: [],
    notifications: [{
      id: '00000000-0000-0000-0000-000000000000',
    },
    {
      id: '00000000-0000-0000-0000-000000000001',
    }],
    value_of_loss: 1000,
    value_of_prevention: 0,
    updated_at: moment(createdAt).add(15, 'seconds').toISOString(),
    expire_mode: undefined,
    expire_at: undefined,
    there_was_an_arrest: false,
    cops_were_called: true,
    history: [
      {
        created_at: moment(createdAt).add(15, 'seconds').toISOString(),
        created_by: '00000000-0000-0000-0000-000000000000',
        action: 'com.deconve.faceid.notification:reviewed',
      },
    ],
    tags: [],
    people: [{
      id: '00000000-0000-0000-0000-000000000000',
    }, {
      id: '00000000-0000-0000-0000-000000000001',
    }],
    unit: {
      id: '00000000-0000-0000-0000-000000000001',
    },
    about: i18n.t('deconve.onboarding.occurrences.occurrence01.about') as string,
    requestedAtMs: Date.now(),
    areTheNotificationsIdsReady: true,
    is_finished: false,
  };

  return occurrence;
}

function getOccurrence02(preview: OccurrencePreview): Occurrence {
  const createdAt = moment.utc().add(-25, 'seconds');

  const occurrence: Occurrence = {
    ...preview,
    created_at: createdAt.toISOString(),
    name: i18n.t('deconve.onboarding.occurrences.occurrence02.name') as string,
    time_line: i18n.t('deconve.onboarding.occurrences.occurrence02.timeLine') as string,
    theft_status: i18n.t('deconve.onboarding.occurrences.occurrence02.theftStatus') as string,
    files: [],
    notifications: [
      {
        id: '00000000-0000-0000-0000-000000000002',
      }],
    value_of_loss: 0,
    value_of_prevention: 110,
    updated_at: moment(createdAt).add(15, 'seconds').toISOString(),
    expire_mode: undefined,
    expire_at: undefined,
    there_was_an_arrest: false,
    cops_were_called: false,
    history: [
      {
        created_at: moment(createdAt).add(15, 'seconds').toISOString(),
        created_by: '00000000-0000-0000-0000-000000000000',
        action: 'com.deconve.faceid.notification:reviewed',
      },
    ],
    tags: [],
    people: [{
      id: '00000000-0000-0000-0000-000000000000',
    }],
    unit: {
      id: '00000000-0000-0000-0000-000000000001',
    },
    about: i18n.t('deconve.onboarding.occurrences.occurrence02.about') as string,
    requestedAtMs: Date.now(),
    areTheNotificationsIdsReady: true,
    is_finished: false,
  };

  return occurrence;
}

export function getDemoOccurrence(occurrenceId: string): Promise<Occurrence> {
  return new Promise((resolve) => {
    getDemoOccurrences().then(({ items }) => {
      const oid01 = '00000000-0000-0000-0000-000000000001';
      const oid02 = '00000000-0000-0000-0000-000000000002';
      const preview = items.find(({ id }) => id === occurrenceId);
      const occurrencePreviewId = preview?.id;

      let occurrence;

      switch (occurrencePreviewId) {
        case oid01:
          occurrence = getOccurrence01(preview as OccurrencePreview);
          break;

        case oid02:
          occurrence = getOccurrence02(preview as OccurrencePreview);
          break;

        default:
          occurrence = getOccurrence00(preview as OccurrencePreview);
          break;
      }

      resolve(occurrence);
    });
  });
}
