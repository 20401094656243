




























// Copyright (C) 2022 Deconve Technology. All rights reserved.

import Vue from 'vue';

interface UpdateEvent {
  detail: ServiceWorkerRegistration;
}

export default Vue.extend({
  name: 'AppUpdateNotifier',
  data: () => ({
    refreshing: false as boolean,
    registration: null as unknown as ServiceWorkerRegistration,
    showNewVersionAvailable: false as boolean,
  }),
  created() {
    // Listen for serviceWorkerUpdated event and display refresh snackbar as required.
    document.addEventListener('serviceWorkerUpdated', this.showRefreshAlert, { once: true });

    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;

        this.refreshing = true;
        localStorage.setItem('newAppVersion', JSON.stringify(true));
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshAlert(e: unknown) {
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      const { detail } = e as UpdateEvent;

      this.registration = detail;

      if (navigator.userAgent.includes('DeconveMobileApp')) {
        this.refreshApp();
      } else {
        this.showNewVersionAvailable = true;
      }
    },
    refreshApp() {
      this.showNewVersionAvailable = false;

      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return; }

      this.registration.waiting.postMessage('skipWaiting');
    },
  },
});
