// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<AuthState, RootState> = {
  isUserLogged(state): boolean {
    return state.isLogged;
  },
  userId(state): string {
    const { user } = state;

    if (user) return user.id;
    return '';
  },
  userName(state): string {
    const { user } = state;

    if (user) return user.firstName;
    return '';
  },
  userProfileImage(state): string | undefined {
    const { user } = state;

    if (user) return user.profileImage;
    return '';
  },
  userRole(state): string {
    return state.introspect?.role || '';
  },
  userScopes(state): string[] {
    return state.introspect?.scopes || [];
  },
  userNameInitials(state): string {
    const { user } = state;

    if (user) return `${user.firstName[0]}${user.lastName[0]}`;
    return '';
  },
  workspaceId(state): string {
    return state.introspect?.workspace_id || '';
  },
  workspaceUserId(state): string {
    return state.introspect?.workspace_user_id || '';
  },
  authorizationToken(state): string {
    const { authorizationToken: token } = state;

    if (token) return token;
    return '';
  },
  isTokenUpdateEnabled(state): boolean {
    return state.tokenUpdateTimerId !== undefined && state.tokenUpdateTimerId !== null;
  },
  isDemoMode(state): boolean {
    return state.isDemoMode;
  },
};

export default getters;
