// Copyright (C) 2022 Deconve Technology. All rights reserved.

import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';

export interface RequestParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  limit?: number;
  skip?: number;
}

interface RootGetters {
  authorizationToken: string;
}

export function getDataWithPagination(
  url: string,
  params: RequestParams,
  rootGetters: RootGetters,
  headers: AxiosRequestHeaders = {},
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any[]> {
  const { authorizationToken } = rootGetters;

  const requestOptions: AxiosRequestConfig = {
    url,
    params,
    method: 'GET',
    headers: {
      ...headers,
      Authorization: authorizationToken,
    },
  };

  return new Promise((resolve, reject) => {
    axios(requestOptions).then((response) => {
      const { data } = response;
      const { has_more: hasMore, items } = data;

      if (hasMore) {
        let { skip } = params;

        skip += items.length;

        getDataWithPagination(url, { ...params, skip }, rootGetters, headers)
          .then((pageItems) => {
            const output = items.concat(pageItems);

            resolve(output);
          });
      } else {
        resolve(items);
      }
    }).catch((error) => reject(error));
  });
}
