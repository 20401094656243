// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import { getDataWithPagination } from '@/utils/getDataWithPagination';
import {
  types, Campaign, CampaignState, UnitInfo,
} from './types';
import { RootState } from '../../types';

interface EditCampaignQuery {
  campaignId: string;
  name: string;
  value: number;
  startAt: string;
  endAt: string;
  units: UnitInfo[];
  tagIds: string[];
}

interface CampaignQuery {
  tags?: {id: string}[];
  noTags?: boolean;
  startBefore?: string;
  endAfter?: string;
}

export const actions: ActionTree<CampaignState, RootState> = {
  addCampaign({ rootGetters }, payload): Promise<void> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'POST',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: '/campaigns/',
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        data: payload,
      };

      axios(requestOptions).then((response) => {
        const { data } = response;

        resolve(data);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  fetchCampaigns({ rootGetters }, {
    startBefore,
    endAfter,
    tags,
    noTags,
  }: CampaignQuery): Promise<Campaign[]> {
    const params = {
      skip: 0,
    };

    if (startBefore && endAfter) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      Object.assign(params, { start_before: startBefore });
      // eslint-disable-next-line @typescript-eslint/camelcase
      Object.assign(params, { end_after: endAfter });
    }

    const host = process.env.VUE_APP_DECONVE_API_URL;
    let url = `${host}/campaigns/`;

    if (noTags) {
      url += '?tag_ids';
    } else if (tags) {
      tags.forEach((tag, index) => {
        if (!index) {
          url += `?tag_ids=${tag.id}`;
        } else {
          url += `&tag_ids=${tag.id}`;
        }
      });
    }

    return new Promise((resolve, reject) => {
      getDataWithPagination(url, params, rootGetters).then((campaigns) => {
        resolve(campaigns);
      }).catch((error) => {
        reject(error);
      });
    });
  },
  fetchAllCampaigns({ commit, dispatch }): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_CAMPAIGNS_REQUEST);
      dispatch('fetchCampaigns', {}).then((campaigns) => {
        commit(types.GET_CAMPAIGNS_SUCCESS, campaigns);
      }).catch((error) => {
        commit(types.GET_CAMPAIGNS_FAILURE, error);
        reject(error);
      });
    });
  },
  deleteCampaign({ rootGetters }, id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'delete',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/campaigns/${id}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
  editCampaign({ dispatch, rootGetters },
    params: EditCampaignQuery): Promise<void> {
    return new Promise((resolve, reject) => {
      const {
        campaignId, name, value,
        startAt, endAt, units, tagIds,
      } = params;

      const payload = {
        name,
        value,
        units,
        // eslint-disable-next-line @typescript-eslint/camelcase
        start_at: startAt,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_at: endAt,
        // eslint-disable-next-line @typescript-eslint/camelcase
        tag_ids: tagIds,
      };

      const requestOptions: AxiosRequestConfig = {
        method: 'put',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/campaigns/${campaignId}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        data: payload,
      };

      axios(requestOptions).then(() => {
        dispatch('fetchAllCampaigns');

        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },
};

export default actions;
