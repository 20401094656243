// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { VideosState, types } from './types';

const kMaxBufferSize = 512;

export const mutations: MutationTree<VideosState> = {
  [types.GET_FACEID_VIDEOS_REQUEST](state) {
    state.requestCounter += 1;
  },
  [types.GET_FACEID_VIDEOS_SUCCESS](state, data) {
    const { id: videoId } = data;
    const videoIndex = state.videos.findIndex(({ id }) => id === videoId);

    if (videoIndex >= 0) {
      state.videos.splice(videoIndex, 1, data);
    } else {
      const numberOfVideos = state.videos.push(data);

      if (numberOfVideos > kMaxBufferSize) state.videos.shift();
    }

    state.requestCounter -= 1;
  },
  [types.GET_FACEID_VIDEOS_FAILURE](state, error) {
    state.requestCounter -= 1;
    state.error = error;
  },
};

export default mutations;
