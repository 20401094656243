// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { PersonFilesState } from './types';
import { RootState } from '../../types';

export const state: PersonFilesState = {
  files: [],
  personId: '',
};

const namespaced = false;

export const personFiles: Module<PersonFilesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
