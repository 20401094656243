// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  CREATE_TAG_REQUEST: 'CREATE_TAG_REQUEST',
  CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
  CREATE_TAG_FAILURE: 'CREATE_TAG_FAILURE',

  GET_TAGS_REQUEST: 'GET_TAGS_REQUEST',
  GET_TAGS_SUCCESS: 'GET_TAGS_SUCCESS',
  GET_TAGS_FAILURE: 'GET_TAGS_FAILURE',

  DELETE_TAG_REQUEST: 'DELETE_TAG_REQUEST',
  DELETE_TAG_SUCCESS: 'DELETE_TAG_SUCCESS',
  DELETE_TAG_FAILURE: 'DELETE_TAG_FAILURE',
};

export interface Tag {
  id: string;
  name: string;
  value: string;
  color: string;
  created_at: string;
}

export interface TagState {
  tags: Tag[];
  isLoading: boolean;
  error?: AxiosError;
}
