// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';
import { Video } from '../videos/types';

export const types: { [id: string]: string } = {
  GET_VEHICLE_COUNTER_REPORTS_PERCENTAGE_PEOPLE_REQUEST: 'GET_VEHICLE_COUNTER_REPORTS_PERCENTAGE_PEOPLE_REQUEST',
  GET_VEHICLE_COUNTER_REPORTS_AUDIENCE_PANEL_REQUEST: 'GET_VEHICLE_COUNTER_REPORTS_AUDIENCE_PANEL_REQUEST',
  GET_VEHICLE_COUNTER_REPORTS_PERCENTAGE_PEOPLE_SUCCESS: 'GET_VEHICLE_COUNTER_REPORTS_PERCENTAGE_PEOPLE_SUCCESS',
  GET_VEHICLE_COUNTER_REPORTS_AUDIENCE_PANEL_SUCCESS: 'GET_VEHICLE_COUNTER_REPORTS_AUDIENCE_PANEL_SUCCESS',
  GET_VEHICLE_COUNTER_REPORTS_BY_DAY_REQUEST: 'GET_VEHICLE_COUNTER_REPORTS_BY_DAY_REQUEST',
  GET_VEHICLE_COUNTER_REPORTS_BY_DAY_SUCCESS: 'GET_VEHICLE_COUNTER_REPORTS_BY_DAY_SUCCESS',
  GET_VEHICLE_COUNTER_REPORTS_TOTAL_PEOPLE_REQUEST: 'GET_VEHICLE_COUNTER_REPORTS_TOTAL_PEOPLE_REQUEST',
  GET_VEHICLE_COUNTER_REPORTS_TOTAL_PEOPLE: 'GET_VEHICLE_COUNTER_REPORTS_TOTAL_PEOPLE',
  GET_VEHICLE_COUNTER_REPORTS_BY_DAY_FAILURE: 'GET_VEHICLE_COUNTER_REPORTS_BY_DAY_FAILURE',
};

export interface DirectionData {
  bus: number;
  car: number;
  motorbike: number;
  truck: number;
  total: number;
}

interface Results {
  total: number;
  percentage?: string;
}

export interface Report {
  video: Video;
  created_at: string;
  local_time_zone: number;
  vehicle: string;
  direction_in: DirectionData;
  direction_out: DirectionData;
  direction_undefined: DirectionData;
}

export interface PercentageByVehicleType {
  bus: Results;
  car: Results;
  motorbike: Results;
  truck: Results;
}

interface ChartAudience {
  data?: number[];
  label: string[];
}

export interface Reports {
  data: Report[][];
  error?: AxiosError;
  isLoading: boolean;
  audience: ChartAudience;
  percentageOfVehicles: PercentageByVehicleType;
  totalNumberOfVehicles: number;
}

export interface ReportsState {
  reportsByDay: Reports;
}
