





import Vue from 'vue';
import { mapActions } from 'vuex';

export default Vue.extend({
  name: 'App',
  beforeDestroy() {
    this.stopAuthService();
  },
  methods: mapActions({
    stopAuthService: 'stopTokenUpdate',
  }),
});
