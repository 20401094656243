// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { defineAbility, Ability, AbilityBuilder } from '@casl/ability';
import store from '@/store';
import Vue from 'vue';
import type { Notification } from '@/store/faceid/notifications/types';
import type { Workspace } from '@/store/workspace/types';

export default defineAbility((can, cannot) => {
  // CRUD abilities are default for all subjects/endpoints
  cannot('create', 'all');
  cannot('read', 'all');
  cannot('update', 'all');
  cannot('delete', 'all');

  // User can use an app
  cannot('use', 'all');
});

export function updateAbility(): void {
  const { can, rules } = new AbilityBuilder(Ability);
  const { userScopes, userRole } = store.getters;
  const workspaceInfo = store.getters['workspace/myWorkspace'];
  const { apps } = workspaceInfo;

  apps.forEach((app: string) => can('use', app));

  if (userRole === 'admin') {
    can('create', 'all');
    can('read', 'all');
    can('update', 'all');
    can('delete', 'all');
  } else {
    userScopes.forEach((scope: string) => {
      const [action, subject] = scope.split(':', 2);

      can(action, subject);
    });
  }

  Vue.prototype.$ability.update(rules);
  store.dispatch('updateSocketEventList');
}

// This is a special case to do not show the review button for users without permission to update
// the notification
export function canReviewNotificationOnHub(notification: Notification) {
  if (!Vue.prototype.$ability.can('update', 'com.deconve.faceid.notification')) return false;

  const myWorkspace = store.getters['workspace/myWorkspace'] as Workspace;

  const { id: notificationWorkspaceId } = notification.workspace;

  if (notificationWorkspaceId === myWorkspace.id) return true;

  const sharedWorkspaceInfo = myWorkspace.shared_with_me.find(
    (item) => item.workspace.id === notificationWorkspaceId,
  );

  const scopes = sharedWorkspaceInfo?.scopes;

  if (scopes && scopes.length > 0) {
    if (notification.is_from_a_shared_person) {
      if (scopes.includes('review:com.deconve.faceid.shared_notification')) {
        return true;
      }
    } else if (scopes.includes('review:com.deconve.faceid.notification')) return true;
  }

  return false;
}
