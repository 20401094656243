// Copyright (C) 2020 Deconve Technology. All rights reserved.

import Vue from 'vue';
import { Route, RouteMeta, NavigationGuardNext } from 'vue-router';

import store from '../store';
import { updateAbility } from './ability';

function requestLogin(nextUri: string): void {
  const redirectUri = `${window.location.origin}/callback&next=${nextUri}`;
  const url = `${process.env.VUE_APP_DECONVE_AUTH_URL}/authorize?redirect_uri=${redirectUri}`;

  window.location.href = url;
}

function requestCreateWorkspace(nextUri: string): void {
  const redirectUri = `${window.location.origin}/callback&next=${nextUri}`;
  const host = process.env.VUE_APP_DECONVE_ACCOUNT_APP_URL;
  const url = `${host}/workspace/register?redirect_uri=${redirectUri}`;

  window.location.href = url;
}

function setRefreshToken(token: string): void {
  localStorage.setItem('token', token);
}

function setWorkspaceHost(host: string): void {
  localStorage.setItem('host', host);
}

function middleware(to: Route, _from: Route, next: NavigationGuardNext<Vue>) {
  const { requiresAuth } = to.meta as RouteMeta;

  if (requiresAuth) {
    const { isUserLogged } = store.getters;

    if (isUserLogged) {
      next();
    } else {
      store.dispatch('userLogin')
        .then(() => {
          store.dispatch('workspace/getWorkspaceSettings').then(() => {
            updateAbility();
            next();
          }).catch(() => {
            requestCreateWorkspace(to.fullPath);
          });
        })
        .catch(() => {
          requestLogin(to.fullPath);
          next(false);
        });
    }
  } else {
    next();
  }
}

function permissionsMiddleware(to: Route, _from: Route, next: NavigationGuardNext<Vue>) {
  const { permissions } = to.meta as RouteMeta;

  if (permissions) {
    const hasPermission = permissions.every((permission: string) => {
      const [action, subject] = permission.split(':', 2);

      return Vue.prototype.$ability.can(action, subject);
    });

    if (hasPermission) {
      next();
    } else {
      next({ name: 'forbidden', query: { redirect: to.fullPath } });
    }
  } else {
    next();
  }
}

export default {
  middleware, setRefreshToken, setWorkspaceHost, permissionsMiddleware,
};
