// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import { NotificationState, types } from './types';
import { RootState } from '../types';

export const actions: ActionTree<NotificationState, RootState> = {
  enableNotification({ commit }, enable: boolean): void {
    localStorage.setItem('is_notification_enabled', enable ? '1' : '0');
    commit(types.ENABLE_NOTIFICATION, enable);
  },
};

export default actions;
