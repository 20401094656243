// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { Tag, TagState, types } from './types';
import { getDemoTags } from './demo';
import { RootState } from '../types';

interface TagPayload {
  tag: Tag;
  workspaceId?: string;
}

export const actions: ActionTree<TagState, RootState> = {
  createTag({ commit, rootGetters, dispatch }, { tag, workspaceId }: TagPayload): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.CREATE_TAG_REQUEST);

      const { name, value, color } = tag;

      const headers: AxiosRequestHeaders = {
        Authorization: rootGetters.authorizationToken,
      };

      if (workspaceId) {
        headers.workspaceId = workspaceId;
      }

      const requestOptions: AxiosRequestConfig = {
        url: '/tags/',
        method: 'POST',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        headers,
        data: {
          name,
          value,
          color,
        },
      };

      axios(requestOptions).then(() => {
        commit(types.CREATE_TAG_SUCCESS);
        dispatch('fetchTags', workspaceId);
        resolve();
      }).catch((error) => {
        commit(types.CREATE_TAG_FAILURE, error);
        reject();
      });
    });
  },
  fetchTags({ commit, rootGetters }, workspaceId = undefined): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_TAGS_REQUEST);

      const headers: AxiosRequestHeaders = {
        Authorization: rootGetters.authorizationToken,
      };

      if (workspaceId) {
        headers.workspaceId = workspaceId;
      }

      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
        url: '/tags/',
        headers,
      };

      if (rootGetters.isDemoMode) {
        getDemoTags().then((tags) => {
          commit(types.GET_TAGS_SUCCESS, tags);
          resolve();
        });
      } else {
        axios(requestOptions).then((response) => {
          commit(types.GET_TAGS_SUCCESS, response.data);
          resolve();
        }).catch((error) => {
          commit(types.GET_TAGS_FAILURE, error);

          const { response } = error;

          const unauthorizedErrorCode = 401;

          if (response?.status === unauthorizedErrorCode) {
            resolve();
          } else {
            reject();
          }
        });
      }
    });
  },
  deleteTag({ commit, rootGetters, dispatch }, tagId) {
    commit(types.DELETE_TAG_REQUEST);

    const requestOptions: AxiosRequestConfig = {
      method: 'delete',
      baseURL: process.env.VUE_APP_DECONVE_AUTH_URL,
      url: `/tags/${tagId}/`,
      headers: {
        Authorization: rootGetters.authorizationToken,
      },
    };

    axios(requestOptions).then(() => {
      commit(types.DELETE_TAG_SUCCESS);
      dispatch('fetchTags');
    }).catch((error) => {
      commit(types.DELETE_TAG_FAILURE, error);
    });
  },
};

export default actions;
