// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import { dataUrlToBlob } from '../../../utils/data';
import { RootState } from '../../types';
import {
  PersonFile, types, PersonFilesState,
} from './types';

let getFileRequests = [] as CancelTokenSource[];
let addFileRequests = [] as CancelTokenSource[];

interface FileInfo {
  name: string;
  file: string;
}

function addPersonFile(authToken: string, personId: string, fileInfo: FileInfo): Promise<void> {
  return new Promise((resolve, reject) => {
    dataUrlToBlob(fileInfo.file).then((fileBlob) => {
      const formData = new FormData();

      formData.append('file', fileBlob as Blob, fileInfo.name);

      const requestOptions: AxiosRequestConfig = {
        method: 'post',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/files/`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: authToken,
        },
        data: formData,
      };

      axios(requestOptions)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  });
}

function updatePersonFile(authToken: string, personId: string, fileInfo: FileInfo): Promise<void> {
  return new Promise((resolve, reject) => {
    dataUrlToBlob(fileInfo.file).then((fileBlob) => {
      const formData = new FormData();

      formData.append('file', fileBlob as Blob, fileInfo.name);

      const requestOptions: AxiosRequestConfig = {
        method: 'patch',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/files/`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: authToken,
        },
        data: formData,
      };

      axios(requestOptions)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  });
}

function deletePersonFile(
  authToken: string, personId: string, personFileName: string,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const newParams = new URLSearchParams();

    newParams.append('file', personFileName);

    const requestOptions: AxiosRequestConfig = {
      method: 'delete',
      baseURL: process.env.VUE_APP_DECONVE_API_URL,
      url: `/faceid/people/${personId}/files/`,
      params: newParams,
      headers: { Authorization: authToken },
    };

    axios(requestOptions)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
}

export const actions: ActionTree<PersonFilesState, RootState> = {
  fetchPersonFiles({ commit, state }, { files, personId }) {
    // Cancel current file requests if the person id changed

    if (personId !== state.personId) {
      commit(types.RESET_FACEID_PERSON_FILES);
      commit(types.SET_FACEID_PERSON_ID_FILES_REQUESTED, personId);

      if (getFileRequests.length > 0) {
        getFileRequests.forEach((request) => {
          request.cancel();
        });
        getFileRequests = [];
      }

      if (addFileRequests.length > 0) {
        addFileRequests.forEach((request) => {
          request.cancel();
        });
        addFileRequests = [];
      }
    } else {
      return;
    }

    // Prepare the person file data with face info information to be requested
    const personFiles: PersonFile[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    files.forEach((file: any) => {
      const personFile: PersonFile = {
        originalFileUrl: file.url,
        originalName: file.name,
        originalFile: '',
        fileIsBeingLoaded: true,
      };

      personFiles.push(personFile);

      commit(types.GET_FACEID_PERSON_FILE_REQUEST, personFile);
    });

    personFiles.forEach((personFile) => {
      const cancelFileUrlRequest = axios.CancelToken.source();

      getFileRequests.push(cancelFileUrlRequest);

      const personFileData = { ...personFile };

      personFileData.fileIsBeingLoaded = false;

      commit(types.GET_FACEID_PERSON_FILE_SUCCESS, personFileData);
    });
  },

  addPersonFiles({ rootGetters, dispatch }, { personId, files }): Promise<void> {
    return new Promise((resolve, reject) => {
      const addPersonFilePromises: Promise<void>[] = [];

      files.forEach((file: FileInfo) => {
        addPersonFilePromises.push(
          addPersonFile(rootGetters.authorizationToken, personId, file),
        );
      });

      Promise.all(addPersonFilePromises).then(() => {
        dispatch('resetPersonFiles');
        resolve();
      }).catch((error) => reject(error));
    });
  },

  editPersonFiles(
    { rootGetters, dispatch },
    { personId, files, filesToBeDeleted },
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const deleteFilesPromises: Promise<void>[] = [];

      filesToBeDeleted.forEach((name: string) => {
        // The update person file operation overwrites file with same name on database. So, to
        // avoid too many requests, we delete only files that will be not overwritten.
        const index = files.findIndex((file: FileInfo) => name === file.name);

        if (index < 0) {
          deleteFilesPromises.push(
            deletePersonFile(rootGetters.authorizationToken, personId, name),
          );
        }
      });

      Promise.all(deleteFilesPromises).then(() => {
        const updateFilePromises: Promise<void>[] = [];

        files.forEach((file: FileInfo) => {
          updateFilePromises.push(updatePersonFile(rootGetters.authorizationToken, personId, file));
        });

        Promise.all(updateFilePromises).then(() => {
          dispatch('resetPersonFiles');
          resolve();
        }).catch((error) => reject(error));
      }).catch((error) => resolve(error));
    });
  },

  resetPersonFiles({ commit }) {
    commit(types.RESET_FACEID_PERSON_FILES);
  },
};

export default actions;
