// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { OccurrenceState } from './types';
import { RootState } from '../../types';

export const state: OccurrenceState = {
  occurrencePreviewPage: { total: 0, items: [] },
  occurrences: [],
  occurrenceIteratorSettings: undefined,
  occurrencesDownloadState: { isLoading: false, error: undefined },
};

const namespaced = false;

export const occurrences: Module<OccurrenceState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
