// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { UnitsState } from './types';
import { RootState } from '../types';

export const state: UnitsState = {
  isLoading: false,
  units: [],
  unitsPage: {
    total: 0,
    items: [],
  },
  unitsIteratorSettings: undefined,
};

const namespaced = true;

export const units: Module<UnitsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
