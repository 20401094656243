// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import { Video, VideosState, types } from './types';
import { RootState } from '../../types';

import getDemoVideo from './demo';

export const actions: ActionTree<VideosState, RootState> = {
  fetchVideo({ commit, getters, rootGetters }, id): Promise<Video | undefined> {
    return new Promise((resolve) => {
      const cachedVideo = getters.getVideo(id);

      if (cachedVideo) {
        resolve(cachedVideo);
        return;
      }

      commit(types.GET_FACEID_VIDEOS_REQUEST);

      const host = process.env.VUE_APP_DECONVE_API_URL;

      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: host,
        url: `/faceid/videos/${id}/`,
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      if (rootGetters.isDemoMode) {
        getDemoVideo(id).then((video) => {
          commit(types.GET_FACEID_VIDEOS_SUCCESS, video);
          resolve(video);
        });
      } else {
        axios(requestOptions).then((response) => {
          const { data } = response;

          commit(types.GET_FACEID_VIDEOS_SUCCESS, data);
          resolve(data);
        }).catch((error) => {
          commit(types.GET_FACEID_VIDEOS_FAILURE, error);
          resolve(undefined);
        });
      }
    });
  },
};

export default actions;
