// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_FACEID_SIMILAR_SUPER_PROFILE_SUCCESS: 'GET_FACEID_SIMILAR_SUPER_PROFILE_SUCCESS',

  GET_FACEID_SUPER_PROFILES_PREVIEW_SUCCESS: 'GET_FACEID_SUPER_PROFILES_PREVIEW_SUCCESS',

  GET_FACEID_SUPER_PROFILE_REQUEST: 'GET_FACEID_SUPER_PROFILE_REQUEST',
  GET_FACEID_SUPER_PROFILE_SUCCESS: 'GET_FACEID_SUPER_PROFILE_SUCCESS',

  GET_FACEID_SUPER_PROFILE_IMAGES_REQUEST: 'GET_FACEID_SUPER_PROFILE_IMAGES_REQUEST',
  GET_FACEID_SUPER_PROFILE_IMAGES_SUCCESS: 'GET_FACEID_SUPER_PROFILE_IMAGES_SUCCESS',
  GET_FACEID_SUPER_PROFILE_IMAGES_FAILURE: 'GET_FACEID_SUPER_PROFILE_IMAGES_FAILURE',

  EDIT_FACEID_SUPER_PROFILE_REQUEST: 'EDIT_FACEID_SUPER_PROFILE_REQUEST',
  EDIT_FACEID_SUPER_PROFILE_SUCCESS: 'EDIT_FACEID_SUPER_PROFILE_SUCCESS',
  EDIT_FACEID_SUPER_PROFILE_FAILURE: 'EDIT_FACEID_SUPER_PROFILE_FAILURE',

};

export interface Face {
  detection_score: number;
  image_quality_score: number;
  bounding_box: {
    top_left_x: number;
    top_left_y: number;
    width: number;
    height: number;
  };
  angles: {
    yaw: number;
    pitch: number;
    roll: number;
  };
  landmarks: number[];
}

export interface SuperProfileImage {
  id: string;
  original: string;
  original_url: string;
  thumbnail: string;
  thumbnail_url: string;
  medium_quality: string;
  medium_quality_url: string;
  original_width: number;
  original_height: number;
  faces: Face[];
}

export interface Tag {
  id: string;
}

export interface History {
  action: string;
  created_at: string;
  created_by: string;
}

export interface SuperProfilePerson {
  id: string;
}

export interface SuperProfilePreview {
  id: string;
  name?: string;
}

export interface SuperProfilesPreviewPage {
  items: SuperProfilePreview[];
  total: number;
}

export interface SuperProfileSharing {
  status: string;
  created_at: string;
  updated_at: string;
  about: string;
  tags: Tag[];
}

export interface SuperProfileSharingReview {
  current_review: SuperProfileSharing;
  last_review: SuperProfileSharing;
}

export interface SuperProfile {
    id?: string;
    name?: string;
    created_at?: string;
    updated_at?: string;
    expire_at?: string;
    expire_mode?: string;
    deleted_by?: string;
    about?: string;
    status: string;
    tags: Tag[];
    people: SuperProfilePerson[];
    similar_super_profiles: SuperProfilePerson[];
    profile_image?: string;
    profile_image_url?: string;
    images: SuperProfileImage[];
    history?: History[];
    sharing_status: SuperProfileSharingReview;
}

export interface SuperProfileImageForReviewer {
  id: string;
  original_url: string;
  thumbnail_url: string;
  medium_quality_url: string;
  original_width: number;
  original_height: number;
  faces: Face[];
}

export interface SuperProfileImages {
  id?: string;
  profile_image_url?: string;
  images: SuperProfileImageForReviewer[];
}

export interface SuperProfileFiltersSettings {
  page: number;
  itemsPerPage: number;
  search?: string;
  sortBy: string;
  sortOrder: string;
  inTrash?: boolean;
  tags?: string[];
  createdAfter?: string;
  createdBefore?: string;
  createdDateOption?: string;
  unitId?: string;
  unitName?: string;
  hasSimilar: boolean;
  reviewStatus: string;
}

export interface SuperProfileState {
  isLoading: boolean;
  data?: SuperProfile;
  error?: AxiosError;
}

export interface SuperProfileReviewerState {
  isLoading: boolean;
  data?: SuperProfileImages;
  error?: AxiosError;
}

export interface SuperProfilesState {
  superProfiles: SuperProfile[];
  superProfile: SuperProfileState;
  superProfileReviewer: SuperProfileReviewerState;
  superProfileFiltersSettings?: SuperProfileFiltersSettings;
  superProfilesPreviewList: SuperProfilesPreviewPage;
  similarSuperProfiles: SuperProfile[];
}
