// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_CAMPAIGNS_REQUEST: 'GET_CAMPAIGNS_REQUEST',
  GET_CAMPAIGNS_SUCCESS: 'GET_CAMPAIGNS_SUCCESS',
  GET_CAMPAIGNS_FAILURE: 'GET_CAMPAIGNS_FAILURE',
  POST_CAMPAIGNS_REQUEST: 'POST_CAMPAIGNS_REQUEST',
  POST_CAMPAIGNS_SUCCESS: 'POST_CAMPAIGNS_SUCCESS',
  POST_CAMPAIGNS_FAILURE: 'POST_CAMPAIGNS_FAILURE',
};

export interface UnitInfo {
  id: string;
  exposure_rate: number;
}

export interface Campaign {
  id: string;
  name: string;
  start_at: string;
  end_at: string;
  units: UnitInfo[];
  value: number;
  created_at: string;
  updated_at: string;
  tags: string[];
}

export interface CampaignState {
  isLoadingCampaigns: boolean;
  campaigns: Campaign[];
  error?: AxiosError;
}
