// Copyright (C) 2021 Deconve Technology. All rights reserved.

import Vue from 'vue';
import { ActionTree } from 'vuex';
import { ManagerOptions, SocketOptions, io } from 'socket.io-client';

import { SocketState, types } from './types';
import { RootState } from '../types';

let expectedEvents: string[] = [];

export const actions: ActionTree<SocketState, RootState> = {
  [types.SOCKET_INIT_CONNECTION]({ commit, dispatch, rootGetters }): void {
    const options: Partial<ManagerOptions & SocketOptions> = {
      path: '/v1/chat/',
      // Auth uses a callback to be able to update the token when reconnecting
      auth: (cb) => {
        cb({
          token: rootGetters.authorizationToken,
        });
      },
    };

    const socket = io(process.env.VUE_APP_DECONVE_CHAT_URL, options);

    socket.on('connect', () => dispatch('updateSocketEventList'));

    socket.on('event', (message: string) => dispatch(types.SOCKET_ON_EVENT_MESSAGES, message));

    socket.on('disconnect', (reason: string) => {
      if (reason === 'io server disconnect') {
        // The disconnection was initiated by the server, we need to reconnect manually
        socket.connect();
      }
      // Else the socket will automatically try to reconnect
    });

    commit(types.SOCKET_INIT_CONNECTION, socket);
  },
  [types.SOCKET_UPDATE_AUTH_TOKEN]({ dispatch, state }, token: string): void {
    // Update the authorization token if the socket is valid
    if (state.socket) {
      state.socket.emit('authenticate', JSON.stringify({ token }));
    } else {
      dispatch(types.SOCKET_INIT_CONNECTION, token);
    }
  },
  [types.SOCKET_CLOSE_CONNECTION]({ commit }): void {
    commit(types.SOCKET_CLOSE_CONNECTION);
  },
  updateSocketEventList({ state }): void {
    let userAllowedEvents: string[] = [];

    // TODO: this must be done in the server side
    if (Vue.prototype.$ability.can('read', 'com.deconve.faceid.notification')) {
      userAllowedEvents = userAllowedEvents.concat([
        types.COM_DECONVE_FACEID_NOTIFICATION_CREATED,
        types.COM_DECONVE_FACEID_NOTIFICATION_DELETED,
        types.COM_DECONVE_FACEID_NOTIFICATION_RESTORED,
        types.COM_DECONVE_FACEID_NOTIFICATION_REVIEWED,
        types.COM_DECONVE_FACEID_NOTIFICATION_MONITORED,
        types.COM_DECONVE_FACEID_NOTIFICATION_UPDATED,
        types.COM_DECONVE_FACEID_NOTIFICATION_TRASHED,
        types.COM_DECONVE_FACEID_NOTIFICATION_CONFIRMED,
        types.COM_DECONVE_FACEID_NOTIFICATION_UNCONFIRMED,
      ]);
    }

    expectedEvents = userAllowedEvents;
    const { socket } = state;

    if (socket) {
      socket.emit('subscribe', JSON.stringify({ events: expectedEvents }));
    }
  },
  [types.SOCKET_ON_EVENT_MESSAGES]({ dispatch, commit }, message: string): void {
    const event = JSON.parse(message);
    const { event_type: eventType } = event;

    if (expectedEvents.includes(eventType)) {
      commit(types.SOCKET_ON_EVENT_MESSAGES, event);

      switch (eventType) {
        case types.COM_DECONVE_FACEID_NOTIFICATION_CREATED:
          dispatch('faceid/onSocketEventNotificationCreated');
          break;
        case types.COM_DECONVE_FACEID_NOTIFICATION_DELETED:
          dispatch('faceid/onSocketEventNotificationDeleted', event?.notification_id);
          break;
        case types.COM_DECONVE_FACEID_NOTIFICATION_MONITORED:
          dispatch('faceid/onSocketEventNotificationMonitored', event?.notification_id);
          break;
        case types.COM_DECONVE_FACEID_NOTIFICATION_REVIEWED:
          dispatch('faceid/onSocketEventNotificationReviewed', event?.notification_id);
          break;
        case types.COM_DECONVE_FACEID_NOTIFICATION_UPDATED:
          dispatch('faceid/onSocketEventNotificationUpdated', event?.notification_id);
          break;
        case types.COM_DECONVE_FACEID_NOTIFICATION_RESTORED:
          dispatch('faceid/onSocketEventNotificationRestored', event?.notification_id);
          break;
        case types.COM_DECONVE_FACEID_NOTIFICATION_TRASHED:
          dispatch('faceid/onSocketEventNotificationTrashed', event?.notification_id);
          break;
        default:
          break;
      }
    }
  },
};

export default actions;
