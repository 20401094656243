// Copyright (C) 2022 Deconve Technology. All rights reserved.

export const types: { [id: string]: string } = {
  POST_UNITS_SUCCESS: 'POST_UNITS_SUCCESS',
  GET_UNITS_SUCCESS: 'GET_UNITS_SUCCESS',
  GET_UNITS_PAGE_SUCCESS: 'GET_UNITS_PAGE_SUCCESS',
  DELETE_UNITS_SUCCESS: 'DELETE_UNITS_SUCCESS',
};

export interface TagPreview {
  id: string;
}

export interface VideoPreview {
  id: string;
}

export interface PeopleFlowSettings {
  bus: number;
  car: number;
  motorbike: number;
  truck: number;
}

export interface Unit {
  created_at: string;
  id: string;
  name: string;
  people_flow_settings?: PeopleFlowSettings;
  tags: TagPreview[];
  updated_at: string;
  videos: VideoPreview[];
  enable_image_preview: boolean;
  gps_latitude: number;
  gps_longitude: number;
}

export interface UnitsPreviewPage {
  items: Unit[];
  total: number;
}

export interface UnitsIteratorSettings {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortOrder: string;
  search?: string;
  status?: string;
  tags?: string[];
  noTags?: boolean;
  workspaceId?: string;
  workspaceName?: string;
  createdAfter?: string;
  createdBefore?: string;
}

export interface UnitsState {
  isLoading: boolean;
  // This used by the units view page
  unitsPage: UnitsPreviewPage;
  units: Unit[];
  unitsIteratorSettings?: UnitsIteratorSettings;
}
