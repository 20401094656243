// Copyright (C) 2021 Deconve Technology. All rights reserved.

export const LOW_QUALITY_IMAGE = 'low';
export const REGULAR_QUALITY_IMAGE = 'regular';
export const GOOD_QUALITY_IMAGE = 'good';

export default function imageQualityScore(score: number): string {
  if (score < 40) {
    return LOW_QUALITY_IMAGE;
  }

  if (score < 63) {
    return REGULAR_QUALITY_IMAGE;
  }

  return GOOD_QUALITY_IMAGE;
}
