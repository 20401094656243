// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { PersonImagesState } from './types';
import { RootState } from '../../types';

export const state: PersonImagesState = {
  images: [],
  personProfileImage: '',
  personId: '',
};

const namespaced = false;

export const personImages: Module<PersonImagesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
