// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { AxiosError } from 'axios';
import { Tag, TagState } from './types';
import { ApiRequestError, RootState } from '../types';

export const getters: GetterTree<TagState, RootState> = {
  getTags: (state): Tag[] => state.tags,
  getTag: (state) => (tagId: string): Tag | undefined => state.tags.find((tag) => tag.id === tagId),
  getTagsLoading: (state): boolean => state.isLoading,
  error(state): AxiosError | ApiRequestError | undefined {
    const { error } = state as {error: AxiosError | undefined};

    if (error?.response) {
      // Request made and server responded
      const { data: errorData } = error.response as { data: ApiRequestError };

      if (errorData) {
        return errorData;
      }
    }

    return error;
  },
};

export default getters;
