// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_PERSON_VIDEO_REQUEST: 'GET_PERSON_VIDEO_REQUEST',
  GET_PERSON_VIDEO_SUCCESS: 'GET_PERSON_VIDEO_SUCCESS',
  GET_PERSON_VIDEO_FAILURE: 'GET_PERSON_VIDEO_FAILURE',

  LOAD_PERSON_VIDEO_SUCCESS: 'LOAD_PERSON_VIDEO_SUCCESS',
  ADD_PERSON_VIDEO_INFO_REQUEST: 'ADD_PERSON_VIDEO_INFO_REQUEST',
  ADD_PERSON_VIDEO_INFO_SUCCESS: 'ADD_PERSON_VIDEO_INFO_SUCCESS',
  ADD_PERSON_VIDEO_INFO_FAILURE: 'ADD_PERSON_VIDEO_INFO_FAILURE',

  RESET_FACEID_PERSON_VIDEOS: 'RESET_FACEID_PERSON_VIDEOS',
  SET_PERSON_ID_VIDEO_REQUESTED: 'SET_PERSON_ID_VIDEO_REQUESTED',

  EDIT_PERSON_VIDEO_REQUEST: 'EDIT_PERSON_VIDEO_REQUEST',
  EDIT_PERSON_VIDEO_SUCCESS: 'EDIT_PERSON_VIDEO_SUCCESS',
  EDIT_PERSON_VIDEO_FAILURE: 'EDIT_PERSON_VIDEO_FAILURE',

  DELETE_PERSON_VIDEO_SUCCESS: 'DELETE_PERSON_VIDEO_SUCCESS',
};

export interface PersonVideo {
  originalVideoUrl: string;
  originalName: string;
  originalVideo: string;
  videoIsBeingLoaded: boolean;
  error?: AxiosError;
}

export interface PersonVideosState {
  personId: string;
  videos: PersonVideo[];
}
