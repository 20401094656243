// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { ReportsState, types } from './types';

export const mutations: MutationTree<ReportsState> = {
  [types.GET_VEHICLE_COUNTER_REPORTS_AUDIENCE_PANEL_REQUEST](state) {
    state.reportsByDay.isLoading = true;
  },
  [types.GET_VEHICLE_COUNTER_REPORTS_AUDIENCE_PANEL_SUCCESS](state, data) {
    state.reportsByDay.audience = data;
    state.reportsByDay.isLoading = false;
  },
  [types.GET_VEHICLE_COUNTER_REPORTS_PERCENTAGE_PEOPLE_REQUEST](state) {
    state.reportsByDay.isLoading = true;
  },
  [types.GET_VEHICLE_COUNTER_REPORTS_PERCENTAGE_PEOPLE_SUCCESS](state, data) {
    state.reportsByDay.percentageOfVehicles = data;
    state.reportsByDay.isLoading = false;
  },
  [types.GET_VEHICLE_COUNTER_REPORTS_TOTAL_PEOPLE_REQUEST](state) {
    state.reportsByDay.isLoading = true;
  },
  [types.GET_VEHICLE_COUNTER_REPORTS_TOTAL_PEOPLE](state, data) {
    state.reportsByDay.totalNumberOfVehicles = data;
    state.reportsByDay.isLoading = false;
  },
  [types.GET_VEHICLE_COUNTER_REPORTS_BY_DAY_REQUEST](state) {
    state.reportsByDay.isLoading = true;
  },
  [types.GET_VEHICLE_COUNTER_REPORTS_BY_DAY_SUCCESS](state, data) {
    state.reportsByDay.data = data;
    state.reportsByDay.isLoading = false;
  },
  [types.GET_VEHICLE_COUNTER_REPORTS_BY_DAY_FAILURE](state) {
    state.reportsByDay.isLoading = false;
  },
};

export default mutations;
