// Copyright (C) 2022 Deconve Technology. All rights reserved.

export const types: { [id: string]: string } = {
  GET_RETAIL_ANALYTICS_VIDEOS_SUCCESS: 'GET_RETAIL_ANALYTICS_VIDEOS_SUCCESS',
  GET_RETAIL_ANALYTICS_VIDEO_GROUPS_SUCCESS: 'GET_RETAIL_ANALYTICS_VIDEO_GROUPS_SUCCESS',
};

export interface Video {
  id: string;
  name: string;
}

export interface Unit {
  id: string;
  name: string;
  videos?: Video[];
}

export interface VideosState {
  videos: Video[];
  videoGroups: Unit[];
}
