// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { PersonVideo, PersonVideosState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<PersonVideosState, RootState> = {
  personVideos(state): PersonVideo[] {
    return state.videos;
  },
  videoIsBeingLoaded: (state) => (index: number) => (
    index < state.videos.length
      ? state.videos[index].videoIsBeingLoaded : false
  ),
  getVideoUrl: (state) => (videoName: string): string | undefined => {
    const video = state.videos.find(({ originalName }) => originalName === videoName);

    return video?.originalVideoUrl;
  },
};

export default getters;
