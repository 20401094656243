// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { PlayersState } from './types';
import { RootState } from '../types';

export const PLAYER_STATUS = {
  all: 'all',
  offline: 'offline',
  stopped: 'stopped',
  running: 'running',
};

export const state: PlayersState = {
  unitPlayers: {},
  playerLogs: {},
};

const namespaced = true;

export const players: Module<PlayersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
