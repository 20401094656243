// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { VideosState, types } from './types';
import { RootState } from '../../types';

let request: CancelTokenSource | null = null;

export const actions: ActionTree<VideosState, RootState> = {
  fetchVideos({ commit, rootGetters }): Promise<void> {
    return new Promise((resolve) => {
      commit(types.GET_VEHICLE_COUNTER_VIDEOS_REQUEST);

      if (request) {
        request.cancel();
      }

      request = axios.CancelToken.source();

      const host = process.env.VUE_APP_DECONVE_API_URL;

      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: host,
        url: '/vehiclescounter/videos/',
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
        cancelToken: request.token,
      };

      axios(requestOptions).then((response) => {
        const { data } = response;

        commit(types.GET_VEHICLE_COUNTER_VIDEOS_SUCCESS, data);
        resolve();
      }).catch((error) => {
        commit(types.GET_VEHICLE_COUNTER_VIDEOS_FAILURE, error);
        resolve();
      });
    });
  },
};

export default actions;
