// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { RootState } from '../../types';

import { PersonFile, PersonFilesState } from './types';

export const getters: GetterTree<PersonFilesState, RootState> = {
  personFiles(state): PersonFile[] {
    return state.files;
  },
  fileIsBeingLoaded: (state) => (index: number) => (
    index < state.files.length
      ? state.files[index].fileIsBeingLoaded : false
  ),
  getFileUrl: (state) => (fileName: string): string | undefined => {
    const file = state.files.find(({ originalName }) => originalName === fileName);

    return file?.originalFileUrl;
  },
};

export default getters;
