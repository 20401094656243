// Copyright (C) 2022 Deconve Technology. All rights reserved.

import moment from 'moment';
import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import {
  ReportsState, Report, TotalByVideo, Ages, Genders,
} from './types';

interface DatasetItem {
  [date: string]: number;
}

interface DataByAge {
  data: number[];
  labels: string[];
}

export const getters: GetterTree<ReportsState, RootState> = {
  totalNumberOfPeople: (state): number => state.totalNumberOfPeople,
  totalNumberOfPeopleByVideo: (state): TotalByVideo => state.totalByVideo,
  reportByUnitByDayToChartData(state) {
    // first date and last date are used to create the chart data labels
    let firstValidDate: moment.Moment = moment(null);
    let lastValidDate: moment.Moment = moment(null);

    const dateFormat = 'DD/MM/YYYY';
    const dataByDate: DatasetItem = {};

    const { reportByUnitByDay } = state;

    reportByUnitByDay.forEach((dataByVideo: Report[]) => {
      dataByVideo.forEach((item: Report) => {
        const { created_at: createdAt, local_time_zone: timeZone } = item;

        const {
          direction_undefined: directionUndefined,
        } = item;

        const date = moment.utc(createdAt).add(timeZone, 'hours');

        if (!firstValidDate.isValid() || date.isBefore(firstValidDate)) {
          firstValidDate = moment(date);
        }

        if (!lastValidDate.isValid() || date.isAfter(lastValidDate)) {
          lastValidDate = moment(date);
        }

        const dateInString = date.format(dateFormat);

        let totalDistinct = 0;

        if (directionUndefined?.distinct) {
          totalDistinct += directionUndefined.distinct;
        }

        if (dateInString in dataByDate) {
          dataByDate[dateInString] += totalDistinct;
        } else {
          dataByDate[dateInString] = totalDistinct;
        }
      });
    });

    const labels: moment.Moment[] = [];
    const data: number[] = [];

    if (firstValidDate.isValid()) {
      while (firstValidDate.isSameOrBefore(lastValidDate)) {
        const dateLabel = firstValidDate.format(dateFormat);

        labels.push(moment(firstValidDate));

        data.push(dateLabel in dataByDate ? dataByDate[dateLabel] : 0);
        firstValidDate.add(1, 'day');
      }
    }

    return { labels, data };
  },
  reportByGender(state): Genders {
    const { reportByUnitByDay } = state;

    const outputData: Genders = {
      female: 0,
      male: 0,
    };

    reportByUnitByDay.forEach((dataByVideo: Report[]) => {
      dataByVideo.forEach((item: Report) => {
        const {
          direction_undefined: directionUndefined,
        } = item;

        if (directionUndefined?.genders) {
          outputData.female += directionUndefined.genders?.female;
          outputData.male += directionUndefined.genders?.male;
        }
      });
    });

    return outputData;
  },
  reportByAge(state): DataByAge {
    const { reportByUnitByDay } = state;
    const sumOfAges: Ages = {};

    reportByUnitByDay.forEach((dataByVideo: Report[]) => {
      dataByVideo.forEach((item: Report) => {
        const {
          direction_undefined: directionUndefined,
        } = item;

        if (directionUndefined?.ages) {
          const { ages } = directionUndefined;

          Object.keys(ages).forEach((age) => {
            const value = ages[age];

            if (age in sumOfAges) {
              sumOfAges[age] += value;
            } else {
              sumOfAges[age] = value;
            }
          });
        }
      });
    });

    const labels = Object.keys(sumOfAges).sort();
    const data: number[] = [];

    labels.forEach((label) => {
      data.push(sumOfAges[label]);
    });

    return { labels, data };
  },
};

export default getters;
