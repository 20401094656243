// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { AxiosError } from 'axios';
import {
  Invite, User, Workspace, WorkspaceState, WorkspaceUser,
} from './types';
import { ApiRequestError, RootState } from '../types';

export const getters: GetterTree<WorkspaceState, RootState> = {
  myWorkspace(state): Workspace {
    return state.workspace;
  },
  workspaceUsers(state): User[] {
    return state.workspaceUsers;
  },
  workspaceList(state): WorkspaceUser[] {
    return state.workspaceList;
  },
  invites(state): Invite[] {
    return state.invites;
  },
  error(state): AxiosError | ApiRequestError | undefined {
    const { error } = state as {error: AxiosError | undefined};

    if (error?.response) {
      // Request made and server responded
      const { data: errorData } = error.response as { data: ApiRequestError };

      if (errorData) {
        return errorData;
      }
    }

    return error;
  },
  getWorkspacesSharedWithMe: (state) => (scopes: string[]): string[] => {
    const { shared_with_me: sharedWithMe } = state.workspace;

    let selectedWorkspaceIds: string[] = [];

    if (scopes?.length > 0) {
      sharedWithMe.forEach(({ workspace, scopes: workspaceScopes }) => {
        const filteredScopes = workspaceScopes.filter(
          (scope) => scopes.includes(scope),
        );

        if (filteredScopes?.length > 0) {
          selectedWorkspaceIds.push(workspace.id);
        }
      });
    } else {
      selectedWorkspaceIds = sharedWithMe.map(({ workspace }) => workspace.id);
    }

    return selectedWorkspaceIds;
  },
};

export default getters;
