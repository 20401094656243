// Copyright (C) 2022 Deconve Technology. All rights reserved.
/* eslint-disable @typescript-eslint/camelcase */

import moment from 'moment';

import {
  Player, PlayerLog, PlayerStatus, Tool, VideoFrame,
} from './types';

interface PlayerBasicInfo {
  playerId: string;
  unitId: string;
  status: string;
  videoId: string;
}

function getPlayerInfos(): PlayerBasicInfo[] {
  const unitId00 = '00000000-0000-0000-0000-000000000000';
  const unitId01 = '00000000-0000-0000-0000-000000000001';
  const unitId02 = '00000000-0000-0000-0000-000000000002';

  const videoId00 = '00000000-0000-0000-0000-000000000000';
  const videoId01 = '00000000-0000-0000-0000-000000000001';
  const videoId02 = '00000000-0000-0000-0000-000000000002';
  const videoId03 = '00000000-0000-0000-0000-000000000003';

  const infos = [
    {
      playerId: '00000000-0000-0000-0000-000000000000',
      unitId: unitId00,
      status: 'running',
      videoId: videoId00,
    },
    {
      playerId: '00000000-0000-0000-0000-000000000001',
      unitId: unitId00,
      status: 'running',
      videoId: videoId01,
    },
    {
      playerId: '00000000-0000-0000-0000-000000000002',
      unitId: unitId00,
      status: 'stopped',
      videoId: videoId01,
    },
    {
      playerId: '00000000-0000-0000-0000-000000000003',
      unitId: unitId01,
      status: 'running',
      videoId: videoId02,
    },
    {
      playerId: '00000000-0000-0000-0000-000000000004',
      unitId: unitId02,
      status: 'offline',
      videoId: videoId03,
    },
  ];

  return infos;
}

function getPlayerInfo(playerId: string): PlayerBasicInfo| undefined {
  const infos = getPlayerInfos();

  return infos.find((info) => info.playerId === playerId);
}

function getPlayerLog(playerInfo: PlayerBasicInfo, refDate: string): PlayerLog {
  const tools: Tool[] = [
    {
      id: 'peoplecounter',
      description: 'People counter',
    },
    {
      id: 'vehiclescounter',
      description: 'Vehicles counter',
    },
    {
      id: 'licenseplaterecognizer',
      description: 'Vehicles license plate recognition',
    },
    {
      id: 'caremonitor',
      description: 'Care monitor',
    },
    {
      id: 'faceidentification',
      description: 'Facial identification',
    },
    {
      id: 'retailanalytics',
      description: 'Retail Analytics',
    },
  ];

  const playerStatus: Record<string, PlayerStatus> = {
    offline: {
      id: 'offline',
      message: 'player is offline',
    },
    stopped: {
      id: 'stopped',
      message: 'player is stopped',
    },
    running: {
      id: 'running',
      message: 'player is running',
    },
  };

  const videoFrame: VideoFrame = {
    original: 'player_example.jpg',
    original_url: '/img/demo/player_example.jpg',
    thumbnail: 'player_example.jpg',
    thumbnail_url: '/img/demo/player_example.jpg',
    original_width: 100,
    original_height: 100,
  };

  // Adds a delay of 70 minutes or 1 minute
  const receivedAtDelay = playerInfo.status === playerStatus.offline.id ? 4200 : 60;
  const isToAddVideoFrame = playerInfo.status === playerStatus.running.id;

  const playerLog: PlayerLog = {
    id: playerInfo.playerId,
    created_at: moment.utc(refDate).subtract(receivedAtDelay + 10, 'seconds').toISOString(),
    received_at: moment.utc(refDate).subtract(receivedAtDelay, 'seconds').toISOString(),
    video: { id: playerInfo.videoId },
    player: { id: playerInfo.playerId },
    start_run_at: moment.utc(refDate).subtract(24, 'hours').toISOString(),
    end_run_at: undefined,
    status: playerStatus[playerInfo.status],
    video_frame: isToAddVideoFrame ? videoFrame : undefined,
    tool: tools[0],
  };

  return playerLog;
}

export function getDemoPlayer(playerInfo: PlayerBasicInfo, refDate: string): Player {
  const player: Player = {
    id: playerInfo.playerId,
    created_at: moment.utc().subtract(30, 'days').toISOString(),
    video: { id: playerInfo.videoId },
    unit: { id: playerInfo.unitId },
    enabled: true,
    last_log: getPlayerLog(playerInfo, refDate),
    last_notification_at: undefined,
  };

  return player;
}

export function getDemoPlayerLogs(playerId: string, refDate: string): Promise<PlayerLog[]> {
  return new Promise((resolve) => {
    const playerInfo = getPlayerInfo(playerId) as PlayerBasicInfo;
    const playerLog = getPlayerLog(playerInfo, refDate);

    resolve([playerLog]);
  });
}

export function getUnitDemoPlayers(
  unitId: string, refDate: string | undefined = undefined,
): Promise<Player[]> {
  const date = refDate || moment.utc().toISOString();

  return new Promise((resolve) => {
    const players: Player[] = [];

    const infos = getPlayerInfos();

    infos.forEach((info) => {
      if (info.unitId === unitId) {
        players.push(getDemoPlayer(info, date));
      }
    });

    resolve(players);
  });
}
