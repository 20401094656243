// Copyright (C) 2022 Deconve Technology. All rights reserved.

export const types: { [id: string]: string } = {
  GET_PLAYERS_SUCCESS: 'GET_PLAYERS_SUCCESS',
};

export interface PlayerRef {
  id: string;
}

export interface UnitRef {
  id: string;
}

export interface VideoRef {
  id: string;
}

export interface PlayerStatus {
  id: string;
  message: string;
}

export interface Tool {
  id: string;
  description: string;
}

export interface VideoFrame {
  original: string;
  original_url: string;
  thumbnail: string;
  thumbnail_url: string;
  original_width: number;
  original_height: number;
}

export interface PlayerLog {
  id: string;
  created_at: string;
  video?: VideoRef;
  player: PlayerRef;
  received_at: string;
  start_run_at: string;
  end_run_at?: string;
  status: PlayerStatus;
  video_frame?: VideoFrame;
  tool: Tool;
}

export interface Player {
  id: string;
  created_at: string;
  video?: VideoRef;
  unit: UnitRef;
  enabled: boolean;
  last_notification_at?: string;
  last_log: PlayerLog;
}

export interface PlayerLogsPage {
  items: PlayerLog[];
  total: number;
}

export interface PlayersState {
  unitPlayers: Record<string, Player[]>;
  playerLogs: Record<string, PlayerLog[]>;
}
