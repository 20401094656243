// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { RootState } from '../types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { NotificationState } from './types';

function currentNotificationState(): boolean {
  const isActivatedValue = '1';
  const state = localStorage.getItem('is_notification_enabled') || isActivatedValue;

  return state === isActivatedValue;
}

export const state: NotificationState = {
  isEnabled: currentNotificationState(),
};

const namespaced = false;

export const notification: Module<NotificationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
