// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { RootState } from '../types';

import { people } from './people';
import { notifications } from './notifications';
import { reports } from './reports';
import { personImages } from './personImages';
import { personFiles } from './personFiles';
import { personVideos } from './personVideos';
import { occurrences } from './occurrences';
import { superProfiles } from './superProfiles';
import { superProfileImages } from './superProfilesImages';

import { units } from './units';
import { videos } from './videos';

const namespaced = true;

export const faceid: Module<RootState, RootState> = {
  namespaced,
  modules: {
    people,
    notifications,
    personImages,
    personFiles,
    personVideos,
    reports,
    units,
    videos,
    occurrences,
    superProfiles,
    superProfileImages,
  },
};

export default faceid;
