/* eslint-disable @typescript-eslint/camelcase */
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import i18n from '@/plugins/i18n';
import moment from 'moment';

import { Workspace } from './types';

export function getWorkspace2(): Promise <Workspace> {
  return new Promise((resolve) => {
    const id = '00000000-0000-0000-0000-000000000000';
    const createdAt = moment.utc().add(-5, 'days').toISOString();

    const workspace: Workspace = {
      id,
      host: 'workspace.deconve.com',
      name: i18n.t('deconve.onboarding.workspace.name') as string,
      apps: [
        'com.deconve.id',
        // 'com.deconve.analytics.peopleflow',
        // 'com.deconve.analytics.dooh',
        // 'com.deconve.analytics.vehicleflow',
        // 'com.deconve.analytics.retail',
      ],
      organization: { id },
      profile_image: '',
      brand_image: '',
      created_at: createdAt,
      ringtone: 'default',
      number_of_days_to_delete_item_permanently: 30,
      is_person_tag_required: true,
      workspace_to_save_super_profile: { id },
      shared_with: [],
      shared_with_me: [],
    };

    resolve(workspace);
  });
}

export function getDemoWorkspace(): Promise <Workspace> {
  return new Promise((resolve) => {
    const id = '00000000-0000-0000-0000-000000000000';
    const createdAt = moment.utc().add(-5, 'days').toISOString();

    const workspace: Workspace = {
      id,
      host: 'workspace.deconve.com',
      name: i18n.t('deconve.onboarding.workspace.name') as string,
      apps: [
        'com.deconve.id',
        // 'com.deconve.analytics.peopleflow',
        // 'com.deconve.analytics.dooh',
        // 'com.deconve.analytics.vehicleflow',
        // 'com.deconve.analytics.retail',
      ],
      organization: { id },
      profile_image: '',
      brand_image: '',
      created_at: createdAt,
      ringtone: 'default',
      number_of_days_to_delete_item_permanently: 30,
      is_person_tag_required: true,
      workspace_to_save_super_profile: { id },
      shared_with: [],
      shared_with_me: [],
    };

    resolve(workspace);
  });
}
