// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Video, Unit } from '../videos/types';

export const types: { [id: string]: string } = {
  GET_RETAIL_ANALYTICS_REPORT_BY_UNIT_BY_DAY_SUCCESS: 'GET_RETAIL_ANALYTICS_REPORT_BY_UNIT_BY_DAY_SUCCESS',
  GET_RETAIL_ANALYTICS_REPORT_BY_UNIT_BY_HOUR_SUCCESS: 'GET_RETAIL_ANALYTICS_REPORT_BY_UNIT_BY_HOUR_SUCCESS',
  GET_RETAIL_ANALYTICS_REPORT_TOTAL_PEOPLE_SUCCESS: 'GET_RETAIL_ANALYTICS_REPORT_TOTAL_PEOPLE_SUCCESS',
  GET_RETAIL_ANALYTICS_REPORT_TOTAL_BY_VIDEO_SUCCESS: 'GET_RETAIL_ANALYTICS_REPORT_TOTAL_BY_VIDEO_SUCCESS',
  GET_RETAIL_ANALYTICS_REPORT_TOTAL_BY_UNIT_SUCCESS: 'GET_RETAIL_ANALYTICS_REPORT_TOTAL_BY_UNIT_SUCCESS',
};

export interface Genders {
  female: number;
  male: number;
}

export interface Ages {
  [age: string]: number;
}

export interface DirectionData {
  total: number;
  distinct: number;
  genders?: Genders;
  ages?: Ages;
}

export interface Report {
  video?: Video;
  video_group?: Unit;
  created_at: string;
  local_time_zone: number;
  direction_in?: DirectionData;
  direction_out?: DirectionData;
  direction_undefined?: DirectionData;
}

export interface TotalByVideo {
  data?: number[];
  label: string[];
}

export interface TotalByVideoGroup {
  data?: number[];
  label: string[];
}

export interface ReportsState {
  reportByUnitByDay: Report[][];
  reportByUnitByHour: Report[][];
  totalByVideo: TotalByVideo;
  totalByUnit: TotalByVideo;
  totalNumberOfPeople: number;
}
