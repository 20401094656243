// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { CampaignState } from './types';
import { RootState } from '../../types';

export const state: CampaignState = {
  isLoadingCampaigns: false,
  campaigns: [],
  error: undefined,
};

const namespaced = false;

export const campaigns: Module<CampaignState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
