// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios from 'axios';

import { getDataWithPagination } from '@/utils/getDataWithPagination';
import { RootState } from '../../types';
import { Video } from '../videos/types';
import {
  DirectionData, ReportsState, Report, types,
} from './types';

interface FetchOptions {
  startDate: string;
  endDate: string;
  videoIds?: string[];
}

interface ReportQuery {
  startDate: string;
  endDate: string;
  groupBy: string;
  videoId: string;
}

interface RootGetters {
  authorizationToken: string;
}

interface AudienceByVideo {
  data: number[];
  labels: string[];
}

interface DownloadReportyOptions {
  start_date: string;
  end_date: string;
  group_by: string;
  report_type: string;
  video_ids?: string[];
  is_to_convert_to_local_time_zone?: boolean;
  locale?: string;
}

export function getReports({
  startDate, endDate, groupBy, videoId,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ReportQuery, rootGetters: RootGetters): Promise<any[]> {
  const params = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    start_date: startDate,
    // eslint-disable-next-line @typescript-eslint/camelcase
    end_date: endDate,
    // eslint-disable-next-line @typescript-eslint/camelcase
    group_by: groupBy,
    // eslint-disable-next-line @typescript-eslint/camelcase
    video_id: videoId,
    limit: 1000,
    skip: 0,
  };

  const host = process.env.VUE_APP_DECONVE_PEOPLE_COUNTER_API_URL
      || process.env.VUE_APP_DECONVE_API_URL;
  const url = `${host}/peoplecounter/reports/`;

  return getDataWithPagination(url, params, rootGetters);
}

function sortDataAndLabels(data: number[], labels: string[]): AudienceByVideo {
  // Data is sorted in descending order and, when there is multiple data with same value, the
  // labels are sorted in ascending order:
  // unsorted: C: 0, Bb: 0, Ba: 0, D: 1
  // sorted:   D: 1, Ba: 0, Bb: 0, C: 0
  const sortedData: number[] = [];
  const sortedLabels: string[] = [];

  const dataAndLabels: { [value: number]: string[] } = {};

  data.forEach((value, index) => {
    if (value in dataAndLabels) {
      dataAndLabels[value].push(labels[index]);
    } else {
      dataAndLabels[value] = [labels[index]];
    }
  });

  let sortedValueKeys: number[] = [];

  Object.keys(dataAndLabels).forEach((value) => sortedValueKeys.push(Number(value)));

  sortedValueKeys = sortedValueKeys.sort((a, b) => (a > b ? -1 : 1));

  sortedValueKeys.forEach((value) => {
    const valueLabels = dataAndLabels[value].sort((a, b) => (a > b ? 1 : -1));

    valueLabels.forEach((label: string) => {
      sortedData.push(value);
      sortedLabels.push(label);
    });
  });

  return { data: sortedData, labels: sortedLabels };
}

export const actions: ActionTree<ReportsState, RootState> = {
  downloadReportByHour(
    { rootGetters }, { startDate, endDate, videoIds }: FetchOptions,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const params: DownloadReportyOptions = {
      // eslint-disable-next-line @typescript-eslint/camelcase
        start_date: startDate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        end_date: endDate,
        // TODO: this will be updated at SPID-2664
        // eslint-disable-next-line @typescript-eslint/camelcase
        group_by: 'day',
        // hour_offset: dashboardSettings.getValue('hourOffset', 0),
        // eslint-disable-next-line @typescript-eslint/camelcase
        report_type: 'csv',
        // eslint-disable-next-line @typescript-eslint/camelcase
        is_to_convert_to_local_time_zone: true,
        locale: 'pt-br',
      };

      if (videoIds) {
      // eslint-disable-next-line @typescript-eslint/camelcase
        params.video_ids = videoIds;
      }

      const { authorizationToken } = rootGetters;

      const host = process.env.VUE_APP_DECONVE_PEOPLE_COUNTER_API_URL
      || process.env.VUE_APP_DECONVE_API_URL;
      const url = `${host}/peoplecounter/reports/`;
      const headers = { Authorization: authorizationToken };

      axios.get(url, { params, headers, responseType: 'blob' })
        .then((response) => {
          const path = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');

          link.href = path;
          link.setAttribute('download', 'report.csv');
          document.body.appendChild(link);
          link.click();
          resolve();
        }).catch((error) => reject(error));
    });
  },
  fetchReportByHour(
    { commit, rootGetters }, { startDate, endDate, videoIds }: FetchOptions,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      let selectedVideoIds: string[];
      const videos: Video[] = rootGetters['peoplecounter/getVideos'];

      if (videoIds) {
        selectedVideoIds = videoIds;
      } else {
        selectedVideoIds = videos.map(({ id }) => id);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];

      selectedVideoIds.forEach((videoId) => {
        const params = {
          startDate,
          endDate,
          videoId,
          groupBy: 'hour',
        };

        promises.push(getReports(params, rootGetters));
      });

      Promise.all(promises).then((results) => {
        commit(types.GET_PEOPLE_COUNTER_REPORTS_BY_HOUR_SUCCESS, results);
        resolve();
      }).catch((error) => reject(error));
    });
  },
  fetchReportByDay(
    { commit, rootGetters }, { startDate, endDate, videoIds }: FetchOptions,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      let selectedVideoIds: string[];
      const videos: Video[] = rootGetters['peoplecounter/getVideos'];

      if (videoIds) {
        selectedVideoIds = videoIds;
      } else {
        selectedVideoIds = videos.map(({ id }) => id);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];

      selectedVideoIds.forEach((videoId) => {
        const params = {
          startDate,
          endDate,
          videoId,
          groupBy: 'day',
        };

        promises.push(getReports(params, rootGetters));
      });

      // Only this direction considered for now
      const direction = 'direction_in';
      const totalByVideo: { [id: string]: number } = {};

      Promise.all(promises).then((results) => {
        let totalNumberOfPeople = 0;

        results.forEach((dataByVideo: Report[]) => {
          dataByVideo.forEach((report: Report) => {
            const { video } = report;
            const { id: videoId } = video;

            const directionData = report[direction] as DirectionData;
            const total = directionData?.total || 0;

            if (videoId) {
              if (videoId in totalByVideo) {
                totalByVideo[videoId] += total;
              } else {
                totalByVideo[videoId] = total;
              }
            }

            totalNumberOfPeople += total;
          });
        });

        const totalNumberOfPeopleByVideo: number[] = [];
        const videoNames: string[] = [];

        selectedVideoIds.forEach((videoId) => {
          const video = videos.find(({ id }) => id === videoId);
          const videoName = video?.name || videoId;

          videoNames.push(videoName);

          if (videoId in totalByVideo) {
            totalNumberOfPeopleByVideo.push(totalByVideo[videoId]);
          } else {
            totalNumberOfPeopleByVideo.push(0);
          }
        });

        const peopleByVideo = sortDataAndLabels(totalNumberOfPeopleByVideo, videoNames);

        commit(types.GET_PEOPLE_COUNTER_REPORTS_TOTAL_BY_VIDEO_SUCCESS, peopleByVideo);
        commit(types.GET_PEOPLE_COUNTER_REPORTS_TOTAL_PEOPLE_SUCCESS, totalNumberOfPeople);
        commit(types.GET_PEOPLE_COUNTER_REPORTS_BY_DAY_SUCCESS, results);
        resolve();
      }).catch((error) => reject(error));
    });
  },
};

export default actions;
