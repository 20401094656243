// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';
import { Video } from '../videos/types';

export const types: { [id: string]: string } = {
  GET_OBJECT_COUNTER_REPORTS_BY_DAY_REQUEST: 'GET_OBJECT_COUNTER_REPORTS_BY_DAY_REQUEST',
  GET_OBJECT_COUNTER_REPORTS_BY_DAY_SUCCESS: 'GET_OBJECT_COUNTER_REPORTS_BY_DAY_SUCCESS',
  GET_OBJECT_COUNTER_REPORTS_BY_DAY_FAILURE: 'GET_OBJECT_COUNTER_REPORTS_BY_DAY_FAILURE',

  GET_OBJECT_COUNTER_REPORTS_BY_HOUR_REQUEST: 'GET_OBJECT_COUNTER_REPORTS_BY_HOUR_REQUEST',
  GET_OBJECT_COUNTER_REPORTS_BY_HOUR_SUCCESS: 'GET_OBJECT_COUNTER_REPORTS_BY_HOUR_SUCCESS',
  GET_OBJECT_COUNTER_REPORTS_BY_HOUR_FAILURE: 'GET_OBJECT_COUNTER_REPORTS_BY_HOUR_FAILURE',
};

export interface Statistics {
  positive: number;
  negative: number;
  unreviewed: number;
}

export interface DirectionData {
  total: number;
}

export interface Report {
  video: Video;
  created_at: string;
  local_time_zone: number;
  name: string;
  direction_in?: DirectionData;
  direction_out?: DirectionData;
  direction_undefined?: DirectionData;
}

export interface Reports {
  data: Report[][];
  error?: AxiosError;
  isLoading: boolean;
}

export interface ReportsState {
  reportsByDay: Reports;
  reportsByHour: Reports;
}
