// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { NotificationState } from './types';
import { RootState } from '../types';

export const getters: GetterTree<NotificationState, RootState> = {
  isNotificationEnabled(state): boolean {
    return state.isEnabled;
  },
};

export default getters;
