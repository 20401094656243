// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_FACEID_NOTIFICATIONS_STATISTICS_REQUEST: 'GET_FACEID_NOTIFICATIONS_STATISTICS_REQUEST',
  GET_FACEID_NOTIFICATIONS_STATISTICS_SUCCESS: 'GET_FACEID_NOTIFICATIONS_STATISTICS_SUCCESS',
  GET_FACEID_NOTIFICATIONS_STATISTICS_FAILURE: 'GET_FACEID_NOTIFICATIONS_STATISTICS_FAILURE',

  GET_FACEID_NOTIFICATIONS_REPORTS_REQUEST: 'GET_FACEID_NOTIFICATIONS_REPORTS_REQUEST',
  GET_FACEID_NOTIFICATIONS_REPORTS_SUCCESS: 'GET_FACEID_NOTIFICATIONS_REPORTS_SUCCESS',
  GET_FACEID_NOTIFICATIONS_REPORTS_FAILURE: 'GET_FACEID_NOTIFICATIONS_REPORTS_FAILURE',

  GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_REQUEST: 'GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_REQUEST',
  GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_SUCCESS: 'GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_SUCCESS',
  GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_FAILURE: 'GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_FAILURE',

  GET_FACEID_NOTIFICATION_VIDEO_SOURCES_REQUEST: 'GET_FACEID_NOTIFICATION_VIDEO_SOURCES_REQUEST',
  GET_FACEID_NOTIFICATION_VIDEO_SOURCES_SUCCESS: 'GET_FACEID_NOTIFICATION_VIDEO_SOURCES_SUCCESS',
  GET_FACEID_NOTIFICATION_VIDEO_SOURCES_FAILURE: 'GET_FACEID_NOTIFICATION_VIDEO_SOURCES_FAILURE',

  DOWNLOAD_FACEID_REPORTS_REQUEST: 'DOWNLOAD_FACEID_REPORTS_REQUEST',
  DOWNLOAD_FACEID_REPORTS_SUCCESS: 'DOWNLOAD_FACEID_REPORTS_SUCCESS',
  DOWNLOAD_FACEID_REPORTS_FAILURE: 'DOWNLOAD_FACEID_REPORTS_FAILURE',
};

export interface Statistics {
  positive: number;
  negative: number;
  unreviewed: number;
  total: number;
}

export interface Report {
  id?: string;
  workspace_id?: string;
  notification_id?: string;
  created_by?: string;
  created_at?: string;
  local_time_zone?: number;
  deleted_by?: string;
  deleted_at?: string;
  deleted_mode?: string;
  first_review_at?: string;
  first_review_by?: string;
  last_review_at?: string;
  last_review_by?: string;
  is_same_person?: string;
  delay_in_seconds?: number;
  person?: { id: string };
  video?: { id: string };
  positive?: number;
  negative?: number;
  unreviewed?: number;
  total?: number;
}

export interface NotificationReceiveTimeReport {
  delay_in_seconds: number;
  video: VideoSources;
  positive: number;
  negative: number;
  unreviewed: number;
  total: number;
}

export interface VideoSources {
  id: string;
  name?: string;
}

export interface NotificationStatistics {
  data: Statistics;
  error?: AxiosError;
  isLoading: boolean;
}

export interface ReportPage {
  items: Report[];
  hasMore: boolean;
  total: number;
}

export interface NotificationReports {
  data: ReportPage;
  error?: AxiosError;
  isLoading: boolean;
}

export interface NotificationReportsDelay {
  data: {
    items: NotificationReceiveTimeReport[];
    hasMore: boolean;
    total: number;
  };
  error?: AxiosError;
  isLoading: boolean;
}

export interface NotificationVideoSources {
  data: VideoSources[];
  isLoading: boolean;
}

export interface DownloadReportsState {
  isLoading: boolean;
  error?: AxiosError;
}

export interface NotificationReportsState {
  notificationStatistics: NotificationStatistics;
  notificationReports: NotificationReports;
  notificationReportsDelay: NotificationReportsDelay;
  notificationVideoSources: NotificationVideoSources;
  downloadReportsState: DownloadReportsState;
}
