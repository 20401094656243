// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { AxiosError } from 'axios';
import { RootState } from '../../types';
import { OccurrenceState, History, Occurrence } from './types';

export const getters: GetterTree<OccurrenceState, RootState> = {
  getOccurrences: (state) => state.occurrencePreviewPage,
  getOccurrence: (state) => (occurrenceId: string): Occurrence | undefined => (
    state.occurrences.find(({ id }) => id === occurrenceId)
  ),
  getOccurrenceIteratorSettings: (state) => state.occurrenceIteratorSettings,
  getOccurrenceHistory: (state) => (occurrenceId: string): History[] | undefined => {
    const occurrence = state.occurrences.find(({ id }) => id === occurrenceId);

    return occurrence?.history;
  },
  isDownloadingOccurrences(state): boolean {
    return state.occurrencesDownloadState.isLoading;
  },
  occurrencesDownloadError(state): AxiosError | undefined {
    return state.occurrencesDownloadState.error;
  },
};

export default getters;
