// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { AxiosResponse } from 'axios';

export function blobToDataUrl(response: AxiosResponse): Promise<string> {
  return new Promise((resolve) => {
    const { data } = response;
    const reader = new FileReader();

    reader.onload = () => {
      const { result } = reader as {result: string};

      resolve(result);
    };

    reader.readAsDataURL(data);
  });
}

export function dataUrlToBlob(data: string): Promise<Blob | null> {
  return new Promise((resolve) => {
    // fetch loads the data content and sets its mime type
    fetch(data).then((res) => resolve(res.blob()));
  });
}

export function fileToBase64(file: File): Promise<string | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject();
      }
    };
  });
}
