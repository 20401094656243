// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { SuperProfileImages, SuperProfileImagesState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<SuperProfileImagesState, RootState> = {
  superProfileImages(state): SuperProfileImages[] | [] {
    return state.superProfilesImages || [];
  },
  imageSimilarSuperProfileIsBeingLoaded: (state) => (superProfileId: string, imageId: string) => {
    const superProfileImagesIndex = state.superProfilesImages?.findIndex(
        (profile) => profile.superProfileId === superProfileId);

    if (superProfileImagesIndex !== -1) {
      const { images } = state.superProfilesImages[superProfileImagesIndex];
      const imageFound = images.find((image) => image.id === imageId);

      return imageFound?.imageIsBeingLoaded;
    }

    return false;
  },
};

export default getters;
