// Copyright (C) 2021 Deconve Technology. All rights reserved.
import { ActionTree } from 'vuex';
import axios, {
  AxiosRequestConfig, AxiosResponse, CancelTokenSource,
} from 'axios';

import { blobToDataUrl, dataUrlToBlob } from '../../../utils/data';
import { RootState } from '../../types';
import {
  PersonImagesState, types, PersonImage, Info,
} from './types';
import { PersonFinderPeople } from '../people/types';

let getImageRequests = [] as CancelTokenSource[];
let addImageRequests = [] as CancelTokenSource[];

function downloadImage(url: string): Promise<string> {
  return new Promise((resolve) => {
    axios({
      url,
      method: 'get',
      responseType: 'blob',
    })
      .then((response) => blobToDataUrl(response as AxiosResponse))
      .then((image) => resolve(image))
      .catch(() => resolve(''));
  });
}

function selectDefaultFaceIndex(faces: Info[]) {
  let faceIndexSelected;

  if (faces && faces.length > 0) {
    // TODO: add option to set auto select faces
    const isToAutoSelectFace = false;

    faceIndexSelected = isToAutoSelectFace && faces.length === 1 ? 0 : undefined;
  }

  return faceIndexSelected;
}

export const actions: ActionTree<PersonImagesState, RootState> = {
  fetchPersonImages({ commit, state }, { images, personId }) {
    // Cancel current image requests if the person id changed

    if (personId !== state.personId) {
      commit(types.RESET_FACEID_PERSON_IMAGES);
      commit(types.ADD_PERSONID, personId);

      if (getImageRequests.length > 0) {
        getImageRequests.forEach((request) => {
          request.cancel();
        });
        getImageRequests = [];
      }

      if (addImageRequests.length > 0) {
        addImageRequests.forEach((request) => {
          request.cancel();
        });
        addImageRequests = [];
      }
    } else {
      return;
    }

    // Prepare the person image data with face info information to be requested
    const personImages: PersonImage[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    images.forEach((image: any) => {
      const personFaces = { faces: image.faces, selected: image.faces.length > 0 ? 0 : undefined };

      const personImage: PersonImage = {
        id: image.id,
        originalImageUrl: image.original_url,
        mediumImageUrl: image.medium_quality_url,
        thumbnailImageUrl: image.thumbnail_url,
        originalName: image.original,
        mediumName: image.medium_quality,
        thumbnailName: image.thumbnail,
        originalImage: '',
        originalHeight: image.original_height,
        originalWidth: image.original_width,
        mediumImage: '',
        thumbnailImage: '',
        info: personFaces,
        imageIsBeingLoaded: true,
        facesAreBeingLoaded: true,
      };

      personImages.push(personImage);

      commit(types.GET_PERSON_IMAGE_REQUEST, personImage);
    });

    // Sort the person images by quality
    commit(types.SORT_IMAGES_BY_QUALITY);

    // Download the images
    personImages.forEach((personImage) => {
      const cancelImageUrlRequest = axios.CancelToken.source();

      getImageRequests.push(cancelImageUrlRequest);

      const personImageData = { ...personImage };

      // Get thumbnail image version
      downloadImage(personImage.thumbnailImageUrl)
        .then((image) => {
          if (personId === state.personId && image) {
            personImageData.thumbnailImage = image;
            personImageData.imageIsBeingLoaded = false;
            personImageData.facesAreBeingLoaded = false;
            commit(types.GET_PERSON_IMAGE_SUCCESS, personImageData);

            // Getting the medium image quality to improve the face image quality in the person
            // profile
            downloadImage(personImage.mediumImageUrl)
              .then((mediumImage) => {
                if (personId === state.personId && mediumImage) {
                  personImageData.mediumImage = mediumImage;
                  commit(types.GET_PERSON_IMAGE_SUCCESS, personImageData);
                }
              })
              .catch((error) => {
                if (personId === state.personId) {
                  personImageData.error = error;
                  commit(types.GET_PERSON_IMAGE_FAILURE, personImageData);
                }
              });
          }
        })
        .catch((error) => {
          if (personId === state.personId) {
            personImageData.error = error;
            personImageData.imageIsBeingLoaded = false;
            personImageData.facesAreBeingLoaded = false;
            commit(types.GET_PERSON_IMAGE_FAILURE, personImageData);
          }
        });
    });
  },

  getMediumPersonImage({ commit, state }, imageIndex) {
    const newData = state.images.slice();
    const newImage = newData[imageIndex];

    commit(types.EDIT_PERSON_IMAGE_REQUEST, imageIndex);

    downloadImage(newImage.mediumImageUrl)
      .then((image) => {
        if (image) {
          newImage.mediumImage = image;

          commit(types.EDIT_PERSON_IMAGE_SUCCESS, { index: imageIndex, data: newImage });
        }
      }).catch((err) => {
        commit(types.EDIT_PERSON_IMAGE_FAILURE, { index: imageIndex, error: err });
      });
  },

  getOriginalPersonImage({ commit, state }, imageIndex): Promise<void> {
    return new Promise((resolve, reject) => {
      const newData = state.images.slice();
      const newImage = newData[imageIndex];

      commit(types.EDIT_PERSON_IMAGE_REQUEST, imageIndex);

      downloadImage(newImage.originalImageUrl)
        .then((image) => {
          if (image) {
            newImage.originalImage = image;
            commit(types.EDIT_PERSON_IMAGE_SUCCESS, { index: imageIndex, data: newImage });
          }

          resolve();
        }).catch((err) => {
          commit(types.EDIT_PERSON_IMAGE_FAILURE, { index: imageIndex, error: err });
          reject();
        });
    });
  },

  fetchPersonProfileImage({ commit, state }, { personId, profileImageUrl }): Promise<void> {
    return new Promise((resolve, reject) => {
      if (personId === state.personId) {
        resolve();
        return;
      }

      downloadImage(profileImageUrl)
        .then((image) => {
          if (personId === state.personId) {
            commit(types.ADD_PERSON_PROFILE_IMAGE, image);
          }

          resolve();
        }).catch((error) => {
          if (personId === state.personId) {
            reject(error);
          }
        });
    });
  },

  isImageNameValid({ state }, imageName): Promise<boolean> {
    return new Promise((resolve) => {
      // The file name without the extension must be unique, as defined by the faceid database
      const baseFileName = imageName.replace(/\.[^/.]+$/, '');
      const index = state.images.findIndex(
        ({ originalName }) => originalName.replace(/\.[^/.]+$/, '') === baseFileName,
      );

      resolve(index < 0);
    });
  },

  addImageToProfile(
    { commit, dispatch, state }, {
      name, frame, addImageToImageList,
    },
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      dispatch('isImageNameValid', name).then((isValid) => {
        if (!isValid) {
          resolve(false);
        } else {
          const file = {
            originalName: name, originalImage: frame, info: undefined,
          };

          const formData = new FormData();

          dataUrlToBlob(file.originalImage).then((blob) => {
            formData.append('images', blob as Blob, file.originalName);
            formData.append('is_to_keep_images', 'true');
            dispatch('editPerson', { personId: state.personId, payload: formData })
              .then(() => {
                if (addImageToImageList) {
                  commit(types.LOAD_FACEID_PERSON_IMAGE_SUCCESS, file);
                }

                resolve(true);
              })
              .catch((error) => reject(error));
          });
        }
      });
    });
  },
  loadPersonImage({ commit, dispatch, state }, file): Promise<boolean> {
    return new Promise((resolve) => {
      // The file name without the extension must be unique, as defined by the faceid database
      const baseFileName = file.name.replace(/\.[^/.]+$/, '');
      const index = state.images.findIndex(
        ({ originalName }) => originalName.replace(/\.[^/.]+$/, '') === baseFileName,
      );

      if (index >= 0) {
        resolve(false);
      } else {
        const fileURL = URL.createObjectURL(file);

        const image: PersonImage = {
          originalImageUrl: '',
          mediumImageUrl: '',
          thumbnailImageUrl: '',
          mediumImage: '',
          mediumName: '',
          thumbnailImage: '',
          thumbnailName: '',
          originalName: file.name,
          originalImage: fileURL,
          info: undefined,
          facesAreBeingLoaded: false,
          imageIsBeingLoaded: false,
        };

        commit(types.LOAD_FACEID_PERSON_IMAGE_SUCCESS, image);
        const imageIndex = state.images.findIndex((item) => item.originalName === file.name);

        dispatch('detectFaces', imageIndex);
        resolve(true);
      }
    });
  },

  detectFaces({ commit, state, rootGetters }, index) {
    const data = state.images.find((_, i) => i === index);

    if (!data) return;

    commit(types.DETECT_FACEID_FACES_REQUEST, index);

    dataUrlToBlob(data.originalImage).then((img) => {
      const formData = new FormData();

      formData.append('image', img as Blob, data.originalName);

      const requestOptions: AxiosRequestConfig = {
        method: 'post',
        url: 'faceid/actions/detect/faces/',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: rootGetters.authorizationToken,
        },
        data: formData,
      };

      axios(requestOptions).then((response) => {
        const { faces } = response.data;

        if (faces?.length > 0) {
          data.info = {
            faces,
            selected: selectDefaultFaceIndex(faces),
          };
        } else {
          data.info = undefined;
        }

        commit(types.DETECT_FACEID_FACES_SUCCESS, { data, index });
      }).catch(() => {
        commit(types.DETECT_FACEID_FACES_FAILURE, index);
      });
    });
  },

  deleteFaces({ commit, state }, index) {
    const data = state.images.slice();
    const newImage = data[index];

    newImage.info = undefined;

    commit(types.EDIT_PERSON_IMAGE_SUCCESS, { index, data: newImage });
  },

  editPersonImage({ commit, dispatch, state }, { imageData, fileExtension, imgIndex }) {
    const { image, height, width } = imageData;
    const data = state.images.slice();
    let { originalName } = data[imgIndex];

    // Removes the file extension
    const baseFileName = originalName.replace(/\.[^/.]+$/, '');

    originalName = baseFileName + fileExtension;

    commit(types.EDIT_PERSON_IMAGE_REQUEST, imgIndex);

    const newImage: PersonImage = {
      originalImageUrl: '',
      mediumImageUrl: '',
      thumbnailImageUrl: '',
      originalHeight: height,
      originalWidth: width,
      originalName,
      originalImage: image as string,
      mediumName: '',
      thumbnailName: '',
      mediumImage: '',
      thumbnailImage: '',
      info: undefined,
      facesAreBeingLoaded: false,
      imageIsBeingLoaded: true,
    };

    commit(types.EDIT_PERSON_IMAGE_SUCCESS, { index: imgIndex, data: newImage });
    dispatch('detectFaces', imgIndex);
  },

  addPersonProfileImage({ commit }, img) {
    commit(types.ADD_PERSON_PROFILE_IMAGE, img);
  },

  selectFace({ commit, state }, { imageIndex, faceIndex }) {
    const personImageIndex = state.images.findIndex((image, index) => index === imageIndex);

    if (personImageIndex !== -1) {
      const newState = state.images.slice();
      const newImage = newState[personImageIndex];

      if (newImage.info) {
        newImage.info = {
          faces: newImage.info.faces,
          selected: faceIndex,
        };

        commit(types.EDIT_PERSON_IMAGE_SUCCESS, newImage);
      }
    }
  },

  deletePersonImage({ commit, state }, imageIndex) {
    const newState = state.images.slice();
    const personImageIndex = newState.findIndex((image, index) => index === imageIndex);

    if (personImageIndex !== -1) {
      newState.splice(personImageIndex, 1);
      commit(types.DELETE_PERSON_IMAGE_SUCCESS, newState);
      commit(types.SORT_IMAGES_BY_QUALITY);
    }
  },

  sortPersonImages({ commit }) {
    commit(types.SORT_IMAGES_BY_QUALITY);
  },

  resetPersonImages({ commit }) {
    commit(types.RESET_FACEID_PERSON_IMAGES);
  },

  fetchDuplicatePeopleByImage({ commit, rootGetters }, { image, imageIndex }): Promise<void> {
    // Remove prefix added by base64 encoder. This prefix is not accepted by back-end
    const imageWithoutBase64Prefix = image.split('64,')[1];

    return new Promise((resolve, reject) => {
      const requestOptions: AxiosRequestConfig = {
        method: 'post',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: '/faceid/actions/identify/faces/',
        data: { image: imageWithoutBase64Prefix },
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then((response) => {
        const peopleData: PersonFinderPeople[] = response.data;
        const selectedPeople: PersonFinderPeople[] = [];
        const defaultScore = 0.95;

        peopleData.forEach((person) => {
          if (person.identification_score >= defaultScore) {
            selectedPeople.push(person);
          }
        });

        const filteredPeople = { data: selectedPeople, index: imageIndex };

        commit(types.ADD_PERSON_IMAGE_INFO_SIMILAR_PEOPLE, filteredPeople);
        resolve();
      }).catch((error) => {
        commit(types.ADD_PERSON_IMAGE_INFO_SIMILAR_PEOPLE, []);
        reject(error);
      });
    });
  },
};

export default actions;
