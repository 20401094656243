// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { Socket } from 'socket.io-client';

export const types: { [id: string]: string } = {
  SOCKET_INIT_CONNECTION: 'SOCKET_INIT_CONNECTION',
  SOCKET_CLOSE_CONNECTION: 'SOCKET_CLOSE_CONNECTION',
  SOCKET_UPDATE_AUTH_TOKEN: 'SOCKET_UPDATE_AUTH_TOKEN',
  SOCKET_ON_EVENT_MESSAGES: 'SOCKET_ON_EVENT_MESSAGES',

  COM_DECONVE_FACEID_NOTIFICATION_CREATED: 'com.deconve.faceid.notification:created',
  COM_DECONVE_FACEID_NOTIFICATION_CONFIRMED: 'com.deconve.faceid.notification:confirmed',
  COM_DECONVE_FACEID_NOTIFICATION_UNCONFIRMED: 'com.deconve.faceid.notification:unconfirmed',
  COM_DECONVE_FACEID_NOTIFICATION_DELETED: 'com.deconve.faceid.notification:deleted',
  COM_DECONVE_FACEID_NOTIFICATION_RESTORED: 'com.deconve.faceid.notification:restored',
  COM_DECONVE_FACEID_NOTIFICATION_REVIEWED: 'com.deconve.faceid.notification:reviewed',
  COM_DECONVE_FACEID_NOTIFICATION_MONITORED: 'com.deconve.faceid.notification:monitored',
  COM_DECONVE_FACEID_NOTIFICATION_UPDATED: 'com.deconve.faceid.notification:updated',
  COM_DECONVE_FACEID_NOTIFICATION_TRASHED: 'com.deconve.faceid.notification:trashed',
  COM_DECONVE_FACEID_PERSON_CREATED: 'com.deconve.faceid.person:created',
  COM_DECONVE_FACEID_PERSON_UPDATED: 'com.deconve.faceid.person:updated',
  COM_DECONVE_FACEID_PERSON_RESTORED: 'com.deconve.faceid.person:restored',
  COM_DECONVE_FACEID_PERSON_TRASHED: 'com.deconve.faceid.person:trashed',
};

export interface SocketState {
  socket?: Socket;
  event?: object;
}
