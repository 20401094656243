// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { Report, ReportsState, types } from './types';

export const mutations: MutationTree<ReportsState> = {
  [types.GET_RETAIL_ANALYTICS_REPORT_BY_UNIT_BY_DAY_SUCCESS](state, data: Report[][]) {
    state.reportByUnitByDay = data;
  },
  [types.GET_RETAIL_ANALYTICS_REPORT_BY_UNIT_BY_HOUR_SUCCESS](state, data: Report[][]) {
    state.reportByUnitByHour = data;
  },
  [types.GET_RETAIL_ANALYTICS_REPORT_TOTAL_BY_VIDEO_SUCCESS](state, data) {
    state.totalByVideo = data;
  },
  [types.GET_RETAIL_ANALYTICS_REPORT_TOTAL_BY_UNIT_SUCCESS](state, data) {
    state.totalByUnit = data;
  },
  [types.GET_RETAIL_ANALYTICS_REPORT_TOTAL_PEOPLE_SUCCESS](state, total: number) {
    state.totalNumberOfPeople = total;
  },
};

export default mutations;
