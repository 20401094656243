// Copyright (C) 2022 Deconve Technology. All rights reserved.

import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';

import en from 'apexcharts/dist/locales/en.json';
import ptBr from 'apexcharts/dist/locales/pt-br.json';

// Add apex chat locales globally. The locale should be defined globally too, but for now we did
// know how to do it
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).Apex = { chart: { locales: [en, ptBr] } };

Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);
