// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Notification } from './types';

// Keep the data cached for a maximum of 4,5 minutes
const kCacheMaxAgeInMs = 4.5 * 60 * 1000;
const kCacheMinAgeInMs = 1000;

function hasSomeImageUrlEmpty(data: Notification) {
  const isSomeUrlEmpty = data.faces.some((faceInfo) => {
    const { face_image: faceImageInfo, video_frame: videoFrameInfo } = faceInfo;
    const {
      original_url: faceImageOriginalUrl,
      thumbnail_url: faceImageThumbnailUrl,
    } = faceImageInfo;

    if (!faceImageOriginalUrl || !faceImageThumbnailUrl) return true;

    const {
      original_url: videoFrameOriginalUrl,
      thumbnail_url: videoFrameThumbnailUrl,
      medium_quality_url: videoFrameMediumQualityUrl,
    } = videoFrameInfo;

    if (!videoFrameOriginalUrl || !videoFrameThumbnailUrl || !videoFrameMediumQualityUrl) {
      return true;
    }

    return false;
  });

  return isSomeUrlEmpty;
}

// If some url is empty, the data is cached by the minimum time. The time is not zero to avoid a
// too many requests to backend. If the notification data is updated every time, in the current
// implementation, the vue component will be too
export function prepareNotificationToBeCached(data: Notification): Notification {
  const areTheImageUrlsReady = !hasSomeImageUrlEmpty(data);

  return { ...data, areTheImageUrlsReady, requestedAtMs: Date.now() };
}

export function isCachedDataValid(cachedData: Notification) {
  if (cachedData) {
    const { areTheImageUrlsReady, requestedAtMs } = cachedData;
    const cacheAge = Date.now() - requestedAtMs;
    const cacheAgeLimit = areTheImageUrlsReady ? kCacheMaxAgeInMs : kCacheMinAgeInMs;

    return cacheAge < cacheAgeLimit;
  }

  return false;
}

export function hasNotificationChanged(currentData: Notification, newData: Notification): boolean {
  if ((currentData.updated_at !== newData.updated_at)
      || (currentData.expire_at !== newData.expire_at)
      || (currentData.expire_mode !== newData.expire_mode)) {
    return true;
  }

  return !isCachedDataValid(currentData);
}
