// Copyright (C) 2022 Deconve Technology. All rights reserved.

export default {
  doohAnalytics: {
    mainIntroduction: {
      description: 'Conheça o alcance das suas campanhas e o CPM (custo por mil impressões). Com essas métricas você lida de forma sistemática com as melhores estratégias de marketing para suas campanhas',
    },
    mainMenu: {
      description: 'Aqui você pode configurar o período das campanhas e detalhes dos painéis, por exemplo',
    },
    selectCampaign: {
      description: 'Veja as campanhas ativas em um período de data e selecione uma campanha para ver o seu alcance e CPM',
    },
    selectPanel: {
      description: 'Você pode ver o fluxo de veículos de um painel ou de vários',
    },
    reportDateFilter: {
      description: 'Veja os relatórios dos painéis em um período específico',
    },
    campaignImpact: {
      description: 'Aqui fica claro a audiência da sua campanha e o CPM',
    },
    peopleImpacted: {
      description: 'Número de pessoas impactadas ao longo dos dias',
    },
    audienceByPanel: {
      description: 'Saiba quais painéis têm as maiores audiências',
    },
    reportByVehicleType: {
      description: 'Conheça a proporção da sua audiência por tipo de veículo',
    },
    campaignName: {
      description: 'Informe como a campanha será chamada',
    },
    campaignValue: {
      description: 'Informe o valor em R$ da campanha',
    },
    campaignPeriods: {
      description: 'Esse é o período de exibição da campanha',
    },
    campaignTags: {
      description: 'Você pode utilizar tags para ajudar a identificar as campanhas por estado, por exemplo, ou para limitar o acesso a elas',
    },
    campaignPanels: {
      description: 'Esses são os painéis onde a campanha será apresentada',
    },
    panelExposureRate: {
      description: 'A taxa de exposição é utilizada para calcular o impacto da campanha. Se a campanha ficou exposta 100% do tempo no painel, então todas as pessoas que viram o painél foram impactadas',
    },
    sameExposureRateForAllPanels: {
      description: 'Aplica a mesma taxa de exposição para todos os painéis',
    },
  },
  unitRegister: {
    name: 'Identificação da unidade',
    coordinates: 'Insira as coordenadas da unidade aqui',
    peopleByVehicles: 'Configure a estimativa de quantidade de pessoas por veículos que passam por essa unidade, por exemplo, 1.5 por carro e 10 por ônibus. Esses dados são utilizados para calcular quantas pessoas passam por esse local',
    tags: 'Você pode utilizar tags para ajudar a identificar as unidades por estado, por exemplo, ou para limitar o acesso a elas',
    videos: 'Câmeras utilizadas no monitoramento dessa unidade',
    enableImagePreview: 'Permite visualizar as imagens processadas. No status do player, por exemplo, é possível ver as linhas de contagem',
    enableAutoUpdate: 'Permite que a aplicação faça atualizações automáticas da unidade cadastrada',
  },
  unitViewer: {
    name: 'Informações da unidade',
    date: 'Mostra o log mais próximo da data selecionada. Confirme o horário do log na parte inferior do player',
    players: 'Informações do vídeo processado. A imagem estará indisponível se o player: 1) ficar sem acesso ao nosso servidor (offline); 2) perder conexão com o streaming de vídeo (câmera); 3) estiver parado; ou 4) não estiver com funcionalidade habilitada nas configurações da unidade',
  },
  tag: {
    region: {
      name: 'Região',
      value: 'Brasil',
    },
    state: {
      name: 'Estado',
      value: 'Santa Catarina',
    },
    city00: {
      name: 'Cidade',
      value: 'Florianópolis',
    },
    city01: {
      name: 'Cidade',
      value: 'Palhoça',
    },
  },
  units: {
    unit00: 'Unidade A',
    unit01: 'Shopping Center',
    unit02: '001 Loja',
  },
  videos: {
    video00: 'Acesso Escada Fixa',
    video01: 'Acesso Escada Rolante',
    video02: 'Shopping Center | Acesso Escada Rolante',
    video03: '001 Loja | Entrada',
  },
  faceid: {
    person00: 'dd/mm/yyyy - 001 Loja acesso principal - 01/02',
    superProfile: {
      name: 'Super Profile 01',
      about: 'Sobre o super perfil no modo de demonstração',
      aboutReview: 'Sobre a revisão do super perfil no modo de demonstração',
    },
  },
  occurrences: {
    occurrence00: {
      name: 'Ocorrência 01',
      timeLine: 'following',
      theftStatus: 'did_not_try_to_steal',
      about: '',
    },
    occurrence01: {
      name: '001 Cliente | Loja 12 34',
      timeLine: 'finished',
      theftStatus: 'stole',
      about: 'Suspeito furtou furadeira.',
    },
    occurrence02: {
      name: 'Loja Sul | Acesso AB',
      timeLine: 'finished',
      theftStatus: 'tried_to_steal',
      about: '',
    },
  },
  workspace: {
    name: 'Deconve',
  },
};
