// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_OBJECT_COUNTER_VIDEOS_REQUEST: 'GET_OBJECT_COUNTER_VIDEOS_REQUEST',
  GET_OBJECT_COUNTER_VIDEOS_SUCCESS: 'GET_OBJECT_COUNTER_VIDEOS_SUCCESS',
  GET_OBJECT_COUNTER_VIDEOS_FAILURE: 'GET_OBJECT_COUNTER_VIDEOS_FAILURE',

  GET_OBJECT_COUNTER_VIDEOS_GROUP_REQUEST: 'GET_OBJECT_COUNTER_VIDEOS_GROUP_REQUEST',
  GET_OBJECT_COUNTER_VIDEOS_GROUP_SUCCESS: 'GET_OBJECT_COUNTER_VIDEOS_GROUP_SUCCESS',
  GET_OBJECT_COUNTER_VIDEOS_GROUP_FAILURE: 'GET_OBJECT_COUNTER_VIDEOS_GROUP_FAILURE',
};

export interface Video {
  id: string;
  name: string;
}

export interface VideosState {
  isLoading: boolean;
  videos: Video[];
  error?: AxiosError;
}
