// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios from 'axios';
import {
  PercentageByVehicleType, ReportsState, Report, types,
} from './types';
import { RootState } from '../../types';
import { Video } from '../videos/types';
import { getReports } from '../../../api/vehiclecounter/report';

interface ReportQuery {
  startDate: string;
  endDate: string;
  groupBy: string;
  videoIds?: string[];
}

interface AudienceByPanel {
  data: number[];
  labels: string[];
}

interface DownloadReportyOptions {
  start_date: string;
  end_date: string;
  group_by: string;
  report_type: string;
  video_ids?: string[];
}

function calcPercentage(partial: number, total: number): string {
  if (total > 0) {
    const ratio = partial / total;
    const percentage = `${(ratio * 100).toFixed(2)}%`;

    return percentage;
  }

  return '0%';
}

function populateEntries(
  bus: number, car: number, motorbike: number, truck: number,
  total: number,
): PercentageByVehicleType {
  const vehiclesEntries: PercentageByVehicleType = {
    bus: { total: bus, percentage: calcPercentage(bus, total) },
    car: { total: car, percentage: calcPercentage(car, total) },
    motorbike: { total: motorbike, percentage: calcPercentage(motorbike, total) },
    truck: { total: truck, percentage: calcPercentage(truck, total) },
  };

  return vehiclesEntries;
}

function sortDataAndLabels(data: number[], labels: string[]): AudienceByPanel {
  // Data is sorted in descending order and, when there is multiple data with same value, the
  // labels are sorted in ascending order:
  // unsorted: C: 0, Bb: 0, Ba: 0, D: 1
  // sorted:   D: 1, Ba: 0, Bb: 0, C: 0
  const sortedData: number[] = [];
  const sortedLabels: string[] = [];

  const dataAndLabels: { [value: number]: string[] } = {};

  data.forEach((value, index) => {
    if (value in dataAndLabels) {
      dataAndLabels[value].push(labels[index]);
    } else {
      dataAndLabels[value] = [labels[index]];
    }
  });

  let sortedValueKeys: number[] = [];

  Object.keys(dataAndLabels).forEach((value) => sortedValueKeys.push(Number(value)));

  sortedValueKeys = sortedValueKeys.sort((a, b) => (a > b ? -1 : 1));

  sortedValueKeys.forEach((value) => {
    const valueLabels = dataAndLabels[value].sort((a, b) => (a > b ? 1 : -1));

    valueLabels.forEach((label: string) => {
      sortedData.push(value);
      sortedLabels.push(label);
    });
  });

  return { data: sortedData, labels: sortedLabels };
}

export const actions: ActionTree<ReportsState, RootState> = {
  downloadReportByHour(
    { rootGetters }, { startDate, endDate, videoIds }: ReportQuery,
  ) {
    const params: DownloadReportyOptions = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      start_date: startDate,
      // eslint-disable-next-line @typescript-eslint/camelcase
      end_date: endDate,
      // eslint-disable-next-line @typescript-eslint/camelcase
      group_by: 'hour',
      // hour_offset: dashboardSettings.getValue('hourOffset', 0),
      // eslint-disable-next-line @typescript-eslint/camelcase
      report_type: 'xlsx',
    };

    if (videoIds) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      params.video_ids = videoIds;
    }

    const { authorizationToken } = rootGetters;

    const host = process.env.VUE_APP_DECONVE_VEHICLE_COUNTER_API_URL
      || process.env.VUE_APP_DECONVE_API_URL;
    const url = `${host}/vehiclescounter/reports/`;
    const headers = { Authorization: authorizationToken };

    return axios.get(url, { params, headers, responseType: 'blob' })
      .then((response) => {
        const path = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = path;
        link.setAttribute('download', 'report.xlsx');
        document.body.appendChild(link);
        link.click();
        return true;
      });
  },
  getReportsByDay(
    { commit, rootGetters }, { startDate, endDate, videoIds }: ReportQuery,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_VEHICLE_COUNTER_REPORTS_BY_DAY_REQUEST);

      let videos: Video[] = [];

      if (videoIds) {
        const getVideoById = rootGetters['vehiclecounter/getVideo'];

        videoIds.forEach((id) => {
          const video = getVideoById(id);

          videos.push(video);
        });
      } else {
        videos = rootGetters['vehiclecounter/videos'];
      }

      const getVideoById = rootGetters['videos/getVideo'];
      const totalByVideo: { [id: string]: number } = {};

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];

      videos.forEach((video) => {
        const params = {
          startDate,
          endDate,
          groupBy: 'day',
          videoId: video.id,
        };

        promises.push(getReports(params, rootGetters));
      });

      Promise.all(promises).then((results) => {
        let totalBus = 0;
        let totalCar = 0;
        let totalMotorbike = 0;
        let totalTruck = 0;

        results.forEach((dataByVideo: Report[]) => {
          dataByVideo.forEach((report: Report) => {
            const {
              video, direction_in: directionIn,
              direction_out: directionOut, direction_undefined: directionUndefined,
            } = report;

            const { id: videoId } = video;

            let totalByReport = 0;

            if (directionIn) {
              const {
                car, motorbike, bus, truck, total,
              } = directionIn;

              if (car) totalCar += car;
              if (bus) totalBus += bus;
              if (truck) totalTruck += truck;
              if (motorbike) totalMotorbike += motorbike;
              if (total) totalByReport += total;
            }

            if (directionOut) {
              const {
                car, motorbike, bus, truck, total,
              } = directionOut;

              if (car) totalCar += car;
              if (bus) totalBus += bus;
              if (truck) totalTruck += truck;
              if (motorbike) totalMotorbike += motorbike;
              if (total) totalByReport += total;
            }

            if (directionUndefined) {
              const {
                car, motorbike, bus, truck, total,
              } = directionUndefined;

              if (car) totalCar += car;
              if (bus) totalBus += bus;
              if (truck) totalTruck += truck;
              if (motorbike) totalMotorbike += motorbike;
              if (total) totalByReport += total;
            }

            if (videoId) {
              if (videoId in totalByVideo) {
                totalByVideo[videoId] += totalByReport;
              } else {
                totalByVideo[videoId] = totalByReport;
              }
            }
          });
        });

        const totalNumberOfVehiclesByVideo: number[] = [];
        const videoNames: string[] = [];

        videos.forEach((video) => {
          const videoData = getVideoById(video.uuid);
          const { id: videoId } = video;
          let videoName;

          // TODO: remove after migrate the vehicle counter videos to units database
          if (videoData) {
            videoName = videoData.name;
          } else {
            videoName = video.name;
          }

          videoNames.push(videoName);

          if (videoId in totalByVideo) {
            totalNumberOfVehiclesByVideo.push(totalByVideo[videoId]);
          } else {
            totalNumberOfVehiclesByVideo.push(0);
          }
        });

        const audienceByVideo = sortDataAndLabels(totalNumberOfVehiclesByVideo, videoNames);

        commit(types.GET_VEHICLE_COUNTER_REPORTS_AUDIENCE_PANEL_SUCCESS, audienceByVideo);

        const total = totalCar + totalBus + totalTruck + totalMotorbike;

        commit(types.GET_VEHICLE_COUNTER_REPORTS_TOTAL_PEOPLE, total);

        commit(types.GET_VEHICLE_COUNTER_REPORTS_PERCENTAGE_PEOPLE_REQUEST);

        const vehiclesEntries = populateEntries(
          totalBus, totalCar, totalMotorbike, totalTruck, total,
        );

        commit(types.GET_VEHICLE_COUNTER_REPORTS_PERCENTAGE_PEOPLE_SUCCESS, vehiclesEntries);
        commit(types.GET_VEHICLE_COUNTER_REPORTS_BY_DAY_SUCCESS, results);
        resolve();
      }).catch((error) => {
        commit(types.GET_VEHICLE_COUNTER_REPORTS_BY_DAY_FAILURE);
        reject(error);
      });
    });
  },
};

export default actions;
