// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Module } from 'vuex';

import { RootState } from '../../types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { ReportsState } from './types';

export const state: ReportsState = {
  reportByUnitByDay: [],
  reportByUnitByHour: [],
  totalByVideo: { data: [], label: [] },
  totalByUnit: { data: [], label: [] },
  totalNumberOfPeople: 0,
};

const namespaced = false;

export const reports: Module<ReportsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
