// Copyright (C) 2022 Deconve Technology. All rights reserved.

import Vue from 'vue';
import { MutationTree } from 'vuex';
import { Player, PlayersState, types } from './types';

export const mutations: MutationTree<PlayersState> = {
  [types.GET_PLAYERS_SUCCESS](state, { unitId, players }: { unitId: string; players: Player[] }) {
    Vue.set(state.unitPlayers, unitId, players);
  },
};

export default mutations;
