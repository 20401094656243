// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';
import { Video } from '../../vehiclecounter/videos/types';

export const types: { [id: string]: string } = {
  GET_DOOH_PEOPLE_BY_VEHICLE_SUCCESS: 'GET_DOOH_PEOPLE_BY_VEHICLE_SUCCESS',
  GET_DOOH_AUDIENCE_BY_PANEL_SUCCESS: 'GET_DOOH_AUDIENCE_BY_PANEL_SUCCESS',

  GET_DOOH_REPORTS_BY_DAY_REQUEST: 'GET_DOOH_REPORTS_BY_DAY_REQUEST',
  GET_DOOH_REPORTS_BY_DAY_SUCCESS: 'GET_DOOH_REPORTS_BY_DAY_SUCCESS',
  GET_DOOH_REPORTS_BY_DAY_FAILURE: 'GET_DOOH_REPORTS_BY_DAY_FAILURE',

  GET_DOOH_TOTAL_PEOPLE_IMPACTED_SUCCESS: 'GET_DOOH_TOTAL_PEOPLE_IMPACTED_SUCCESS',
  GET_DOOH_TOTAL_VEHICLES_IMPACTED_SUCCESS: 'GET_DOOH_TOTAL_VEHICLES_IMPACTED_SUCCESS',
  GET_DOOH_TOTAL_VEHICLES_SUCCESS: 'GET_DOOH_TOTAL_VEHICLES_SUCCESS',

  GET_DOOH_TOTAL_PEOPLE_IMPACTED_BY_CAMPAIGN_SUCCESS: 'GET_DOOH_TOTAL_PEOPLE_IMPACTED_BY_CAMPAIGN_SUCCESS',
  GET_DOOH_TOTAL_VEHICLES_IMPACTED_BY_CAMPAIGN_SUCCESS: 'GET_DOOH_TOTAL_VEHICLES_IMPACTED_BY_CAMPAIGN_SUCCESS',
  GET_DOOH_TOTAL_VEHICLES_BY_CAMPAIGN_SUCCESS: 'GET_DOOH_TOTAL_VEHICLES_BY_CAMPAIGN_SUCCESS',

  GET_DOOH_CAMPAIGN_COST_PER_MILE_IMPRESSIONS_SUCCESS: 'GET_DOOH_CAMPAIGN_COST_PER_MILE_IMPRESSIONS_SUCCESS',
};

export interface DirectionData {
  bus: number;
  car: number;
  motorbike: number;
  truck: number;
  total: number;
}

interface Results {
  total: number;
  percentage?: string;
}

export interface Report {
  video: Video;
  created_at: string;
  local_time_zone: number;
  vehicle: string;
  direction_in: DirectionData;
  direction_out: DirectionData;
  direction_undefined: DirectionData;
}

export interface PeopleReport {
  video: Video;
  createdAt: string;
  localTimeZone: number;
  peopleByVehicles: DirectionData;
  numberOfVehicles: number;
  numberOfVehiclesImpacted: number;
}

export type ReportByVideo = Array<PeopleReport>
export type ReportByPanel = Array<ReportByVideo>

export interface PercentageByVehicleType {
  bus: Results;
  car: Results;
  motorbike: Results;
  truck: Results;
}

interface ChartAudience {
  data?: number[];
  label: string[];
}

export interface Reports {
  byPanel: ReportByPanel[];
  error?: AxiosError;
  isLoading: boolean;
  audience: ChartAudience;
  byVehicles: PercentageByVehicleType;
}

export interface ReportsState {
  campaignCostPerMileImpressions: number;
  reportsByDay: Reports;
  numberOfPeopleImpacted: number;
  numberOfVehiclesImpacted: number;
  totalNumberOfVehicles: number;
  numberOfPeopleImpactedByCampaign: number;
  numberOfVehiclesImpactedByCampaign: number;
  totalNumberOfVehiclesByCampaign: number;
}
