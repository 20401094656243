// Copyright (C) 2023 Deconve Technology. All rights reserved.
/* eslint-disable @typescript-eslint/camelcase */

import moment from 'moment';

import {
  Ages, DirectionData, Genders, Report,
} from './types';

interface ReportQuery {
  startDate: string;
  endDate: string;
  groupBy: string;
  videoId?: string;
  videoGroupId?: string;
}

function padNumber(num: number): string {
  if (num < 10) {
    return `0${num}`;
  }

  return num.toString();
}

// uses the Box-Muller transform to generate random numbers from a Gaussian distribution
function generateGaussianRandom(mean: number, standardDeviation: number) {
  let u1;
  let u2;
  let s;

  do {
    u1 = Math.random() * 2 - 1;
    u2 = Math.random() * 2 - 1;
    s = u1 * u1 + u2 * u2;
  } while (s >= 1 || s === 0);

  const v1 = u1 * Math.sqrt((-2 * Math.log(s)) / s);

  // Adjust the mean and standard deviation
  const randomValue = v1 * standardDeviation + mean;

  return randomValue;
}

function getAges(numberOfPeople: number): Ages {
  const maxAge = 99;
  const ages: Ages = {};

  for (let age = 0; age <= maxAge; age += 1) {
    const ageLabel = padNumber(age);

    ages[ageLabel] = 0;
  }

  const refAge = 25 + 15 * Math.random();

  for (let i = 0; i < numberOfPeople; i += 1) {
    let age;

    do {
      age = Math.round(generateGaussianRandom(refAge, 10));
    } while (age < 0 || age > maxAge);

    const ageLabel = padNumber(age);

    ages[ageLabel] += 1;
  }

  return ages;
}

function getGenders(numberOfPeople: number): Genders {
  const female = Math.round(Math.random() * (numberOfPeople - 1));
  const male = numberOfPeople - female;

  return { female, male };
}

function getDataByDirection(total: number, distinct: number): DirectionData {
  return {
    total,
    distinct,
    genders: getGenders(distinct),
    ages: getAges(distinct),
  };
}

export default function getDemoReports(query: ReportQuery): Promise<Report[]> {
  return new Promise((resolve) => {
    const {
      startDate, endDate, videoId, videoGroupId,
    } = query;

    const startAt = moment.utc(startDate);
    const endAt = moment.utc(endDate);
    const reports: Report[] = [];

    // Do not use too many samples because of the time required to generated the samples
    const maxNumberOfSamples = 100;

    while (startAt.isBefore(endAt)) {
      const distinctUndefined = Math.round(Math.random() * maxNumberOfSamples);
      const totalUndefined = distinctUndefined + Math.round(Math.random() * maxNumberOfSamples);

      const report: Report = {
        created_at: startAt.toISOString(),
        local_time_zone: -3,
        direction_in: undefined,
        direction_out: undefined,
        direction_undefined: getDataByDirection(totalUndefined, distinctUndefined),
      };

      if (videoId) {
        report.video = { id: videoId, name: videoId };
      }

      if (videoGroupId) {
        report.video_group = { id: videoGroupId, name: videoGroupId };
      }

      reports.push(report);

      startAt.add(1, 'day');
    }

    resolve(reports);
  });
}
