// Copyright (C) 2021 Deconve Technology. All rights reserved.

import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { auth } from './auth';
import { dooh } from './dooh';
import { faceid } from './faceid';
import { objectcounter } from './objectcounter';
import { peoplecounter } from './peoplecounter';
import { retailAnalytics } from './retailAnalytics';
import { vehiclecounter } from './vehiclecounter';
import { notification } from './notification';
import { players } from './players';
import { socket } from './socket';
import { tags } from './tags';
import { units } from './units';
import { videos } from './videos';
import { workspace } from './workspace';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    auth,
    dooh,
    faceid,
    notification,
    objectcounter,
    peoplecounter,
    players,
    retailAnalytics,
    socket,
    tags,
    units,
    videos,
    workspace,
    vehiclecounter,
  },
};

export default new Vuex.Store<RootState>(store);
