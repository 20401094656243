// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { PlayersState, Player, PlayerLog } from './types';
import { RootState } from '../types';

export const getters: GetterTree<PlayersState, RootState> = {
  getUnitPlayers: (state) => (unitId: string): Player[] | undefined => (
    unitId in state.unitPlayers ? state.unitPlayers[unitId] : undefined
  ),
  getLastPlayerLog: (state) => (unitId: string, playerId: string): PlayerLog | undefined => {
    let lastLog;

    if (unitId in state.unitPlayers) {
      const unitPlayers = state.unitPlayers[unitId];
      const player = unitPlayers.find(({ id }) => id === playerId);

      if (player) lastLog = player.last_log;
    }

    return lastLog;
  },
};

export default getters;
