// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { RootState } from '../types';

import { campaigns } from './campaigns';
import { reports } from './reports';

const namespaced = true;

export const dooh: Module<RootState, RootState> = {
  namespaced,
  modules: {
    campaigns,
    reports,
  },
};

export default dooh;
