// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { PersonVideosState } from './types';
import { RootState } from '../../types';

export const state: PersonVideosState = {
  videos: [],
  personId: '',
};

const namespaced = false;

export const personVideos: Module<PersonVideosState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
