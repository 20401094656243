// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { getDemoVideo as getUnitVideoDemo } from '@/store/videos/demo';
import { Video } from './types';

export default function getDemoVideo(videoId: string): Promise<Video> {
  return new Promise((resolve) => {
    getUnitVideoDemo(videoId).then((videoData) => {
      const { id, name } = videoData;
      const videoInfo: Video = { id, name };

      resolve(videoInfo);
    });
  });
}
