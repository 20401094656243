// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

import { MutationTree } from 'vuex';
import {
  AuthState, User, types, Introspect,
} from './types';

export const mutations: MutationTree<AuthState> = {
  [types.SET_UPDATE_TOKEN_TIMER_ID](state, timerId: number | undefined) {
    state.tokenUpdateTimerId = timerId;
  },
  [types.AUTH_LOGOUT](state) {
    state.isLogged = false;
    state.isLoading = false;
    state.isLogging = false;
    state.authorizationToken = '';
    state.user = undefined;
  },
  [types.AUTH_LOGIN](state) {
    state.isLogged = true;
  },
  [types.USER_LOGIN_REQUEST](state) {
    state.isLogging = true;
  },
  [types.USER_LOGIN_SUCCESS](state, { authorizationToken, version }) {
    state.isLogging = false;
    state.authorizationToken = authorizationToken;
    state.authorizationVersion = version;
  },
  [types.USER_LOGIN_FAILURE](state) {
    state.isLogging = false;
    state.authorizationToken = '';
  },
  [types.GET_USER_INFO_REQUEST](state) {
    state.isLoading = true;
  },
  [types.GET_USER_INFO_SUCCESS](state, user: User) {
    state.isLoading = false;
    state.user = user;
  },
  [types.GET_USER_INFO_FAILURE](state, error: AxiosError) {
    state.isLoading = false;
    state.error = error;
  },
  [types.GET_USER_INTROSPECT_SUCCESS](state, introspect: Introspect) {
    state.introspect = introspect;
  },
  [types.ENABLE_DEMO_MODE](state, isEnabled: boolean) {
    state.isDemoMode = isEnabled;
  },
};

export default mutations;
