// Copyright (C) 2020 Deconve Technology. All rights reserved.

import Vue from 'vue';
import VueRouter from 'vue-router';

import auth from '../services/auth';
import LoginCallback from '../components/LoginCallback';
import Index from '../views/Index.vue';

Vue.use(VueRouter);

// Use route level code-splitting when defining a new route.
// component: () => import(/* webpackChunkName: "pagename" */ '../views/PageName.vue'),
// It generates a separate chunk (pagename.[hash].js, for example) for the route.
// Such chunk/page will be loaded only when the route is visited - this is called lazy-loading.
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Index,
    children: [
      {
        path: '/forbidden',
        name: 'forbidden',
        component: () => import(/* webpackChunkName: "forbidden" */ '../views/utils/Forbidden.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/faceid/people',
        name: 'faceid-people',
        component: () => import(/* webpackChunkName: "faceid-people" */ '../views/faceid/People/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['use:com.deconve.id', 'read:com.deconve.faceid.person'],
        },
      },
      {
        path: '/faceid/people/new',
        name: 'faceid-person-register',
        component: () => import(/* webpackChunkName: "faceid-person" */ '../views/faceid/Person/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['use:com.deconve.id', 'create:com.deconve.faceid.person'],
        },
      },
      {
        path: '/faceid/people/:personId',
        name: 'faceid-person',
        component: () => import(/* webpackChunkName: "faceid-person" */ '../views/faceid/Person/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['use:com.deconve.id', 'read:com.deconve.faceid.person'],
        },
      },
      {
        path: '/faceid/superprofiles',
        name: 'faceid-super-profiles',
        component: () => import(/* webpackChunkName: "faceid-super-profiles" */ '../views/faceid/SuperProfiles/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['use:com.deconve.hub', 'read:com.deconve.faceid.superprofile'],
        },
      },
      {
        path: '/faceid/superprofiles/:superProfileId',
        name: 'faceid-super-profile',
        component: () => import(/* webpackChunkName: "faceid-super-profile" */ '../views/faceid/SuperProfile/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['use:com.deconve.hub', 'read:com.deconve.faceid.superprofile'],
        },
      },
      {
        path: '/faceid/occurrences',
        name: 'faceid-occurrences',
        component: () => import(/* webpackChunkName: "faceid-occurrences" */ '../views/faceid/Occurrences/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['use:com.deconve.id', 'read:com.deconve.faceid.occurrence'],
        },
      },
      {
        path: '/faceid/occurrences/:occurrenceId',
        name: 'faceid-occurrence',
        component: () => import(/* webpackChunkName: "faceid-occurrence" */ '../views/faceid/Occurrence/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['use:com.deconve.id', 'read:com.deconve.faceid.occurrence'],
        },
      },
      {
        path: '/faceid/notifications',
        name: 'faceid-notifications',
        component: () => import(/* webpackChunkName: "faceid-notifications" */ '../views/faceid/Notifications/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['use:com.deconve.id', 'read:com.deconve.faceid.notification'],
        },
      },
      {
        path: '/faceid/notifications/:notificationId',
        name: 'faceid-notification',
        component: () => import(/* webpackChunkName: "faceid-notification" */ '../views/faceid/Notification/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['use:com.deconve.id', 'read:com.deconve.faceid.notification'],
        },
      },
      {
        path: '/faceid/reports',
        name: 'faceid-reports',
        component: () => import(/* webpackChunkName: "faceidreports" */ '../views/faceid/Reports/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['use:com.deconve.id', 'read:com.deconve.faceid.report'],
        },
      },
      {
        path: '/analytics/dooh',
        name: 'analytics-dooh-audience',
        component: () => import(/* webpackChunkName: "analytics-dooh-audience" */ '../views/analytics/DoohAudience.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            'use:com.deconve.analytics.dooh',
            'read:com.deconve.vehiclecounter.report',
          ],
        },
      },
      {
        path: '/analytics/vehicles/',
        name: 'analytics-vehicles',
        component: () => import(/* webpackChunkName: "analytics-vehicles" */ '../views/analytics/Vehicles.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            'use:com.deconve.analytics.vehicleflow',
            'read:com.deconve.vehiclecounter.report',
          ],
        },
      },
      {
        path: '/analytics/people/',
        name: 'analytics-people',
        component: () => import(/* webpackChunkName: "analytics-people" */ '../views/analytics/PeopleFlow/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            'use:com.deconve.analytics.peopleflow',
            'read:com.deconve.peoplecounter.report',
          ],
        },
      },
      {
        path: '/analytics/retail/',
        name: 'analytics-retail',
        component: () => import(/* webpackChunkName: "analytics-retail" */ '../views/analytics/RetailAnalytics/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            'use:com.deconve.analytics.retail',
            'read:com.deconve.retailanalytics.report',
          ],
        },
      },
      {
        path: '/objectcounter/reports',
        name: 'object-counter-reports',
        component: () => import(/* webpackChunkName: "object-counter-reports" */ '../views/objectcounter/Reports.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['use:com.deconve.objectcounter', 'read:com.deconve.objectcounter.report'],
        },
      },
      {
        path: '/units/new',
        name: 'unit-register',
        component: () => import(/* webpackChunkName: "unit" */ '../views/units/UnitDetails/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['create:com.deconve.unit'],
        },
      },
      {
        path: '/units/:unitId',
        name: 'unit-details',
        component: () => import(/* webpackChunkName: "unit" */ '../views/units/UnitDetails/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['read:com.deconve.unit'],
        },
      },
      {
        path: '/units',
        name: 'units',
        component: () => import(/* webpackChunkName: "units" */ '../views/units/Units/index.vue'),
        meta: {
          requiresAuth: true,
          permissions: ['read:com.deconve.unit'],
        },
      },
      {
        path: '/settings/workspace',
        name: 'settings-workspace',
        component: () => import(/* webpackChunkName: "settings-workspace" */ '../views/settings/Workspace.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/settings/users',
        name: 'settings-users',
        component: () => import(/* webpackChunkName: "settings-users" */ '../views/settings/Users.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/settings/apikeys',
        name: 'settings-apikeys',
        component: () => import(/* webpackChunkName: "settings-apikeys" */ '../views/settings/ApiKeys.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/settings/general',
        name: 'settings-general',
        component: () => import(/* webpackChunkName: "settings-general" */ '../views/settings/GeneralSettings.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: '/tools',
        name: 'tools',
        component: () => import(/* webpackChunkName: "tools" */ '../views/tools/index.vue'),
        meta: { requiresAuth: true },
      },
    ],
    meta: { requiresAuth: true },
  },
  { path: '/callback', component: LoginCallback },
  // Otherwise redirect to home
  { path: '*', redirect: '/' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(auth.middleware);
router.beforeEach(auth.permissionsMiddleware);

export default router;
