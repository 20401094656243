// Copyright (C) 2020 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { PersonPreviewPage, PeopleState, types } from './types';

import { hasPersonChanged } from './utils';

const kMaxBufferSize = 128;

export const mutations: MutationTree<PeopleState> = {
  [types.GET_FACEID_PEOPLE_SUCCESS](state, data: PersonPreviewPage) {
    state.peoplePreviewList.total = data.total;
    state.peoplePreviewList.items = data.items;
  },
  [types.GET_FACEID_PERSON_FINDER_PEOPLE_SUCCESS](state, people) {
    state.peopleByImageList.people = people;
  },
  [types.GET_FACEID_PERSON_FINDER_IMAGE_FACES](state, faces) {
    state.peopleByImageList.file.faces = faces;
  },
  [types.SET_FACEID_PERSON_FINDER_IMAGE_FACE_INDEX](state, index) {
    state.peopleByImageList.file.selectedFaceIndex = index;
  },
  [types.SET_FACEID_PERSON_FINDER_SCORE_THRESHOLD](state, score) {
    state.peopleByImageList.filters.selectedScore = score;
  },
  [types.SET_FACEID_PERSON_FINDER_PAGINATION](state, { page, itemsPerPage }) {
    state.peopleByImageList.filters.page = page;
    state.peopleByImageList.filters.itemsPerPage = itemsPerPage;
  },
  [types.SET_FACEID_PERSON_FINDER_SELECTED_PEOPLE](state, selectedPeople) {
    state.peopleByImageList.selectedPeople = selectedPeople;
  },
  [types.ADD_FACEID_PERSON_FINDER_IMAGE](state, { image, name }) {
    state.peopleByImageList.people = undefined;
    state.peopleByImageList.selectedPeople = undefined;
    state.peopleByImageList.file = {
      image,
      name,
      faces: [],
      selectedFaceIndex: -1,
    };
  },
  [types.RESET_FACEID_PERSON_FINDER_IMAGE_FACES](state) {
    state.peopleByImageList.file.selectedFaceIndex = -1;
    state.peopleByImageList.file.faces = undefined;
  },
  [types.REMOVE_FACEID_PERSON_FINDER_IMAGE](state) {
    state.peopleByImageList.people = undefined;
    state.peopleByImageList.selectedPeople = undefined;
    state.peopleByImageList.file = {
      faces: undefined,
      selectedFaceIndex: -1,
      image: '',
      name: '',
    };
    state.peopleByImageList.filters = {
      selectedScore: 0.95,
      page: 1,
      itemsPerPage: 8,
    };
  },
  [types.GET_FACEID_PERSON_TAGS_REQUEST](state) {
    state.person.error = undefined;
  },
  [types.GET_FACEID_PERSON_TAGS_SUCCESS](state, data) {
    const newData = { ...state.person };

    newData.tags = data;
    state.person = newData;
  },
  [types.GET_FACEID_PERSON_TAGS_FAILURE](state, error) {
    state.person.error = error;
  },
  [types.DELETE_FACEID_PERSON_TAG_REQUEST](state) {
    state.person.error = undefined;
  },
  [types.DELETE_FACEID_PERSON_TAG_SUCCESS](state, tagId) {
    if (state.person.data) {
      const tagIndex = state.person.tags.findIndex(({ id }) => id === tagId);
      const isTagExists = tagIndex !== -1;

      if (isTagExists) {
        state.person.tags.splice(tagIndex, 1);
      }
    }
  },
  [types.DELETE_FACEID_PERSON_TAG_FAILURE](state, error) {
    state.person.error = error;
  },
  [types.RESET_FACEID_PERSON_TAG](state) {
    state.person.tags = [];
  },
  [types.ADD_FACEID_PERSON_REQUEST](state) {
    state.person.isLoading = true;
    state.person.error = undefined;
  },
  [types.GET_FACEID_PERSON_REQUEST](state) {
    state.person.isLoading = true;
  },
  [types.GET_FACEID_PERSON_FAILURE](state) {
    state.person.isLoading = false;
  },
  [types.GET_FACEID_PERSON_SUCCESS](state, data) {
    state.person.data = data;
    state.person.isLoading = false;

    const { id: personId } = data;
    const personIndex = state.people.findIndex(({ id }) => id === personId);

    if (personIndex >= 0) {
      // To avoid to propagate signals when the person is reviewed, for example, we check
      // if he has changed before to update the store
      const currentData = state.people[personIndex];

      if (hasPersonChanged(currentData, data)) {
        state.people.splice(personIndex, 1, data);
      }
    } else {
      const numberOfPeople = state.people.push(data);

      if (numberOfPeople > kMaxBufferSize) {
        state.people.shift();
      }
    }
  },
  [types.ADD_FACEID_PERSON_FAILURE](state, error) {
    state.person.isLoading = false;
    state.person.error = error;
  },
  [types.EDIT_FACEID_PERSON_REQUEST](state) {
    state.person.isLoading = true;
    state.person.error = undefined;
  },
  [types.EDIT_FACEID_PERSON_SUCCESS](state, data) {
    state.person.data = data;
    state.person.isLoading = false;

    const { id: personId } = data;
    const personIndex = state.people.findIndex(({ id }) => id === personId);

    if (personIndex >= 0) {
      // To avoid to propagate signals when the person is reviewed, for example, we check
      // if he has changed before to update the store
      const currentData = state.people[personIndex];

      if (hasPersonChanged(currentData, data)) {
        state.people.splice(personIndex, 1, data);
      }
    } else {
      const numberOfPeople = state.people.push(data);

      if (numberOfPeople > kMaxBufferSize) {
        state.people.shift();
      }
    }
  },
  [types.EDIT_FACEID_PERSON_FAILURE](state, error) {
    state.person.isLoading = false;
    state.person.error = error;
  },
  [types.DELETE_FACEID_PERSON_REQUEST](state) {
    state.person.isLoading = true;
  },
  [types.DELETE_FACEID_PERSON_SUCCESS](state, personId: string) {
    state.person.isLoading = false;
    state.person.data = undefined;

    state.peoplePreviewList.items = [];
    state.peoplePreviewList.total = 0;

    const personIndex = state.people.findIndex(({ id }) => id === personId);

    if (personIndex >= 0) {
      state.people.splice(personIndex, 1);
    }
  },
  [types.DELETE_FACEID_PERSON_FAILURE](state) {
    state.person.isLoading = false;
  },
  [types.GET_FACEID_PERSON_FALSE_NEGATIVES_SUCCESS](state, data) {
    state.personFalseNegatives = data;
  },
  [types.GET_FACEID_PERSON_FALSE_NEGATIVES_FAILURE](state, error) {
    state.person.error = error;
  },
  isLoading(state) {
    state.person.isLoading = true;
  },
  setPersonIteratorSettings(state, data) {
    state.personIteratorSettings = data;
  },
  setPersonFalseNegatives(state, data) {
    state.personFalseNegatives = data;
  },
  resetError(state) {
    state.person.error = undefined;
  },
  resetPerson(state) {
    state.person = {
      isLoading: false,
      data: undefined,
      tags: [],
      error: undefined,
    };
  },
};

export default mutations;
