// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_SUPER_PROFILE_IMAGE_REQUEST: 'GET_SUPER_PROFILE_IMAGE_REQUEST',
  GET_SUPER_PROFILE_IMAGE_SUCCESS: 'GET_SUPER_PROFILE_IMAGE_SUCCESS',
  GET_SUPER_PROFILE_IMAGE_FAILURE: 'GET_SUPER_PROFILE_IMAGE_FAILURE',

  ADD_SUPER_PROFILE_IMAGE_PROFILE: 'ADD_SUPER_PROFILE_IMAGE_PROFILE',

  EDIT_SUPER_PROFILE_IMAGE_REQUEST: 'EDIT_SUPER_PROFILE_IMAGE_REQUEST',
  EDIT_SUPER_PROFILE_IMAGE_SUCCESS: 'EDIT_SUPER_PROFILE_IMAGE_SUCCESS',
  EDIT_SUPER_PROFILE_IMAGE_FAILURE: 'EDIT_SUPER_PROFILE_IMAGE_FAILURE',
};

interface BoundingBox {
  width: number;
  height: number;
  top_left_x: number;
  top_left_y: number;
}

export interface Info {
  bounding_box: BoundingBox;
  image_quality_score: number;
}

export interface ImageInfo {
  faces: Info[];
}

export interface SuperProfileImage {
  id?: string;
  originalImageUrl: string;
  mediumImageUrl: string;
  thumbnailImageUrl: string;
  originalName: string;
  mediumName: string;
  thumbnailName: string;
  originalImage: string;
  mediumImage: string;
  thumbnailImage: string;
  imageIsBeingLoaded: boolean;
  facesAreBeingLoaded?: boolean;
  info: ImageInfo | undefined;
  originalHeight?: number;
  originalWidth?: number;
  error?: AxiosError;
}

export interface SuperProfileImages {
  superProfileId: string;
  images: SuperProfileImage[];
  profileImage: string;
}

export interface SuperProfileImagesState {
  superProfilesImages: SuperProfileImages[];
}
