// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { SocketState } from './types';
import { RootState } from '../types';

export const state: SocketState = {
  socket: undefined,
  event: undefined,
};

const namespaced = false;

export const socket: Module<SocketState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
