// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { getDataWithPagination } from '@/utils/getDataWithPagination';

interface ReportQuery {
  startDate: string;
  endDate: string;
  groupBy: string;
  videoId: string;
}
interface RootGetters {
  authorizationToken: string;
}

export function getReports({
  startDate, endDate, groupBy, videoId,
}: ReportQuery, rootGetters: RootGetters) {
  const params = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    start_date: startDate,
    // eslint-disable-next-line @typescript-eslint/camelcase
    end_date: endDate,
    // eslint-disable-next-line @typescript-eslint/camelcase
    group_by: groupBy,
    // eslint-disable-next-line @typescript-eslint/camelcase
    video_id: videoId,
    limit: 1000,
    skip: 0,
  };

  const host = process.env.VUE_APP_DECONVE_OBJECT_COUNTER_API_URL
      || process.env.VUE_APP_DECONVE_API_URL;
  const url = `${host}/objectcounter/reports/`;

  return getDataWithPagination(url, params, rootGetters);
}

export default {
  getReports,
};
