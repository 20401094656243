// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_FACEID_OCCURRENCES_SUCCESS: 'GET_FACEID_OCCURRENCES_SUCCESS',
  GET_FACEID_OCCURRENCE_SUCCESS: 'GET_FACEID_OCCURRENCE_SUCCESS',

  PATCH_FACEID_OCCURRENCE_SUCCESS: 'PATCH_FACEID_OCCURRENCE_SUCCESS',

  DOWNLOAD_FACEID_OCCURRENCES_REQUEST: 'DOWNLOAD_FACEID_OCCURRENCES_REQUEST',
  DOWNLOAD_FACEID_OCCURRENCES_SUCCESS: 'DOWNLOAD_FACEID_OCCURRENCES_SUCCESS',
  DOWNLOAD_FACEID_OCCURRENCES_FAILURE: 'DOWNLOAD_FACEID_OCCURRENCES_FAILURE',
};

interface Notification {
  id: string;
}

export interface OccurrenceFiles {
  name: string;
  url: string;
}

export interface OccurrencePreview {
  id: string;
  notification: { id: string };
}

export interface OccurrencePreviewPage {
  total: number;
  items: Array<OccurrencePreview>;
}

export interface OccurrenceFilterSettings {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortOrder: string;
  search?: string;
  theftStatus: string | undefined;
  inTrash?: boolean;
  occurrenceTags: string[];
  noOccurrenceTags: boolean;
  peopleTags: string[];
  noPeopleTags: boolean;
  unitId?: string;
  unitName?: string;
  personId?: string;
  personName?: string;
  videoName?: string;
  about?: string;
  createdDateOption?: string;
  createdAfter?: string;
  createdBefore?: string;
  locale?: string;
}

export interface History {
  action: string;
  created_at: string;
  created_by: string;
}

interface Tag {
  id: string;
}

interface Person {
  id: string;
}

interface Unit {
  id: string;
}

export interface Occurrence {
  id: string;
  name: string;
  created_at: string;
  about: string;
  notifications: Notification[];
  time_line: string;
  people: Person[];
  theft_status: string;
  updated_at: string;
  expire_mode?: string;
  expire_at?: string;
  files: OccurrenceFiles[];
  value_of_loss: number;
  value_of_prevention: number;
  unit: Unit;
  tags: Tag[];
  history: History[];
  there_was_an_arrest: boolean;
  cops_were_called: boolean;
  requestedAtMs: number;
  areTheNotificationsIdsReady: boolean;
  is_finished: boolean;
}

export interface OccurrencesDownload {
  isLoading: boolean;
  error?: AxiosError;
}

export interface OccurrenceState {
  occurrences: Occurrence[];
  occurrencePreviewPage: OccurrencePreviewPage;
  occurrenceIteratorSettings?: OccurrenceFilterSettings;
  occurrencesDownloadState: OccurrencesDownload;
}
