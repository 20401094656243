// Copyright (C) 2024 Deconve Technology. All rights reserved.

import axios, { AxiosRequestConfig } from 'axios';

interface AuthRequest {
  ClientId: string;
  AuthFlow: string;
  AuthParameters: {
    REFRESH_TOKEN: string;
    DEVICE_KEY: string;
  };
  user_id?: string;
  workspace_id?: string;
}

export function setUserCredentials(
  refreshToken: string, deviceKey: string, userId: string, workspaceId: string,
): void {
  const clientId = process.env.VUE_APP_AWS_COGNITO_USER_POOL_CLIENT_ID;
  let storageBaseKey = `CognitoIdentityServiceProvider.${clientId}`;

  if (userId) localStorage.setItem(`${storageBaseKey}.LastAuthUser`, userId);

  storageBaseKey = `${storageBaseKey}.${userId}`;
  if (refreshToken) localStorage.setItem(`${storageBaseKey}.refreshToken`, refreshToken);
  if (deviceKey) localStorage.setItem(`${storageBaseKey}.deviceKey`, deviceKey);
  if (userId) localStorage.setItem(`${storageBaseKey}.user_id`, userId);
  if (workspaceId) localStorage.setItem(`${storageBaseKey}.workspace_id`, workspaceId);
}

export function removeUserCredentials(): Promise<void> {
  return new Promise((resolve) => {
    const clientId = process.env.VUE_APP_AWS_COGNITO_USER_POOL_CLIENT_ID;
    let storageBaseKey = `CognitoIdentityServiceProvider.${clientId}`;
    const userId = localStorage.getItem(`${storageBaseKey}.LastAuthUser`);

    localStorage.removeItem(`${storageBaseKey}.LastAuthUser`);

    storageBaseKey = `${storageBaseKey}.${userId}`;
    const refreshToken = localStorage.getItem(`${storageBaseKey}.refreshToken`);

    if (!refreshToken) {
      resolve();
      return;
    }

    localStorage.removeItem(`${storageBaseKey}.refreshToken`);
    localStorage.removeItem(`${storageBaseKey}.deviceKey`);
    localStorage.removeItem(`${storageBaseKey}.user_id`);
    localStorage.removeItem(`${storageBaseKey}.workspace_id`);

    const authUrl = process.env.VUE_APP_DECONVE_AUTH_URL.replace('/v1', '/v2');

    const data = {
      // eslint-disable-next-line @typescript-eslint/camelcase
      refresh_token: refreshToken,
      // eslint-disable-next-line @typescript-eslint/camelcase
      client_id: clientId,
    };

    const requestOptions: AxiosRequestConfig = {
      method: 'post',
      baseURL: authUrl,
      url: '/revoke/',
      data,
    };

    axios(requestOptions).then(() => resolve()).catch(() => resolve());
  });
}

// To avoid ciclical depedency, we request another here
export function getAccessToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    const clientId = process.env.VUE_APP_AWS_COGNITO_USER_POOL_CLIENT_ID;

    let storageBaseKey = `CognitoIdentityServiceProvider.${clientId}`;
    const lastCognitoUserId = localStorage.getItem(`${storageBaseKey}.LastAuthUser`);

    if (!lastCognitoUserId) {
      reject();
      return;
    }

    storageBaseKey = `${storageBaseKey}.${lastCognitoUserId}`;

    const refreshToken = localStorage.getItem(`${storageBaseKey}.refreshToken`);
    const deviceKey = localStorage.getItem(`${storageBaseKey}.deviceKey`);
    const userId = localStorage.getItem(`${storageBaseKey}.user_id`);
    const workspaceId = localStorage.getItem(`${storageBaseKey}.workspace_id`);

    if (!refreshToken || !deviceKey) {
      reject();
      return;
    }

    const data: AuthRequest = {
      ClientId: clientId,
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
        DEVICE_KEY: deviceKey,
      },
    };

    if (userId && workspaceId) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      data.user_id = userId;
      // eslint-disable-next-line @typescript-eslint/camelcase
      data.workspace_id = workspaceId;
    }

    const authUrl = process.env.VUE_APP_DECONVE_AUTH_URL.replace('/v1', '/v2');

    const requestOptions: AxiosRequestConfig = {
      method: 'post',
      baseURL: authUrl,
      url: '/oauth2/token/',
      headers: {
        'content-type': 'application/x-amz-json-1.1',
        'x-amz-target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      },
      data,
    };

    axios(requestOptions).then((response) => {
      const { data: responseData } = response;
      // The authorization token pattern is 'Bearer token'. Any whitespace in the start or end
      // breaks the authorization token
      const authorizationToken = `${responseData.token_type} ${responseData.access_token}`;

      resolve(authorizationToken);
    }).catch(() => reject());
  });
}

export function logInAnotherWorkspace(workspaceId: string): void {
  const clientId = process.env.VUE_APP_AWS_COGNITO_USER_POOL_CLIENT_ID;
  const storageBaseKey = `CognitoIdentityServiceProvider.${clientId}`;
  const lastCognitoUserId = localStorage.getItem(`${storageBaseKey}.LastAuthUser`);

  if (lastCognitoUserId) {
    localStorage.setItem(`${storageBaseKey}.${lastCognitoUserId}.workspace_id`, workspaceId);
  }

  window.location.reload();
}
