// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import { VideosState, types } from './types';
import { RootState } from '../../types';

export const actions: ActionTree<VideosState, RootState> = {
  fetchVideos({ commit, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      const host = process.env.VUE_APP_DECONVE_PEOPLE_COUNTER_API_URL
      || process.env.VUE_APP_DECONVE_API_URL;

      const requestOptions: AxiosRequestConfig = {
        method: 'get',
        baseURL: host,
        url: '/peoplecounter/videos/',
        headers: {
          Authorization: rootGetters.authorizationToken,
        },
      };

      axios(requestOptions).then((response) => {
        const { data } = response;

        commit(types.GET_PEOPLE_COUNTER_VIDEOS_SUCCESS, data);
        resolve();
      }).catch((error) => reject(error));
    });
  },
};

export default actions;
