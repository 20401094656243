// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_FACEID_PERSON_FILE_REQUEST: 'GET_FACEID_PERSON_FILE_REQUEST',
  GET_FACEID_PERSON_FILE_SUCCESS: 'GET_FACEID_PERSON_FILE_SUCCESS',

  RESET_FACEID_PERSON_FILES: 'RESET_FACEID_PERSON_FILES',
  SET_FACEID_PERSON_ID_FILES_REQUESTED: 'SET_FACEID_PERSON_ID_FILES_REQUESTED',
};

export interface PersonFile {
  originalFileUrl: string;
  originalName: string;
  originalFile: string;
  fileIsBeingLoaded: boolean;
  error?: AxiosError;
}

export interface PersonFilesState {
  personId: string;
  files: PersonFile[];
}
