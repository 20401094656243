// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { Tag, TagState, types } from './types';

export const mutations: MutationTree<TagState> = {
  [types.GET_TAGS_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.GET_TAGS_SUCCESS](state, tags: Tag[]) {
    state.tags = tags;
    state.isLoading = false;
  },
  [types.GET_TAGS_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.CREATE_TAG_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.CREATE_TAG_SUCCESS](state) {
    state.isLoading = false;
  },
  [types.CREATE_TAG_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.DELETE_TAG_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.DELETE_TAG_SUCCESS](state) {
    state.isLoading = false;
  },
  [types.DELETE_TAG_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
};

export default mutations;
