// Copyright (C) 2023 Deconve Technology. All rights reserved.

import moment from 'moment';
import { getDemoNotifications, getDemoNotification } from '../notifications/demo';
import { Notification } from '../notifications/types';

import { Report, ReportPage, Statistics } from './types';

const reports: Report[] = [];

// uses the Box-Muller transform to generate random numbers from a Gaussian distribution
function generateGaussianRandom(mean: number, standardDeviation: number) {
  let u1;
  let u2;
  let s;

  do {
    u1 = Math.random() * 2 - 1;
    u2 = Math.random() * 2 - 1;
    s = u1 * u1 + u2 * u2;
  } while (s >= 1 || s === 0);

  const v1 = u1 * Math.sqrt((-2 * Math.log(s)) / s);

  // Adjust the mean and standard deviation
  const randomValue = v1 * standardDeviation + mean;

  return randomValue;
}

export function setNotificationReports(): void {
  const localTimeZone = -3;
  const endDate = moment.utc().startOf('day').add(-localTimeZone);
  const startDate = moment(endDate).add(-31, 'days');

  const people = [
    '00000000-0000-0000-0000-000000000000',
    '00000000-0000-0000-0000-000000000001',
    '00000000-0000-0000-0000-000000000002',
  ];

  const videos = [
    '00000000-0000-0000-0000-000000000000',
    '00000000-0000-0000-0000-000000000001',
    '00000000-0000-0000-0000-000000000002',
  ];

  while (startDate.isSameOrBefore(endDate)) {
    const createdAt = startDate.toISOString();
    const isLastDay = startDate.isSameOrAfter(endDate);

    people.forEach((personId) => {
      videos.forEach((videoId) => {
        const positive = Math.round(Math.random() * 3);
        const negative = Math.round(Math.random());
        const unreviewed = isLastDay ? Math.round(Math.random() * 1) : 0;
        const delay = 15 + Math.round(Math.abs(generateGaussianRandom(10, 20)));

        const report: Report = {
          person: { id: personId },
          video: { id: videoId },
          positive,
          negative,
          unreviewed,
          total: positive + negative + unreviewed,
          // eslint-disable-next-line @typescript-eslint/camelcase
          created_at: createdAt,
          // eslint-disable-next-line @typescript-eslint/camelcase
          local_time_zone: localTimeZone,
          // eslint-disable-next-line @typescript-eslint/camelcase
          delay_in_seconds: delay,
        };

        reports.push(report);
      });
    });

    startDate.add(1, 'day');
  }
}

export function getDemoPersonNotificationStatistics(personId: string): Promise<Statistics> {
  return new Promise((resolve) => {
    getDemoNotifications().then(({ items }) => {
      const promises: Promise<Notification>[] = [];

      items.forEach((preview) => {
        const { id, person } = preview;

        if (person.id === personId) {
          promises.push(getDemoNotification(id));
        }
      });

      Promise.all(promises).then((notifications) => {
        const stats: Statistics = {
          positive: 0, negative: 0, unreviewed: 0, total: notifications.length,
        };

        notifications.forEach((notification) => {
          const { is_same_person: reviewStatus } = notification;

          switch (reviewStatus) {
            case 'yes':
              stats.positive += 1;
              break;
            case 'no':
              stats.negative += 1;
              break;
            default:
              stats.unreviewed += 1;
              break;
          }
        });

        resolve(stats);
      });
    });
  });
}

export function getDemoNotificationStatistics(): Promise<Statistics> {
  return new Promise((resolve) => {
    if (reports.length === 0) setNotificationReports();

    const stats: Statistics = {
      positive: 0, negative: 0, unreviewed: 0, total: 0,
    };

    reports.forEach((report) => {
      const { positive, negative, unreviewed } = report;

      stats.positive += positive || 0;
      stats.positive += negative || 0;
      stats.unreviewed += unreviewed || 0;
    });

    stats.total = stats.positive + stats.negative + stats.unreviewed;
    resolve(stats);
  });
}

function getReportsGroupedByPerson(): Report[] {
  const reportByPerson: Record<string, Report> = {};

  reports.forEach((report) => {
    const {
      person, positive, negative, unreviewed,
    } = report;
    const { id: personId } = person as { id: string};

    if (personId in reportByPerson) {
      const r = reportByPerson[personId];

      r.positive = (r.positive || 0) + (positive || 0);
      r.negative = (r.negative || 0) + (negative || 0);
      r.unreviewed = (r.unreviewed || 0) + (unreviewed || 0);
    } else {
      reportByPerson[personId] = {
        positive,
        negative,
        unreviewed,
        person,
      };
    }
  });

  const output: Report [] = [];

  Object.keys(reportByPerson).forEach((key) => {
    const r = reportByPerson[key];

    r.total = (r.positive || 0) + (r.negative || 0) + (r.unreviewed || 0);
    output.push(r);
  });

  return output;
}

function getReportsGroupedByVideo(): Report[] {
  const reportByVideo: Record<string, Report> = {};

  reports.forEach((report) => {
    const {
      video, positive, negative, unreviewed,
    } = report;
    const { id: videoId } = video as { id: string};

    if (videoId in reportByVideo) {
      const r = reportByVideo[videoId];

      r.positive = (r.positive || 0) + (positive || 0);
      r.negative = (r.negative || 0) + (negative || 0);
      r.unreviewed = (r.unreviewed || 0) + (unreviewed || 0);
    } else {
      reportByVideo[videoId] = {
        positive,
        negative,
        unreviewed,
        video,
      };
    }
  });

  const output: Report [] = [];

  Object.keys(reportByVideo).forEach((key) => {
    const r = reportByVideo[key];

    r.total = (r.positive || 0) + (r.negative || 0) + (r.unreviewed || 0);
    output.push(r);
  });

  return output;
}

function getReportsGroupedByDay(): Report[] {
  const reportByVideo: Record<string, Report> = {};

  reports.forEach((report) => {
    const {
      video, positive, negative, unreviewed, created_at: createdAt, local_time_zone: localTimeZone,
    } = report;
    const { id: videoId } = video as { id: string};
    const key = `${videoId}-${createdAt}`;

    if (key in reportByVideo) {
      const r = reportByVideo[key];

      r.positive = (r.positive || 0) + (positive || 0);
      r.negative = (r.negative || 0) + (negative || 0);
      r.unreviewed = (r.unreviewed || 0) + (unreviewed || 0);
    } else {
      reportByVideo[key] = {
        positive,
        negative,
        unreviewed,
        video,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_at: createdAt,
        // eslint-disable-next-line @typescript-eslint/camelcase
        local_time_zone: localTimeZone,
      };
    }
  });

  const output: Report [] = [];

  Object.keys(reportByVideo).forEach((key) => {
    const r = reportByVideo[key];

    r.total = (r.positive || 0) + (r.negative || 0) + (r.unreviewed || 0);
    output.push(r);
  });

  return output;
}

function getReportsGroupedByNotificationDelay(): Report[] {
  const reportByVideo: Record<string, Report> = {};

  reports.forEach((report) => {
    const {
      video, positive, negative, unreviewed, delay_in_seconds: delay,
    } = report;
    const { id: videoId } = video as { id: string};
    const key = `${videoId}-${delay}`;

    if (key in reportByVideo) {
      const r = reportByVideo[key];

      r.positive = (r.positive || 0) + (positive || 0);
      r.negative = (r.negative || 0) + (negative || 0);
      r.unreviewed = (r.unreviewed || 0) + (unreviewed || 0);
    } else {
      reportByVideo[key] = {
        positive,
        negative,
        unreviewed,
        video,
        // eslint-disable-next-line @typescript-eslint/camelcase
        delay_in_seconds: delay,
      };
    }
  });

  const output: Report [] = [];

  Object.keys(reportByVideo).forEach((key) => {
    const r = reportByVideo[key];

    r.total = (r.positive || 0) + (r.negative || 0) + (r.unreviewed || 0);
    output.push(r);
  });

  return output;
}

export function getDemoNotificationReports(groupBy: string): Promise<ReportPage> {
  return new Promise((resolve) => {
    if (reports.length === 0) setNotificationReports();

    let items: Report[] = [];

    if (groupBy === 'video') {
      items = getReportsGroupedByVideo();
    } else if (groupBy === 'person') {
      items = getReportsGroupedByPerson();
    } else if (groupBy === 'day') {
      items = getReportsGroupedByDay();
    } else {
      items = getReportsGroupedByNotificationDelay();
    }

    const data: ReportPage = {
      items,
      total: items.length,
      hasMore: false,
    };

    resolve(data);
  });
}
