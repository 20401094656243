// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import { MonitoredUnits, UnitsState } from './types';

export const getters: GetterTree<UnitsState, RootState> = {
  getReferenceUnitsIds(state): string[] | [] {
    const referenceUnits = state.monitoredUnits?.referenceUnits?.items;

    if (!referenceUnits) {
      return [];
    }

    return referenceUnits.map(({ unit }) => unit.id);
  },
  getNearbyUnitsIds(state): string[] | [] {
    const nearbyUnits = state.monitoredUnits?.nearbyUnits?.items;

    if (!nearbyUnits) {
      return [];
    }

    return nearbyUnits.map(({ unit }) => unit.id);
  },
  hasMoreReferenceUnits(state): boolean | undefined {
    return state.monitoredUnits?.referenceUnits?.hasMore;
  },
  hasMoreNearbyUnits(state): boolean | undefined {
    return state.monitoredUnits?.nearbyUnits?.hasMore;
  },
  isMonitoringUnitsLoading(state): boolean {
    return state.monitoredUnits?.isLoading;
  },
  getMonitoredReferenceUnits(state): MonitoredUnits[] | undefined {
    return state.monitoredUnits.referenceUnits?.items;
  },
};

export default getters;
