// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { NotificationReportsState, types } from './types';

export const mutations: MutationTree<NotificationReportsState> = {
  [types.GET_FACEID_NOTIFICATIONS_STATISTICS_REQUEST](state) {
    state.notificationStatistics.isLoading = true;
  },
  [types.GET_FACEID_NOTIFICATIONS_STATISTICS_SUCCESS](state, data) {
    state.notificationStatistics.isLoading = false;
    state.notificationStatistics.data = data;
  },
  [types.GET_FACEID_NOTIFICATIONS_STATISTICS_FAILURE](state) {
    state.notificationStatistics.isLoading = false;
  },
  [types.GET_FACEID_NOTIFICATIONS_REPORTS_REQUEST](state) {
    state.notificationReports.isLoading = true;
  },
  [types.GET_FACEID_NOTIFICATIONS_REPORTS_SUCCESS](state, data) {
    state.notificationReports.isLoading = false;
    state.notificationReports.data = data;
  },
  [types.GET_FACEID_NOTIFICATIONS_REPORTS_FAILURE](state) {
    state.notificationReports.isLoading = false;
  },
  [types.GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_REQUEST](state) {
    state.notificationReportsDelay.isLoading = true;
  },
  [types.GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_SUCCESS](state, data) {
    state.notificationReportsDelay.isLoading = false;
    state.notificationReportsDelay.data = data;
  },
  [types.GET_FACEID_NOTIFICATIONS_REPORTS_BY_NOTIFICATION_DELAY_FAILURE](state) {
    state.notificationReportsDelay.isLoading = false;
  },
  [types.GET_FACEID_NOTIFICATION_VIDEO_SOURCES_REQUEST](state) {
    state.notificationVideoSources.isLoading = true;
  },
  [types.GET_FACEID_NOTIFICATION_VIDEO_SOURCES_SUCCESS](state, data) {
    state.notificationVideoSources.isLoading = false;
    state.notificationVideoSources.data = data;
  },
  [types.GET_FACEID_NOTIFICATION_VIDEO_SOURCES_FAILURE](state) {
    state.notificationVideoSources.isLoading = false;
  },
  [types.DOWNLOAD_FACEID_REPORTS_REQUEST](state) {
    state.downloadReportsState.isLoading = true;
  },
  [types.DOWNLOAD_FACEID_REPORTS_SUCCESS](state) {
    state.downloadReportsState.isLoading = false;
  },
  [types.DOWNLOAD_FACEID_REPORTS_FAILURE](state, error) {
    state.downloadReportsState.isLoading = false;
    state.downloadReportsState.error = error;
  },
};

export default mutations;
