// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { PersonImage, PersonImagesState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<PersonImagesState, RootState> = {
  personImages(state): PersonImage[] {
    return state.images;
  },
  personImagesNumber(state): number {
    return state.images.length;
  },
  personImagesId(state): string {
    return state.personId;
  },
  getPersonProfileImage(state): string {
    return state.personProfileImage;
  },
  personImageInfoByIndex: (state) => (index: number) => (
    index < state.images.length ? state.images[index]?.info : undefined
  ),
  imageIsBeingLoaded: (state) => (index: number) => (
    index < state.images.length ? state.images[index].imageIsBeingLoaded : false
  ),
  facesAreBeingLoaded: (state) => (index: number) => (
    index < state.images.length ? state.images[index]?.facesAreBeingLoaded : false
  ),
};

export default getters;
