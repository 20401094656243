// Copyright (C) 2021 Deconve Technology. All rights reserved.

export const NOT_ACCEPTED_RESOLUTION_IMAGE = 'not accepted';
export const LOW_RESOLUTION_IMAGE = 'low';
export const GOOD_RESOLUTION_IMAGE = 'good';

export default function imageResolutionScore(width: number, height: number): string {
  if (width >= 112 && height >= 112) {
    return GOOD_RESOLUTION_IMAGE;
  }

  if (width >= 64 && height >= 64) {
    return LOW_RESOLUTION_IMAGE;
  }

  return NOT_ACCEPTED_RESOLUTION_IMAGE;
}
