// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { Socket } from 'socket.io-client';
import { SocketState, types } from './types';

export const mutations: MutationTree<SocketState> = {
  [types.SOCKET_INIT_CONNECTION](state, socket: Socket): void {
    state.socket = socket;
  },
  [types.SOCKET_CLOSE_CONNECTION](state): void {
    if (state.socket) {
      state.socket.disconnect();
      state.socket = undefined;
      state.event = undefined;
    }
  },
  [types.SOCKET_ON_EVENT_MESSAGES](state, message: object): void {
    state.event = message;
  },
};

export default mutations;
