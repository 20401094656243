// Copyright (C) 2020 Deconve Technology. All rights reserved.

export const types: { [id: string]: string } = {
  GET_FACEID_NOTIFICATIONS_SUCCESS: 'GET_FACEID_NOTIFICATIONS_SUCCESS',
  GET_FACEID_NOTIFICATION_SUCCESS: 'GET_FACEID_NOTIFICATION_SUCCESS',
  DELETE_FACEID_NOTIFICATION_SUCCESS: 'DELETE_FACEID_NOTIFICATION_SUCCESS',
  PUT_FACEID_NOTIFICATION_SUCCESS: 'PUT_FACEID_NOTIFICATION_SUCCESS',
};

interface Tag {
  id: string;
}

export interface NotificationPreview {
  id: string;
  video: { id: string };
  person: { id: string };
}

export interface FaceFrameInfo {
  created_at: string;
  face_bounding_box: {
    top_left_x: number;
    top_left_y: number;
    width: number;
    height: number;
  };
  face_image: {
    original: string;
    original_url: string;
    thumbnail: string;
    thumbnail_url: string;
  };
  video_frame: {
    original: string;
    original_url: string;
    thumbnail: string;
    thumbnail_url: string;
    medium_quality: string;
    medium_quality_url: string;
    original_width: number;
    original_height: number;
  };
}

export interface History {
  action: string;
  created_at: string;
  created_by: string;
}

export interface Notification {
  id: string;
  workspace: { id: string };
  video: { id: string };
  created_at: string;
  local_time_zone: number;
  updated_at: string;
  expire_mode?: string;
  expire_at?: string;
  person: { id: string };
  visible_time_ms: number;
  best_face_index: number;
  is_same_person: string;
  received_at: string;
  about: string;
  faces: FaceFrameInfo[];
  tags: Tag[];
  history: History[];
  is_from_a_shared_person: boolean;
  requestedAtMs: number;
  areTheImageUrlsReady: boolean;
}

export interface NotificationPreviewPage {
  total: number;
  items: Array<NotificationPreview>;
}

export interface NotificationFilterSettings {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortOrder: string;
  search?: string;
  reviewStatus?: string | undefined;
  inTrash?: boolean;
  notificationTags: string[];
  noNotificationTags: boolean;
  peopleTags: string[];
  noPeopleTags: boolean;
  personId?: string;
  personName?: string;
  unitId?: string;
  unitName?: string;
  createdDateOption?: string;
  createdAfter?: string;
  createdBefore?: string;
}

export interface NotificationState {
  notificationPreviewPage: NotificationPreviewPage;
  notifications: Notification[];
  notificationIteratorSettings?: NotificationFilterSettings;
}
