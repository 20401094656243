// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { AxiosError } from 'axios';
import {
  MonitoredNearbyUnitsPreview as NearbyUnits,
  MonitoredReferenceUnitsPreview as ReferenceUnits,
  types, UnitsState,
} from './types';

export const mutations: MutationTree<UnitsState> = {
  [types.GET_FACEID_PEOPLE_MONITORING_UNITS_REQUEST](state) {
    state.monitoredUnits.isLoading = true;
  },
  [types.GET_FACEID_PEOPLE_REFERENCE_MONITORING_UNITS_SUCCESS](state, data: ReferenceUnits) {
    state.monitoredUnits.isLoading = false;
    state.monitoredUnits.referenceUnits = data;
  },
  [types.GET_FACEID_PEOPLE_NEARBY_MONITORING_UNITS_SUCCESS](state, data: NearbyUnits) {
    state.monitoredUnits.isLoading = false;
    state.monitoredUnits.nearbyUnits = data;
  },
  [types.GET_FACEID_PEOPLE_MONITORING_UNITS_ERROR](state, data: AxiosError) {
    state.monitoredUnits.isLoading = false;
    state.monitoredUnits.error = data;
  },
};

export default mutations;
