<template>
  <div>
    <v-snackbar
      v-model="showWhatsNew"
      top
      centered
    >
      {{ $t('deconve.discoverWhatsNew') }}
      <template #action="{ attrs }">
        <v-btn
          text
          color="primary"
          v-bind="attrs"
          @click.stop="toggleCard"
        >
          {{ $t('deconve.viewMore') }}
        </v-btn>
        <v-btn
          icon
          class="ml-4"
          @click="closeSnackbar"
        >
          <v-icon color="white">
            {{ icons.close }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-card
      v-if="showCard"
      class="card-container"
    >
      <div
        v-for="(versionTitle, index) in versions"
        :key="versionTitle"
      >
        <v-card-title>
          {{ $t('deconve.versions')[versionTitle].title }}
          <v-spacer />
          <square-button
            v-if="index === 0"
            menu-disabled
            :icon-name="icons.close"
            outlined
            color="transparentBackground"
            style="margin-top: auto"
            icon-color="neutral"
            @clicked="showCard = false"
          />
        </v-card-title>
        <v-card-text>
          <ul>
            <li
              v-for="feature in $t('deconve.versions')[versionTitle].changes"
              :key="feature.name"
            >
              <strong>{{ feature.name }}:</strong> {{ feature.description }}
            </li>
          </ul>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mdiClose } from '@mdi/js';
import SquareButton from './SquareButton.vue';

export default {
  name: 'WhatsNewNotifier',
  components: {
    SquareButton,
  },
  data() {
    return {
      icons: { close: mdiClose },
      showWhatsNew: false,
      showCard: false,
    };
  },
  computed: {
    versions() {
      const versionsTranslated = this.$t('deconve.versions', { returnObjects: true });
      const keys = Object.keys(versionsTranslated).sort().reverse();

      return keys;
    },
  },
  created() {
    const hasNewAppVersion = JSON.parse(localStorage.getItem('newAppVersion'));

    if (hasNewAppVersion) {
      this.showWhatsNew = true;
      setTimeout(() => {
        this.closeSnackbar();
      }, 5000);
    }
  },
  methods: {
    toggleCard() {
      this.showCard = !this.showCard;
      this.showWhatsNew = !this.showWhatsNew;
      localStorage.removeItem('newAppVersion');
    },
    closeSnackbar() {
      this.showWhatsNew = false;
      localStorage.removeItem('newAppVersion');
    },
    open() {
      this.closeSnackbar();
      this.showCard = true;
    },
  },
};
</script>

<style>
.card-container {
  width: 400px;
  max-height: 480px;
  overflow-y: auto;
  position: absolute !important;
  right: 10px;
  bottom: 200px;
}

@media (max-width: 660px) {
  .card-container {
    max-height: 200px;
    max-width: 260px !important;
    bottom: 10px;
  }
}

@media (max-width: 300px) {
  .card-container {
    max-height: 500px;
    max-width: 260px !important;
    bottom: 50px;
  }
}

.v-snack__wrapper {
  min-width: 270px !important;
}
</style>
