// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { CampaignState, types } from './types';

export const mutations: MutationTree<CampaignState> = {
  [types.GET_CAMPAIGNS_REQUEST](state) {
    state.isLoadingCampaigns = true;
  },
  [types.GET_CAMPAIGNS_SUCCESS](state, data) {
    state.campaigns = data;
    state.isLoadingCampaigns = false;
  },
  [types.GET_CAMPAIGNS_FAILURE](state, error) {
    state.isLoadingCampaigns = false;
    state.error = error;
  },
};

export default mutations;
