// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { PersonVideosState, types } from './types';

export const mutations: MutationTree<PersonVideosState> = {
  [types.GET_PERSON_VIDEO_REQUEST](state, data) {
    state.videos.push(data);
  },
  [types.GET_PERSON_VIDEO_SUCCESS](state, data) {
    const newStateIndex = state.videos.findIndex(
      (file) => file.originalName === data.originalName,
    );

    if (newStateIndex !== -1) {
      state.videos.splice(newStateIndex, 1, data);
    }
  },
  [types.GET_PERSON_VIDEO_FAILURE](state, data) {
    const newStateIndex = state.videos.findIndex(
      (file) => file.originalName === data.originalName,
    );

    if (newStateIndex !== -1) {
      state.videos.splice(newStateIndex, 1, data);
    }
  },
  [types.LOAD_PERSON_VIDEO_SUCCESS](state, data) {
    state.videos.push(data);
  },
  [types.EDIT_PERSON_VIDEO_REQUEST](state, index) {
    if (index < state.videos.length) {
      state.videos[index].videoIsBeingLoaded = true;
    }
  },
  [types.EDIT_PERSON_VIDEO_SUCCESS](state, { data, index }) {
    if (index < state.videos.length) {
      Object.assign(state.videos[index],
        { ...data, videoIsBeingLoaded: false });
    }
  },
  [types.EDIT_PERSON_VIDEO_FAILURE](state, { error, index }) {
    if (index < state.videos.length) {
      state.videos[index].error = error;
      state.videos[index].videoIsBeingLoaded = false;
    }
  },
  [types.DELETE_PERSON_VIDEO_SUCCESS](state, data) {
    state.videos = data;
  },
  [types.RESET_FACEID_PERSON_VIDEOS](state) {
    state.videos = [];
    state.personId = '';
  },
  [types.SET_PERSON_ID_VIDEO_REQUESTED](state, personId) {
    state.personId = personId;
  },
};

export default mutations;
