// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Video } from '../videos/types';

export const types: { [id: string]: string } = {
  GET_PEOPLE_COUNTER_REPORTS_BY_DAY_SUCCESS: 'GET_PEOPLE_COUNTER_REPORTS_BY_DAY_SUCCESS',
  GET_PEOPLE_COUNTER_REPORTS_BY_HOUR_SUCCESS: 'GET_PEOPLE_COUNTER_REPORTS_BY_HOUR_SUCCESS',
  GET_PEOPLE_COUNTER_REPORTS_TOTAL_PEOPLE_SUCCESS: 'GET_PEOPLE_COUNTER_REPORTS_TOTAL_PEOPLE_SUCCESS',
  GET_PEOPLE_COUNTER_REPORTS_TOTAL_BY_VIDEO_SUCCESS: 'GET_PEOPLE_COUNTER_REPORTS_TOTAL_BY_VIDEO_SUCCESS',
};

export interface Statistics {
  positive: number;
  negative: number;
  unreviewed: number;
}

export interface DirectionData {
  total: number;
}

export interface Report {
  video: Video;
  created_at: string;
  local_time_zone: number;
  name: string;
  direction_in?: DirectionData;
  direction_out?: DirectionData;
  direction_undefined?: DirectionData;
}

export interface TotalByVideo {
  data?: number[];
  label: string[];
}

export interface ReportsState {
  reportsByDay: Report[][];
  reportsByHour: Report[][];
  totalByVideo: TotalByVideo;
  totalNumberOfPeople: number;
}
