// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { NotificationState, types } from './types';

export const mutations: MutationTree<NotificationState> = {
  [types.ENABLE_NOTIFICATION](state, isEnabled: boolean) {
    state.isEnabled = isEnabled;
  },
};

export default mutations;
