// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_FACEID_PEOPLE_MONITORING_UNITS_REQUEST: 'GET_FACEID_PEOPLE_MONITORING_UNITS_REQUEST',

  GET_FACEID_PEOPLE_REFERENCE_MONITORING_UNITS_SUCCESS: 'GET_FACEID_PEOPLE_REFERENCE_MONITORING_UNITS_SUCCESS',
  GET_FACEID_PEOPLE_NEARBY_MONITORING_UNITS_SUCCESS: 'GET_FACEID_PEOPLE_NEARBY_MONITORING_UNITS_SUCCESS',

  GET_FACEID_PEOPLE_MONITORING_UNITS_ERROR: 'GET_FACEID_PEOPLE_MONITORING_UNITS_ERROR',
};

export interface UnitId {
  id: string;
}

export interface MonitoredUnits {
  unit: UnitId;
  number_of_positive_notifications: number;
  created_at?: string;
  added: string;
}

export interface MonitoredReferenceUnitsPreview {
  items: MonitoredUnits[];
  total: number;
  hasMore: boolean;
}

export interface MonitoredNearbyUnitsPreview {
  items: MonitoredUnits[];
  total: number;
  hasMore: boolean;
}

export interface MonitoredUnitsData {
  referenceUnits?: MonitoredReferenceUnitsPreview;
  nearbyUnits?: MonitoredNearbyUnitsPreview;
  isLoading: boolean;
  error?: AxiosError;
}

export interface UnitsState {
  monitoredUnits: MonitoredUnitsData;
}
