// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';

export const types: { [id: string]: string } = {
  GET_FACEID_VIDEOS_REQUEST: 'GET_FACEID_VIDEOS_REQUEST',
  GET_FACEID_VIDEOS_SUCCESS: 'GET_FACEID_VIDEOS_SUCCESS',
  GET_FACEID_VIDEOS_FAILURE: 'GET_FACEID_VIDEOS_FAILURE',
};

export interface Video {
  id: string;
  name?: string;
}

export interface VideosState {
  requestCounter: number;
  videos: Video[];
  error?: AxiosError;
}
