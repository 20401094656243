// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

import { dataUrlToBlob } from '../../../utils/data';
import { RootState } from '../../types';
import {
  PersonVideo, types, PersonVideosState,
} from './types';

let getVideoRequests = [] as CancelTokenSource[];
let addVideoRequests = [] as CancelTokenSource[];

interface VideoInfo {
  name: string;
  file?: string;
  url?: string;
}

function addPersonVideo(authToken: string, personId: string, videoInfo: VideoInfo): Promise<void> {
  return new Promise((resolve, reject) => {
    dataUrlToBlob(videoInfo.file as string).then((fileBlob) => {
      const formData = new FormData();

      formData.append('video', fileBlob as Blob, videoInfo.name);

      const requestOptions: AxiosRequestConfig = {
        method: 'post',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/videos/`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: authToken,
        },
        data: formData,
      };

      axios(requestOptions)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  });
}

function updatePersonVideo(
  authToken: string, personId: string, videoInfo: VideoInfo,
): Promise<void> {
  return new Promise((resolve, reject) => {
    dataUrlToBlob(videoInfo.file as string).then((fileBlob) => {
      const formData = new FormData();

      formData.append('video', fileBlob as Blob, videoInfo.name);

      const requestOptions: AxiosRequestConfig = {
        method: 'patch',
        baseURL: process.env.VUE_APP_DECONVE_API_URL,
        url: `/faceid/people/${personId}/videos/`,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: authToken,
        },
        data: formData,
      };

      axios(requestOptions)
        .then(() => resolve())
        .catch((error) => reject(error));
    });
  });
}

function deletePersonVideo(
  authToken: string, personId: string, personVideoName: string,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const newParams = new URLSearchParams();

    newParams.append('video', personVideoName);

    const requestOptions: AxiosRequestConfig = {
      method: 'delete',
      baseURL: process.env.VUE_APP_DECONVE_API_URL,
      url: `/faceid/people/${personId}/videos/`,
      params: newParams,
      headers: { Authorization: authToken },
    };

    axios(requestOptions)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
}

export const actions: ActionTree<PersonVideosState, RootState> = {
  fetchPersonVideos({ commit, state }, { videos, personId }) {
    // On next version, we will use this method to get a video thumbnail. For now, we are
    // keeping the person image workflow
    // Cancel current file requests if the person id changed

    if (personId !== state.personId) {
      commit(types.RESET_FACEID_PERSON_VIDEOS);
      commit(types.SET_PERSON_ID_VIDEO_REQUESTED, personId);

      if (getVideoRequests.length > 0) {
        getVideoRequests.forEach((request) => {
          request.cancel();
        });
        getVideoRequests = [];
      }

      if (addVideoRequests.length > 0) {
        addVideoRequests.forEach((request) => {
          request.cancel();
        });
        addVideoRequests = [];
      }
    } else {
      return;
    }

    // Prepare the person file data with face info information to be requested
    const personVideos: PersonVideo[] = [];

    videos.forEach((file: VideoInfo) => {
      const personVideo: PersonVideo = {
        originalVideoUrl: file.url as string,
        originalName: file.name,
        originalVideo: '',
        videoIsBeingLoaded: true,
      };

      personVideos.push(personVideo);

      commit(types.GET_PERSON_VIDEO_REQUEST, personVideo);
    });

    personVideos.forEach((personFile) => {
      const cancelFileUrlRequest = axios.CancelToken.source();

      getVideoRequests.push(cancelFileUrlRequest);

      const PersonVideoData = { ...personFile };

      PersonVideoData.videoIsBeingLoaded = false;

      commit(types.GET_PERSON_VIDEO_SUCCESS, PersonVideoData);
    });
  },

  addPersonVideos({ rootGetters, dispatch }, { personId, videos }): Promise<void> {
    return new Promise((resolve, reject) => {
      const addVideoPromises: Promise<void>[] = [];

      videos.forEach((video: VideoInfo) => {
        addVideoPromises.push(
          addPersonVideo(rootGetters.authorizationToken, personId, video),
        );
      });

      Promise.all(addVideoPromises).then(() => {
        dispatch('resetPersonVideos');
        resolve();
      }).catch((error) => {
        reject(error);
      });
    });
  },

  editPersonVideos(
    { rootGetters, dispatch },
    { personId, videos, videosToBeDeleted },
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const deleteVideoPromises: Promise<void>[] = [];

      videosToBeDeleted.forEach((name: string) => {
        // The update person video operation overwrites video with same name on database. So, to
        // avoid too many requests, we delete only files that will be not overwritten.
        const index = videos.findIndex((video: VideoInfo) => name === video.name);

        if (index < 0) {
          deleteVideoPromises.push(
            deletePersonVideo(rootGetters.authorizationToken, personId, name),
          );
        }
      });

      Promise.all(deleteVideoPromises).then(() => {
        const updateVideoPromises: Promise<void>[] = [];

        videos.forEach((video: VideoInfo) => {
          updateVideoPromises.push(
            updatePersonVideo(rootGetters.authorizationToken, personId, video),
          );
        });

        Promise.all(updateVideoPromises).then(() => {
          dispatch('resetPersonVideos');
          resolve();
        }).catch((error) => reject(error));
      }).catch((error) => resolve(error));
    });
  },

  resetPersonVideos({ commit }) {
    commit(types.RESET_FACEID_PERSON_VIDEOS);
  },
};

export default actions;
