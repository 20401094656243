// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { VideosState } from './types';
import { RootState } from '../types';

export const state: VideosState = {
  videos: [],
};

const namespaced = true;

export const videos: Module<VideosState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
