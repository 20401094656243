// Copyright (C) 2021 Deconve Technology. All rights reserved.

import imageQualityScore, { GOOD_QUALITY_IMAGE, LOW_QUALITY_IMAGE, REGULAR_QUALITY_IMAGE } from '@/utils/imageQualityScore';
import imageResolutionScore, { GOOD_RESOLUTION_IMAGE, NOT_ACCEPTED_RESOLUTION_IMAGE, LOW_RESOLUTION_IMAGE } from '@/utils/imageResolutionScore';
import { MutationTree } from 'vuex';
import { PersonImagesState, types } from './types';

export const mutations: MutationTree<PersonImagesState> = {
  [types.GET_PERSON_IMAGE_REQUEST](state, data) {
    state.images.push(data);
  },
  [types.GET_PERSON_IMAGE_SUCCESS](state, data) {
    const newStateIndex = state.images.findIndex(
      (image) => image.id === data.id,
    );

    if (newStateIndex !== -1) {
      state.images.splice(newStateIndex, 1, data);
    }
  },
  [types.GET_PERSON_IMAGE_FAILURE](state, data) {
    const newStateIndex = state.images.findIndex(
      (image) => image.originalName === data.originalName,
    );

    if (newStateIndex !== -1) {
      state.images.splice(newStateIndex, 1, data);
    }
  },
  [types.ADD_PERSON_PROFILE_IMAGE](state, image) {
    state.personProfileImage = image;
  },
  [types.ADD_PERSON_IMAGE_INFO_REQUEST](state, index) {
    const newData = state.images.find((_, idx) => idx === index);

    if (newData) {
      newData.facesAreBeingLoaded = true;
      state.images.splice(index, 1, newData);
    }
  },
  [types.LOAD_FACEID_PERSON_IMAGE_SUCCESS](state, data) {
    state.images.push(data);
  },
  [types.ADD_PERSON_IMAGE_INFO_SUCCESS](state, { data, index }) {
    if (index < state.images.length) {
      state.images.splice(index, 1, data);
      state.images[index].facesAreBeingLoaded = false;
    }
  },
  [types.ADD_PERSON_IMAGE_INFO_FAILURE](state, index) {
    if (index < state.images.length) {
      state.images[index].facesAreBeingLoaded = false;
    }
  },
  [types.ADD_PERSON_IMAGE_INFO_SIMILAR_PEOPLE](state, { data, index }) {
    if (index < state.images.length) {
      const info = { ...state.images[index].info, similarPeople: data };

      Object.assign(state.images[index], { ...state.images[index], info });
    }
  },
  [types.EDIT_PERSON_IMAGE_REQUEST](state, index) {
    if (index < state.images.length) {
      state.images[index].imageIsBeingLoaded = true;
    }
  },
  [types.EDIT_PERSON_IMAGE_SUCCESS](state, { data, index }) {
    if (index < state.images.length) {
      Object.assign(state.images[index], { ...data, imageIsBeingLoaded: false });
    }
  },
  [types.EDIT_PERSON_IMAGE_FAILURE](state, { error, index }) {
    if (index < state.images.length) {
      state.images[index].error = error;
      state.images[index].imageIsBeingLoaded = false;
    }
  },
  [types.DELETE_PERSON_IMAGE_SUCCESS](state, data) {
    state.images = data;
  },
  [types.DETECT_FACEID_FACES_REQUEST](state, index) {
    if (index < state.images.length) {
      state.images[index].facesAreBeingLoaded = true;
    }
  },
  [types.DETECT_FACEID_FACES_SUCCESS](state, { data, index }) {
    if (index < state.images.length) {
      Object.assign(state.images[index], { ...data, facesAreBeingLoaded: false });
    }
  },
  [types.DETECT_FACEID_FACES_FAILURE](state, index) {
    if (index < state.images.length) {
      state.images[index].facesAreBeingLoaded = false;
    }
  },
  [types.RESET_FACEID_PERSON_IMAGES](state) {
    state.images = [];
    state.personId = '';
    state.personProfileImage = '';
  },
  [types.ADD_PERSONID](state, personId) {
    state.personId = personId;
  },
  [types.SORT_IMAGES_BY_QUALITY](state) {
    const data = state.images.slice();

    const sortedData = data.sort((a, b) => {
      if (a.info && b.info) {
        const aFaceSelected = a.info.faces[a.info.selected as number];
        const bFaceSelected = b.info.faces[b.info.selected as number];

        if (aFaceSelected && !bFaceSelected) {
          return -1;
        }

        if ((!aFaceSelected && bFaceSelected) || (!aFaceSelected && !bFaceSelected)) {
          return 1;
        }

        const aResolutionHeight = aFaceSelected?.bounding_box.height || 0;
        const aResolutionWidth = aFaceSelected?.bounding_box.width || 0;
        const bResolutionHeight = bFaceSelected?.bounding_box.height || 0;
        const bResolutionWidth = bFaceSelected?.bounding_box.width || 0;

        const aResolutionStatus = imageResolutionScore(aResolutionWidth, aResolutionHeight);
        const bResolutionStatus = imageResolutionScore(bResolutionWidth, bResolutionHeight);

        const aScore = imageQualityScore(aFaceSelected?.image_quality_score * 100 || 0);
        const bScore = imageQualityScore(bFaceSelected?.image_quality_score * 100 || 0);

        const aResolutionTotal = aResolutionWidth * aResolutionHeight;
        const bResolutionTotal = bResolutionWidth * bResolutionHeight;

        if (
          aResolutionStatus === NOT_ACCEPTED_RESOLUTION_IMAGE
          && bResolutionStatus !== NOT_ACCEPTED_RESOLUTION_IMAGE
        ) {
          return 1;
        }

        if (
          aResolutionStatus === LOW_RESOLUTION_IMAGE
          && bResolutionStatus === GOOD_RESOLUTION_IMAGE
        ) {
          return 1;
        }

        if ((
          aResolutionStatus === GOOD_RESOLUTION_IMAGE
          && bResolutionStatus !== GOOD_RESOLUTION_IMAGE) && (
          aScore === LOW_QUALITY_IMAGE
          && bScore !== LOW_QUALITY_IMAGE
        )
        ) {
          return 1;
        }

        if (
          aResolutionStatus === GOOD_RESOLUTION_IMAGE
          && bResolutionStatus !== GOOD_RESOLUTION_IMAGE
        ) {
          return -1;
        }

        if (
          aScore === LOW_QUALITY_IMAGE
          && bScore !== LOW_QUALITY_IMAGE
        ) {
          return 1;
        }

        if (
          aScore === REGULAR_QUALITY_IMAGE
          && bScore === GOOD_QUALITY_IMAGE
        ) {
          return 1;
        }

        if (
          aScore === GOOD_QUALITY_IMAGE
          && bScore !== GOOD_QUALITY_IMAGE
        ) {
          return -1;
        }

        if (
          aResolutionStatus === GOOD_RESOLUTION_IMAGE
          && bResolutionStatus === GOOD_RESOLUTION_IMAGE
          && aScore === GOOD_QUALITY_IMAGE
          && bScore === GOOD_QUALITY_IMAGE
        ) {
          return bResolutionTotal - aResolutionTotal;
        }

        if (
          aResolutionStatus === LOW_RESOLUTION_IMAGE
          && bResolutionStatus === LOW_RESOLUTION_IMAGE
          && aScore === LOW_QUALITY_IMAGE
          && bScore === LOW_QUALITY_IMAGE
        ) {
          return bResolutionTotal - aResolutionTotal;
        }

        if (
          ((aResolutionStatus === NOT_ACCEPTED_RESOLUTION_IMAGE
          && bResolutionStatus === NOT_ACCEPTED_RESOLUTION_IMAGE)
          || (aResolutionStatus === LOW_RESOLUTION_IMAGE
          && bResolutionStatus === LOW_RESOLUTION_IMAGE))
          && aScore === GOOD_QUALITY_IMAGE
          && bScore === GOOD_QUALITY_IMAGE
        ) {
          return bResolutionTotal - aResolutionTotal;
        }

        return -1;
      }

      if (a.info) {
        return -1;
      }

      return 1;
    });

    state.images = sortedData;
  },
};

export default mutations;
