// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { Video, VideosState, types } from './types';

export const mutations: MutationTree<VideosState> = {
  [types.GET_PEOPLE_COUNTER_VIDEOS_SUCCESS](state, videos: Video[]) {
    state.videos = videos;
  },
};

export default mutations;
