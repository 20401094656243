// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { RootState } from '../../types';

import { Campaign, CampaignState } from './types';

export const getters: GetterTree<CampaignState, RootState> = {
  isLoadingCampaigns(state): boolean {
    return state.isLoadingCampaigns;
  },
  getCampaign: (state) => (campaignId: string): Campaign | undefined => {
    const campaign = state.campaigns.find((p) => p.id === campaignId);

    return campaign;
  },
  campaigns: (state): Campaign[] => state.campaigns,
};

export default getters;
