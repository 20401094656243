// Copyright (C) 2021 Deconve Technology. All rights reserved.
import { GetterTree } from 'vuex';
import { AxiosError } from 'axios';
import { NotificationReportsState, NotificationReceiveTimeReport } from './types';
import { RootState, Dataset } from '../../types';

export const getters: GetterTree<NotificationReportsState, RootState> = {
  notificationStatistics: (state) => state.notificationStatistics.data,
  notificationReports: (state) => state.notificationReports.data.items,
  notificationReportsDelay(state) {
    const outputDatasets: Dataset[] = [];

    let total = 0;
    const maxTime = 120;
    let data = new Array(maxTime + 1).fill(0);
    const labels = Array.from(Array(maxTime + 1).keys()).map(String);

    labels[maxTime] = `+${maxTime}`;

    state.notificationReportsDelay.data.items.forEach(
      (ReportbyDelay: NotificationReceiveTimeReport) => {
        const { delay_in_seconds: delayInSeconds, total: totalData } = ReportbyDelay;

        // Ignore notifications stats without dalay time informed
        if (delayInSeconds !== null && delayInSeconds !== undefined) {
          const index = Math.min(delayInSeconds, maxTime);

          data[index] += totalData;
        }

        total += totalData;
      },
    );

    // Normalize the stats and keep at most two decimal places of precision
    // Number.EPSILON is used to ensure things like 1.005 round correctly
    data = data.map((value) => Math.round((value / total + Number.EPSILON) * 10000) / 100);

    outputDatasets.push({ label: '', data });

    const chartData = { labels, datasets: outputDatasets };

    return chartData;
  },
  notificationVideoSources: (state) => state.notificationVideoSources.data,
  notificationVideoIds: (state) => state.notificationVideoSources.data.map((video) => video.id),
  reportIsLoading: (state) => state.notificationReports.isLoading
  || state.notificationStatistics.isLoading
  || state.notificationVideoSources.isLoading,
  isNotificationReportsDelayLoading: (state) => state.notificationReportsDelay.isLoading
  || state.notificationVideoSources.isLoading,
  isDownloadingReports: (state) => state.downloadReportsState.isLoading,
  reportsDownloadError(state): AxiosError | undefined {
    return state.downloadReportsState.error;
  },
};

export default getters;
