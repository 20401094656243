// Copyright (C) 2022 Deconve Technology. All rights reserved.

import moment from 'moment';
import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import {
  ReportsState, Report, TotalByVideo, DirectionData,
} from './types';

interface DatasetItem {
  [date: string]: number;
}

export const getters: GetterTree<ReportsState, RootState> = {
  totalNumberOfPeople: (state): number => state.totalNumberOfPeople,
  totalNumberOfPeopleByVideo: (state): TotalByVideo => state.totalByVideo,
  reportByDayToChartData(state) {
    // first date and last date are used to create the chart data labels
    let firstValidDate: moment.Moment = moment(null);
    let lastValidDate: moment.Moment = moment(null);

    const dateFormat = 'YYYY/MM/DD';
    const dataByDate: DatasetItem = {};

    // Only this direction considered for now
    const direction = 'direction_in';

    state.reportsByDay.forEach((dataByVideo: Report[]) => {
      dataByVideo.forEach((item: Report) => {
        const { created_at: createdAt, local_time_zone: timeZone } = item;

        const directionData = item[direction] as DirectionData;

        const date = moment.utc(createdAt).add(timeZone, 'hours');

        if (!firstValidDate.isValid() || date.isBefore(firstValidDate)) {
          firstValidDate = moment(date);
        }

        if (!lastValidDate.isValid() || date.isAfter(lastValidDate)) {
          lastValidDate = moment(date);
        }

        const dateInString = date.format(dateFormat);

        let total = 0;

        if (directionData?.total) {
          total += directionData.total;
        }

        if (dateInString in dataByDate) {
          dataByDate[dateInString] += total;
        } else {
          dataByDate[dateInString] = total;
        }
      });
    });

    const labels: moment.Moment[] = [];
    const data: number[] = [];

    if (firstValidDate.isValid()) {
      while (firstValidDate.isSameOrBefore(lastValidDate)) {
        const dateLabel = firstValidDate.format(dateFormat);

        labels.push(moment(firstValidDate));

        data.push(dateLabel in dataByDate ? dataByDate[dateLabel] : 0);
        firstValidDate.add(1, 'day');
      }
    }

    return { labels, data };
  },
  reportByHourToChartData(state) {
    // first date and last date are used to create the chart data labels
    let firstValidDate: moment.Moment = moment(null);
    let lastValidDate: moment.Moment = moment(null);

    const dateFormat = 'YYYY/MM/DD HH';
    const dataByDate: DatasetItem = {};

    // Only this direction considered for now
    const direction = 'direction_in';

    state.reportsByHour.forEach((dataByVideo: Report[]) => {
      dataByVideo.forEach((item: Report) => {
        const { created_at: createdAt, local_time_zone: timeZone } = item;

        const directionData = item[direction] as DirectionData;

        const date = moment.utc(createdAt).add(timeZone, 'hours');

        if (!firstValidDate.isValid() || date.isBefore(firstValidDate)) {
          firstValidDate = moment(date);
        }

        if (!lastValidDate.isValid() || date.isAfter(lastValidDate)) {
          lastValidDate = moment(date);
        }

        const dateInString = date.format(dateFormat);

        let total = 0;

        if (directionData?.total) {
          total += directionData.total;
        }

        if (dateInString in dataByDate) {
          dataByDate[dateInString] += total;
        } else {
          dataByDate[dateInString] = total;
        }
      });
    });

    const labels: moment.Moment[] = [];
    const data: number[] = [];

    if (firstValidDate.isValid()) {
      while (firstValidDate.isSameOrBefore(lastValidDate)) {
        const dateLabel = firstValidDate.format(dateFormat);

        if (dateLabel in dataByDate) {
          labels.push(moment(firstValidDate));

          data.push(dataByDate[dateLabel]);
        }

        firstValidDate.add(1, 'hour');
      }
    }

    return { labels, data };
  },
};

export default getters;
