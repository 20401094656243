// Copyright (C) 2022 Deconve Technology. All rights reserved.

import moment from 'moment';
import { GetterTree } from 'vuex';
import { ReportsState, Report } from './types';
import { RootState } from '../../types';

interface DatasetItem {
  [date: string]: number;
}

interface DatasetInfo {
  videoId: string;
  objectName: string;
}

interface Dataset {
  label: string;
  data: (number | null)[];
}

export const getters: GetterTree<ReportsState, RootState> = {
  reportsByDay: (state) => state.reportsByDay.data,
  isLoadingReportsByDay: (state) => state.reportsByDay.isLoading,
  reportsByDayToChartData(state, _getters, rootState, rootGetters) {
    // we know the data is sorted by created_at date in ascending order
    let firstValidDate: moment.Moment = moment(null);
    let lastValidDate: moment.Moment = moment(null);
    const dateFormat = 'DD/MM/YYYY';

    const dataByVideoAndObject: Record<string, DatasetItem> = {};
    const datasetNames: Record<string, DatasetInfo> = {};

    state.reportsByDay.data.forEach((dataByVideo: Report[]) => {
      if (dataByVideo.length > 0) {
        let item = dataByVideo[0];
        const { created_at: createdAt, local_time_zone: timeZone } = item;

        if (firstValidDate.isValid()) {
          const date = moment.utc(createdAt).add(timeZone, 'hours');

          if (date.isBefore(firstValidDate)) {
            firstValidDate = date;
          }
        } else {
          firstValidDate = moment.utc(createdAt).add(timeZone, 'hours');
        }

        item = dataByVideo[dataByVideo.length - 1];
        const { created_at: lastCreatedAt, local_time_zone: lastTimeZone } = item;

        if (lastValidDate.isValid()) {
          const date = moment.utc(lastCreatedAt).add(lastTimeZone, 'hours');

          if (date.isAfter(lastValidDate)) {
            lastValidDate = date;
          }
        } else {
          lastValidDate = moment.utc(lastCreatedAt).add(lastTimeZone, 'hours');
        }
      }

      dataByVideo.forEach((report: Report) => {
        const {
          video, created_at: createdAt, local_time_zone: timeZone, name, direction_in: directionIn,
          direction_out: directionOut, direction_undefined: directionUndefined,
        } = report;

        const date = moment.utc(createdAt).add(timeZone, 'hours').format(dateFormat);
        const { id: videoId } = video;

        const key = `${videoId}-${name}`;

        let total = 0;

        total += directionIn?.total || 0;
        total += directionOut?.total || 0;
        total += directionUndefined?.total || 0;

        if (key in datasetNames) {
          dataByVideoAndObject[key][date] = total;
        } else {
          datasetNames[key] = { videoId, objectName: name };
          dataByVideoAndObject[key] = { [date]: total };
        }
      });
    });

    const labels: string[] = [];
    const datasets: Record<string, (number | null)[]> = {};

    Object.keys(datasetNames).forEach((key) => {
      datasets[key] = [];
    });

    if (firstValidDate.isValid()) {
      while (firstValidDate.isSameOrBefore(lastValidDate)) {
        const dateLabel = firstValidDate.format(dateFormat);

        labels.push(dateLabel);

        Object.keys(datasetNames).forEach((key) => {
          const data = dataByVideoAndObject[key];

          datasets[key].push(dateLabel in data ? data[dateLabel] : null);
        });

        firstValidDate.add(1, 'day');
      }
    }

    const outputDatasets: Dataset[] = [];
    const getVideoName = rootGetters['objectcounter/videoName'];

    // Sort the datasets to make sure the follow the same color order on dashboard
    const datasetKeys = Object.keys(datasetNames);

    datasetKeys.sort();
    datasetKeys.forEach((key) => {
      const { videoId, objectName } = datasetNames[key];
      const videoName = getVideoName(videoId);

      const data = datasets[key];
      const total = data.reduce((a, b) => Number(a) + Number(b), 0);

      outputDatasets.push({ label: `${videoName} - ${objectName}: ${total}`, data });
    });

    const chartData = { labels, datasets: outputDatasets };

    return chartData;
  },
  reportsByHourToChartData(state, _getters, rootState, rootGetters) {
    const kNumberOfHoursByDay = 24;
    const dateFormat = 'YYYYMMDD';
    const today = moment().startOf('day').format(dateFormat);

    const dataByVideoAndObject: Record<string, number[]> = {};
    const datasetNames: Record<string, DatasetInfo> = {};

    state.reportsByHour.data.forEach((dataByVideo: Report[]) => {
      dataByVideo.forEach((report: Report) => {
        const {
          video, created_at: createdAt, local_time_zone: timeZone, name, direction_in: directionIn,
          direction_out: directionOut, direction_undefined: directionUndefined,
        } = report;

        const date = moment.utc(createdAt).add(timeZone, 'hours');
        const day = date.format(dateFormat);

        if (day === today) {
          const hour = date.hours();
          const { id: videoId } = video;

          const key = `${videoId}-${name}`;
          let total = 0;

          total += directionIn?.total || 0;
          total += directionOut?.total || 0;
          total += directionUndefined?.total || 0;

          if (key in datasetNames) {
            dataByVideoAndObject[key][hour] = total;
          } else {
            datasetNames[key] = { videoId, objectName: name };

            const data = new Array(kNumberOfHoursByDay).fill(0);

            data[hour] = total;

            dataByVideoAndObject[key] = data;
          }
        }
      });
    });

    const labels = new Array(kNumberOfHoursByDay);

    for (let i = 0; i < kNumberOfHoursByDay; i += 1) labels[i] = i;

    const outputDatasets: Dataset[] = [];
    const getVideoName = rootGetters['objectcounter/videoName'];

    // Sort the datasets to make sure the follow the same color order on dashboard
    const datasetKeys = Object.keys(datasetNames);

    datasetKeys.sort();
    datasetKeys.forEach((key) => {
      const { videoId, objectName } = datasetNames[key];
      const videoName = getVideoName(videoId);

      const data = dataByVideoAndObject[key];
      const total = data.reduce((a, b) => a + b, 0);

      outputDatasets.push({ label: `${videoName} - ${objectName}: ${total}`, data });
    });

    const chartData = { labels, datasets: outputDatasets };

    return chartData;
  },
};

export default getters;
