// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import { AxiosError } from 'axios';
import {
  WorkspaceState, types, Workspace, User, Invite, WorkspaceUser,
} from './types';

export const mutations: MutationTree<WorkspaceState> = {
  [types.GET_WORKSPACE_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.GET_USER_WORKSPACES_REQUEST](state) {
    state.isLoading = true;
  },
  [types.GET_USER_WORKSPACES_SUCCESS](state, data: WorkspaceUser[]) {
    state.isLoading = false;
    state.workspaceList = data;
  },
  [types.GET_USER_WORKSPACES_FAILURE](state, error: AxiosError) {
    state.isLoading = false;
    state.error = error;
  },
  [types.GET_WORKSPACE_SUCCESS](state, workspace: Workspace) {
    state.workspace = workspace;
    state.isLoading = false;
  },
  [types.GET_WORKSPACE_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.GET_WORKSPACE_USERS_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.GET_WORKSPACE_USERS_SUCCESS](state, workspaceUsers: User[]) {
    state.workspaceUsers = workspaceUsers;
    state.isLoading = false;
  },
  [types.GET_WORKSPACE_USERS_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.UPDATE_WORKSPACE_USER_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.UPDATE_WORKSPACE_USER_SUCCESS](state, workspaceUser: User) {
    const userIndex = state.workspaceUsers.findIndex((user) => user.id === workspaceUser.id);

    state.workspaceUsers.splice(userIndex, 1, workspaceUser);
    state.isLoading = false;
  },
  [types.UPDATE_WORKSPACE_USER_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.DELETE_WORKSPACE_USER_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.DELETE_WORKSPACE_USER_SUCCESS](state, id) {
    const userIndex = state.workspaceUsers.findIndex((user) => user.id === id);

    state.workspaceUsers.splice(userIndex, 1);
    state.isLoading = false;
  },
  [types.DELETE_WORKSPACE_USER_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.GET_WORKSPACE_USER_TAGS_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.GET_WORKSPACE_USER_TAGS_SUCCESS](state, { workspaceUserId, tags }) {
    const userIndex = state.workspaceUsers.findIndex(
      (workspaceUser) => workspaceUserId === workspaceUser.id,
    );

    if (userIndex !== -1) {
      const workspaceUsers = state.workspaceUsers.slice();

      workspaceUsers[userIndex].tags = tags;
      state.workspaceUsers = workspaceUsers;
    }

    state.isLoading = false;
  },
  [types.GET_WORKSPACE_USER_TAGS_FAILURE](state, error) {
    state.error = error;
  },
  [types.GET_INVITES_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.GET_INVITES_SUCCESS](state, invites: Invite[]) {
    state.invites = invites;
    state.isLoading = false;
  },
  [types.GET_INVITES_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.DELETE_INVITE_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.DELETE_INVITE_SUCCESS](state) {
    state.isLoading = false;
  },
  [types.DELETE_INVITE_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.INVITE_USER_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.INVITE_USER_SUCCESS](state, invite: Invite) {
    state.invites.push(invite);
    state.isLoading = false;
  },
  [types.INVITE_USER_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.EDIT_WORKSPACE_REQUEST](state) {
    state.error = undefined;
    state.isLoading = true;
  },
  [types.EDIT_WORKSPACE_SUCCESS](state, workspace: Workspace) {
    state.workspace = workspace;
    state.isLoading = false;
  },
  [types.EDIT_WORKSPACE_FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },
  [types.RESET_ERROR](state) {
    state.error = undefined;
  },
};

export default mutations;
