// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';
import { GetterTree } from 'vuex';
import { VideosState, Video } from './types';
import { ApiRequestError, RootState } from '../../types';

export const getters: GetterTree<VideosState, RootState> = {
  isLoadingVideos: (state): boolean => state.requestCounter > 0,
  videos: (state): Video[] => state.videos,
  requestVideosError(state): AxiosError | ApiRequestError | undefined {
    const { error } = state;

    if (error?.response) {
      // Request made and server responded
      const { data: errorData } = error.response as { data: ApiRequestError };

      if (errorData) {
        return errorData;
      }
    }

    return state.error;
  },
  getVideo: (state) => (videoId: string): Video | undefined => (
    state.videos.find(({ id }) => id === videoId)
  ),
};

export default getters;
