// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { GetterTree } from 'vuex';
import { VideosState, Video } from './types';
import { RootState } from '../types';

export const getters: GetterTree<VideosState, RootState> = {
  videos(state): Video[] {
    return state.videos;
  },
  getVideo: (state) => (videoId: string): Video | undefined => {
    const video = state.videos.find((p) => p.id === videoId);

    return video;
  },
};

export default getters;
