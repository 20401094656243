// Copyright (C) 2022 Deconve Technology. All rights reserved.

import moment from 'moment';
import { GetterTree } from 'vuex';

import { RootState } from '../../types';
import {
  ReportByPanel, ReportByVideo, ReportsState, PeopleReport,
} from './types';

interface DatasetItem {
  [date: string]: number;
}

export const getters: GetterTree<ReportsState, RootState> = {
  reportsByDay: (state) => state.reportsByDay.byPanel,
  numberOfPeopleImpacted: (state) => state.numberOfPeopleImpacted,
  numberOfVehiclesImpacted: (state) => state.numberOfVehiclesImpacted,
  totalNumberOfVehicles: (state) => state.totalNumberOfVehicles,
  numberOfPeopleImpactedByCampaign: (state) => state.numberOfPeopleImpactedByCampaign,
  numberOfVehiclesImpactedByCampaign: (state) => state.numberOfVehiclesImpactedByCampaign,
  totalNumberOfVehiclesByCampaign: (state) => state.totalNumberOfVehiclesByCampaign,
  campaignCostPerMileImpressions: (state) => state.campaignCostPerMileImpressions,
  isLoadingReportsByDay: (state) => state.reportsByDay.isLoading,
  reportsToChartAudience: (state) => state.reportsByDay.audience,
  reportsPercentage: (state) => state.reportsByDay.byVehicles,
  reportsByDayToChartData(state) {
    // first date and last date are used to create the chart data labels
    let firstValidDate: moment.Moment = moment(null);
    let lastValidDate: moment.Moment = moment(null);

    const dateFormat = 'DD/MM/YYYY';
    const dataByDate: DatasetItem = {};

    state.reportsByDay.byPanel.forEach((dataByPanel: ReportByPanel) => {
      dataByPanel.forEach((dataByVideo: ReportByVideo) => {
        dataByVideo.forEach((item: PeopleReport) => {
          const {
            createdAt, localTimeZone: videoTimeZone, peopleByVehicles,
          } = item;

          const date = moment.utc(createdAt).add(videoTimeZone, 'hours');

          if (!firstValidDate.isValid() || date.isBefore(firstValidDate)) {
            firstValidDate = moment(date);
          }

          if (!lastValidDate.isValid() || date.isAfter(lastValidDate)) {
            lastValidDate = moment(date);
          }

          const dateInString = date.format(dateFormat);

          const total = peopleByVehicles?.total || 0;

          if (dateInString in dataByDate) {
            dataByDate[dateInString] += total;
          } else {
            dataByDate[dateInString] = total;
          }
        });
      });
    });

    const labels: string[] = [];
    const data: number[] = [];

    if (firstValidDate.isValid()) {
      while (firstValidDate.isSameOrBefore(lastValidDate)) {
        const dateLabel = firstValidDate.format(dateFormat);

        labels.push(dateLabel);

        data.push(dateLabel in dataByDate ? dataByDate[dateLabel] : 0);
        firstValidDate.add(1, 'day');
      }
    }

    return { labels, data };
  },
};

export default getters;
