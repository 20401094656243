// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import { ReportsState, types } from './types';
import { RootState } from '../../types';
import { Video } from '../videos/types';
import { getReports } from '../../../api/objectcounter/report';

interface ReportQuery {
  startDate: string;
  endDate: string;
  groupBy: string;
  personId?: string;
  videoId?: string;
}

export const actions: ActionTree<ReportsState, RootState> = {
  getReportsByDay({ commit, rootGetters }, { startDate, endDate }: ReportQuery): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_OBJECT_COUNTER_REPORTS_BY_DAY_REQUEST);

      const videos: Video[] = rootGetters['objectcounter/videos'];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];

      videos.forEach((video) => {
        const params = {
          startDate,
          endDate,
          groupBy: 'day',
          videoId: video.id,
        };

        promises.push(getReports(params, rootGetters));
      });

      Promise.all(promises).then((results) => {
        commit(types.GET_OBJECT_COUNTER_REPORTS_BY_DAY_SUCCESS, results);
        resolve();
      }).catch(() => {
        commit(types.GET_OBJECT_COUNTER_REPORTS_BY_DAY_FAILURE);
        reject();
      });
    });
  },
  getReportsByHour({ commit, rootGetters }, { startDate, endDate }: ReportQuery): Promise<void> {
    return new Promise((resolve, reject) => {
      commit(types.GET_OBJECT_COUNTER_REPORTS_BY_HOUR_REQUEST);

      const videos: Video[] = rootGetters['objectcounter/videos'];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const promises: Promise<any>[] = [];

      videos.forEach((video) => {
        const params = {
          startDate,
          endDate,
          groupBy: 'hour',
          videoId: video.id,
        };

        promises.push(getReports(params, rootGetters));
      });

      Promise.all(promises).then((results) => {
        commit(types.GET_OBJECT_COUNTER_REPORTS_BY_HOUR_SUCCESS, results);
        resolve();
      }).catch(() => {
        commit(types.GET_OBJECT_COUNTER_REPORTS_BY_HOUR_FAILURE);
        reject();
      });
    });
  },
};

export default actions;
