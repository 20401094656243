// Copyright (C) 2023 Deconve Technology. All rights reserved.

import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const DATE_TIME_OPTIONS = {
  today: 'today',
  yesterday: 'yesterday',
  lastTwoDays: 'lastTwoDays',
  lastSevenDays: 'lastSevenDays',
  lastFifteenDays: 'lastFifteenDays',
  lastThirtyDays: 'lastThirtyDays',
  thisMonth: 'thisMonth',
  nextFiveMinutes: 'nextFiveMinutes',
  nextFifteenMinutes: 'nextFifteenMinutes',
  nextThirtyMinutes: 'nextThirtyMinutes',
  nextHour: 'nextHour',
  nextTwoHours: 'nextTwoHours',
  custom: 'custom',
  anyPeriod: 'anyPeriod',
};

export function getDateAndTimeRange(dateTimeOption: string) {
  let startDate;
  let endDate;

  switch (dateTimeOption) {
    case DATE_TIME_OPTIONS.anyPeriod:
      startDate = '';
      endDate = '';
      break;

    case DATE_TIME_OPTIONS.today:
      startDate = moment().startOf('day').toISOString();
      endDate = moment().endOf('day').toISOString();
      break;

    case DATE_TIME_OPTIONS.yesterday:
      startDate = moment().subtract(1, 'day').startOf('day').toISOString();
      endDate = moment().subtract(1, 'day').endOf('day').toISOString();
      break;

    case DATE_TIME_OPTIONS.lastTwoDays:
      startDate = moment().subtract(2, 'days').startOf('day').toISOString();
      endDate = moment().endOf('day').toISOString();
      break;

    case DATE_TIME_OPTIONS.lastSevenDays:
      startDate = moment().subtract(7, 'days').startOf('day').toISOString();
      endDate = moment().endOf('day').toISOString();
      break;

    case DATE_TIME_OPTIONS.lastFifteenDays:
      startDate = moment().subtract(15, 'days').startOf('day').toISOString();
      endDate = moment().endOf('day').toISOString();
      break;

    case DATE_TIME_OPTIONS.lastThirtyDays:
      startDate = moment().subtract(30, 'days').startOf('day').toISOString();
      endDate = moment().endOf('day').toISOString();
      break;

    case DATE_TIME_OPTIONS.thisMonth:
      startDate = moment().startOf('month').toISOString();
      endDate = moment().endOf('month').toISOString();
      break;

    case DATE_TIME_OPTIONS.nextFiveMinutes:
      startDate = moment().toISOString();
      endDate = moment().add(5, 'minutes').toISOString();
      break;

    case DATE_TIME_OPTIONS.nextFifteenMinutes:
      startDate = moment().toISOString();
      endDate = moment().add(15, 'minutes').toISOString();
      break;

    case DATE_TIME_OPTIONS.nextThirtyMinutes:
      startDate = moment().toISOString();
      endDate = moment().add(30, 'minutes').toISOString();
      break;

    case DATE_TIME_OPTIONS.nextHour:
      startDate = moment().toISOString();
      endDate = moment().add(1, 'hour').toISOString();
      break;

    case DATE_TIME_OPTIONS.nextTwoHours:
      startDate = moment().toISOString();
      endDate = moment().add(2, 'hours').toISOString();
      break;

    default:
      break;
  }

  return { startDate, endDate };
}
