// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { NotificationReportsState } from './types';
import { RootState } from '../../types';

export const state: NotificationReportsState = {
  notificationStatistics: {
    data: {
      negative: 0,
      positive: 0,
      unreviewed: 0,
      total: 0,
    },
    isLoading: false,
    error: undefined,
  },
  notificationReports: {
    data: {
      hasMore: false,
      items: [],
      total: 0,
    },
    isLoading: false,
    error: undefined,
  },
  notificationReportsDelay: {
    data: {
      items: [],
      hasMore: false,
      total: 0,
    },
    isLoading: false,
    error: undefined,
  },
  notificationVideoSources: {
    data: [],
    isLoading: false,
  },
  downloadReportsState: { isLoading: false, error: undefined },
};

const namespaced = false;

export const reports: Module<NotificationReportsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
