// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { MutationTree } from 'vuex';
import {
  OccurrenceState, OccurrencePreviewPage, Occurrence, types,
} from './types';

import { hasOccurrenceChanged } from './utils';

const kMaxBufferSize = 128;

export const mutations: MutationTree<OccurrenceState> = {
  [types.GET_FACEID_OCCURRENCES_SUCCESS](state, data: OccurrencePreviewPage) {
    state.occurrencePreviewPage.items = data.items;
    state.occurrencePreviewPage.total = data.total;
  },
  [types.GET_FACEID_OCCURRENCE_SUCCESS](state, data: Occurrence) {
    const { id: occurrenceId } = data;
    const occurrenceIndex = state.occurrences.findIndex(({ id }) => id === occurrenceId);

    if (occurrenceIndex >= 0) {
      const currentData = state.occurrences[occurrenceIndex];

      if (hasOccurrenceChanged(currentData, data)) {
        state.occurrences.splice(occurrenceIndex, 1, data);
      }
    } else {
      const numberOfOccurrences = state.occurrences.push(data);

      if (numberOfOccurrences > kMaxBufferSize) {
        state.occurrences.shift();
      }
    }
  },
  [types.PATCH_FACEID_OCCURRENCE_SUCCESS](state, data: Occurrence) {
    const { id: occurrenceId } = data;
    const occurrenceIndex = state.occurrences.findIndex(({ id }) => id === occurrenceId);

    if (occurrenceIndex >= 0) {
      const currentData = state.occurrences[occurrenceIndex];

      if (hasOccurrenceChanged(currentData, data)) {
        state.occurrences.splice(occurrenceIndex, 1, data);
      }
    } else {
      const numberOfOccurrences = state.occurrences.push(data);

      if (numberOfOccurrences > kMaxBufferSize) {
        state.occurrences.shift();
      }
    }
  },
  [types.DELETE_FACEID_OCCURRENCE_SUCCESS](state, occurrenceId) {
    const { items } = state.occurrencePreviewPage;

    if (items) {
      const occurrenceIndex = items.findIndex((occurrence) => (
        occurrence.id === occurrenceId
      ));

      if (occurrenceIndex >= 0) {
        state.occurrencePreviewPage.items.splice(occurrenceIndex, 1);
        state.occurrencePreviewPage.total -= 1;
      }
    }

    const occurrenceIndex = state.occurrences.findIndex(({ id }) => id === occurrenceId);

    if (occurrenceIndex >= 0) {
      state.occurrences.splice(occurrenceIndex, 1);
    }
  },
  [types.DOWNLOAD_FACEID_OCCURRENCES_REQUEST](state) {
    state.occurrencesDownloadState.isLoading = true;
  },
  [types.DOWNLOAD_FACEID_OCCURRENCES_SUCCESS](state) {
    state.occurrencesDownloadState.isLoading = false;
  },
  [types.DOWNLOAD_FACEID_OCCURRENCES_FAILURE](state, error) {
    state.occurrencesDownloadState.isLoading = false;
    state.occurrencesDownloadState.error = error;
  },
  setOccurrenceIteratorSettings(state, data) {
    state.occurrenceIteratorSettings = data;
  },
};

export default mutations;
