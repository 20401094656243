// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { SuperProfilesState } from './types';

export const state: SuperProfilesState = {
  superProfiles: [],
  superProfile: {
    isLoading: false,
    data: undefined,
    error: undefined,
  },
  superProfileReviewer: {
    isLoading: false,
    data: undefined,
    error: undefined,
  },
  superProfileFiltersSettings: undefined,
  superProfilesPreviewList: { items: [], total: 0 },
  similarSuperProfiles: [],
};

const namespaced = false;

export const superProfiles: Module<SuperProfilesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
