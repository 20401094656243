// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { ActionTree } from 'vuex';
import axios, { AxiosRequestConfig } from 'axios';
import getDemoUnits from '@/store/units/demo';
import { getDemoVideos } from '@/store/videos/demo';
import { VideosState, types } from './types';
import { RootState } from '../../types';

export const actions: ActionTree<VideosState, RootState> = {
  fetchVideos({ commit, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      if (rootGetters.isDemoMode) {
        getDemoVideos().then((videos) => {
          commit(types.GET_RETAIL_ANALYTICS_VIDEOS_SUCCESS, videos);
          resolve();
        });
      } else {
        const host = process.env.VUE_APP_DECONVE_RETAIL_ANALYTICS_API_URL
      || process.env.VUE_APP_DECONVE_API_URL;

        const requestOptions: AxiosRequestConfig = {
          method: 'get',
          baseURL: host,
          url: '/retailanalytics/videos/',
          headers: {
            Authorization: rootGetters.authorizationToken,
          },
        };

        axios(requestOptions).then((response) => {
          const { data } = response;

          commit(types.GET_RETAIL_ANALYTICS_VIDEOS_SUCCESS, data);
          resolve();
        }).catch((error) => reject(error));
      }
    });
  },
  fetchVideoGroups({ commit, rootGetters }): Promise<void> {
    return new Promise((resolve, reject) => {
      if (rootGetters.isDemoMode) {
        getDemoUnits().then((data) => {
          const { items: units } = data;

          commit(types.GET_RETAIL_ANALYTICS_VIDEO_GROUPS_SUCCESS, units);

          resolve();
        });
      } else {
        const host = process.env.VUE_APP_DECONVE_RETAIL_ANALYTICS_API_URL
        || process.env.VUE_APP_DECONVE_API_URL;

        const requestOptions: AxiosRequestConfig = {
          method: 'get',
          baseURL: host,
          url: '/retailanalytics/videos/groups/',
          headers: {
            Authorization: rootGetters.authorizationToken,
          },
        };

        axios(requestOptions).then((response) => {
          const { data } = response;

          commit(types.GET_RETAIL_ANALYTICS_VIDEO_GROUPS_SUCCESS, data);
          resolve();
        }).catch((error) => reject(error));
      }
    });
  },
};

export default actions;
