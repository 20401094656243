// Copyright (C) 2022 Deconve Technology. All rights reserved.

export const types: { [id: string]: string } = {
  GET_VIDEOS_SUCCESS: 'GET_VIDEOS_SUCCESS',
  DELETE_VIDEOS_SUCCESS: 'DELETE_VIDEOS_SUCCESS',
};

interface Tag {
  id: string;
}

export interface Video {
  id: string;
  name?: string;
  url?: string;
  gps_latitude?: number;
  gps_longitude?: number;
  created_at?: string;
  updated_at?: string;
  tags?: Tag[];
}

export interface VideosPreviewPage {
  items: Video[];
  total: number;
}

export interface VideosState {
  videos: Video[];
}
