// Copyright (C) 2022 Deconve Technology. All rights reserved.

import { AxiosError } from 'axios';
import { GetterTree } from 'vuex';
import { VideosState } from './types';
import { ApiRequestError, RootState } from '../../types';

export const getters: GetterTree<VideosState, RootState> = {
  isLoadingVideos(state): boolean {
    return state.isLoading;
  },
  videos(state) {
    return state.videos;
  },
  requestVideosError(state): AxiosError | ApiRequestError | undefined {
    const { error } = state;

    if (error?.response) {
      // Request made and server responded
      const { data: errorData } = error.response as { data: ApiRequestError };

      if (errorData) {
        return errorData;
      }
    }

    return state.error;
  },
  videoName: (state) => (videoId: string) => {
    const video = state.videos.find((v) => v.id === videoId);

    return video?.name;
  },
};

export default getters;
